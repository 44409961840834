import React, { useState } from "react";
import UrsorDialog from "../../../components/UrsorDialog";
import UrsorInputField from "../../../components/inputs/UrsorInputField";
import Illustration from "../../../images/ExtraDefaultIllustration.svg";
import { Box } from "@mui/system";

export interface ITeachingNameDialogProps {
  open: boolean;
  name: string;
  setName: (name: string) => void;
  submitCallback: (name: string) => void;
  //backCallback: () => void;
}

export default function TeachingNameDialog(props: ITeachingNameDialogProps) {
  return (
    <UrsorDialog
      title="What is your teaching name?"
      subtitle={["What do your students usually call you?"]}
      supertitle="Create Account"
      open={props.open}
      button={{
        text: "Next",
        callback: () => props.submitCallback(props.name),
        disabled: !props.name,
      }}
      noBackdrop
      noCloseButton
    >
      <UrsorInputField
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.setName(event.target.value)
        }
        value={props.name}
        placeholder={"Professor Feynman"}
        width={410}
      />
      <Box
        component="img"
        height="100%"
        minHeight={0}
        maxHeight="100%"
        width="fit-content"
        src={Illustration}
      />
    </UrsorDialog>
  );
}
