import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";

const BACKEND_URLS = {
  dev: "http://localhost:8080",
  staging:
    "https://058vkvcapb.execute-api.eu-west-1.amazonaws.com/dev/dev-ursor-express-serverless",
  prod: "https://xdt8565hsf.execute-api.eu-west-1.amazonaws.com/prod/api",
};

const api = axios.create({
  baseURL: BACKEND_URLS[process.env.REACT_APP_BUILD_ENV],
});

const s3Api = axios.create();

axiosRetry(api, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000; // time interval between retries
  },
  // // this should automatically not retry POST, and only retry if necessary
  // retryCondition: (error) => {
  //     // if retry condition is not specified, by default idempotent requests are retried
  //     // [408, 500, 502, 503, 504, 522, 524]
  //     return error.response.status === 503;
  // },
});

api.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    // config.headers.Email = window.localStorage.getItem(
    //   "email"
    // )?.replaceAll('"', '')
    config.headers.Authorization = `Bearer ${window.localStorage
      .getItem("accessToken")
      ?.replaceAll('"', "")}`; // get rid of the enclosing quotation marks
    resolve(config);
  });
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    // if not  network error or
    // Request aborted
    Sentry.captureException(error);
    throw error;
  }
);

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class ApiController {
  constructor(username, password) {
    this.accessToken = undefined; // read from sessionStorage
    this.idToken = undefined; // read from sessionStorage
    this.username = username;
    this.password = password;
    this.token = undefined;
    this.teacherId = undefined;
    this.teachers = [];
    this.classrooms = [];
    this.lessons = [];
    this.content = [];
    this.students = [];
  }

  static async loadRefreshToken(code, teacherId) {
    return await api.get("/gapi/loadRefreshToken", {
      params: { code, teacherId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).data;
  }

  static async getGCScopes(teacherId) {
    return (await api.get(`/gapi/scopes/${teacherId}`)).data;
  }

  static async getGCCourses(teacherId) {
    return (await api.get(`/gapi/courses/${teacherId}`)).data;
  }

  static async getStudentsFromGCCourse(teacherId, courseId) {
    return (await api.get(`/gapi/courses/${teacherId}/${courseId}/students`))
      .data;
  }

  static async getLessonsWithLinksFromGCCourse(teacherId, courseId) {
    return (await api.get(`/gapi/courses/${teacherId}/${courseId}/stacks`))
      .data;
  }

  static async getLessonsWithLinksFromGCCourses(teacherId, courseIds) {
    return (await api.post(`/gapi/courses/stacks`, { courseIds, teacherId }))
      .data;
  }

  static async createGCCourse(teacherId, googleId, title, lessons) {
    return (
      await api.post("/gapi/courses", { teacherId, googleId, title, lessons })
    ).data;
  }

  static async createSchool(schoolDetails) {
    return (await api.post("/schools", schoolDetails)).data;
  }

  static async createTemporarySchool(schoolName, teacherId) {
    return (
      await api.post(`/schools/temporarySchool`, { schoolName, teacherId })
    ).data;
  }

  static async createSession(schoolId, sessionDetails) {
    return (await api.post(`/schools/${schoolId}/sessions`, sessionDetails))
      .data;
  }

  static async updateSession(schoolId, sessionId, sessionDetails) {
    return (
      await api.patch(
        `/schools/${schoolId}/sessions/${sessionId}`,
        sessionDetails
      )
    ).data;
  }

  static async updateDeviceName(deviceId, name) {
    return (await api.patch(`/schools/devices/${deviceId}/name`, { name }))
      .data;
  }

  static async updateDeviceAge(deviceId, mode) {
    return (
      await api.patch(`/schools/devices/${deviceId}/contentAgeMode`, { mode })
    ).data;
  }

  static async updateLatestGCSyncTime(teacherId) {
    return (await api.post(`/teachers/${teacherId}/latestGCSyncTime`)).data;
  }

  static async refreshTokens() {
    const res = await Auth.currentSession();

    const idToken = res.getIdToken().getJwtToken();
    localStorage.setItem("idToken", idToken);

    const accessToken = res.getAccessToken().getJwtToken();
    localStorage.setItem("accessToken", accessToken);
  }

  static async isBlocked(url) {
    try {
      const postData = {
        url,
      };
      const _ = await api.post("/links/filter", postData);
      return false;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          if (error.response.status === 405) {
            return true;
          }
        }
      }
    }
  }

  static async checkTeacherExists(email) {
    const response = await api.post("/teachers/checkTeacherExists", { email });
    return response.data;
  }

  static async getSchool(id) {
    const response = await api.get(`/schools/${id}`);
    return response.data;
  }

  static async updateSchool(id, update) {
    const response = await api.patch(`/schools/${id}`, update);
    return response.data;
  }

  static async createClassroom(postData, lessonId) {
    const response = await api.post("/classrooms", postData);
    if (lessonId) {
      await this.duplicateLesson(lessonId, {
        classId: response.data.id,
        teacherOwnerId: postData.teacherOwnerId,
      });
    }
    return response.data;
  }

  static async updateClassroom(classId, classroom) {
    const response = await api.patch(`/classrooms/${classId}`, classroom);
    return response.data;
  }

  static async getTeachersInSchool(schoolId) {
    const response = await api.get("/schools/" + schoolId + "/teachers");
    return response.data;
  }

  static async getStudent(studentId) {
    const response = await api.get("/students/" + studentId);
    return response.data;
  }

  static async getStudentsInSchool(schoolId) {
    const response = await api.get("/schools/" + schoolId + "/students");
    return response.data;
  }

  static async getStudentsForTeacher(teacherId) {
    const response = await api.get("/teachers/" + teacherId + "/students");
    return response.data;
  }

  static async addStudentsToClassroom(classroomId, studentIds, studentGroup) {
    const response = await api.put(`classrooms/${classroomId}/students`, {
      studentIds,
      studentGroup,
    });
    return response.data;
  }

  static async removeStudentFromClassroom(classroomId, studentId) {
    const response = await api.delete(
      `classrooms/${classroomId}/students/${studentId}`
    );
    return response.data;
  }

  static async getLesson(lessonId) {
    const response = await api.get("/lessons/" + lessonId);
    return response.data;
  }

  static async getLessons(ids) {
    const response = await api.get("/lessons", { params: { ids } });
    return response.data;
  }

  static async getWorksheetsInLessons(lessonIds) {
    const response = await api.get(`/worksheets`, {
      params: { lessonId: lessonIds },
    });
    return response.data;
  }

  static async getContentsOfLesson(lessonId) {
    const config = {
      params: {
        lessonId,
      },
    };
    const response = await api.get("/content", config);
    return response.data;
  }

  static async createLinkForHomepage(schoolId, content) {
    const response = await api.post(`/schools/${schoolId}/homepages`, content);
    return response.data;
  }

  static async deleteHomepageContent(schoolId, contentId) {
    const response = await api.delete(
      `/schools/${schoolId}/homepages/${contentId}`
    );
    return response.data;
  }

  static async deleteSession(schoolId, sessionId) {
    const response = await api.delete(
      `/schools/${schoolId}/sessions/${sessionId}`
    );
    return response.data;
  }

  static async getLessonsWithContents(classId) {
    const response = await api.get(`/lessons/lessonsWithContents/${classId}`);
    return response.data;
  }

  static async getLessonsInSchool(schoolId) {
    const response = await api.get(`/schools/${schoolId}/lessons`);
    return response.data;
  }

  static async getLinksInSchool(schoolId) {
    const response = await api.get(`/schools/${schoolId}/links`);
    return response.data;
  }

  static async getStacksInSchool(schoolId) {
    const response = await api.get(`/schools/${schoolId}/stacks`);
    return response.data;
  }

  static async getChannelsInSchool(schoolId) {
    const response = await api.get(`/schools/${schoolId}/channels`);
    return response.data;
  }

  static async getAppsInSchool(schoolId) {
    const response = await api.get(`/schools/${schoolId}/platforms`);
    return response.data;
  }

  static async getApprovalRequestsInSchool(schoolId) {
    const response = await api.get(`/schools/${schoolId}/approvalRequests`);
    return response.data;
  }

  static async approveApprovalRequest(requestId, reviewerId) {
    const response = await api.post(
      `/schools/approvalRequests/${requestId}/approve`,
      { reviewerId }
    );
    return response.data;
  }

  static async denyApprovalRequest(requestId, reviewerId) {
    const response = await api.post(
      `/schools/approvalRequests/${requestId}/deny`,
      { reviewerId }
    );
    return response.data;
  }

  static async approveDevice(deviceId, reviewerId) {
    const response = await api.post(`/schools/devices/${deviceId}/approve`, {
      reviewerId,
    });
    return response.data;
  }

  static async rejectDevice(deviceId, reviewerId) {
    const response = await api.post(`/schools/devices/${deviceId}/reject`, {
      reviewerId,
    });
    return response.data;
  }

  static async getClassesInSchool(schoolId) {
    const response = await api.get(`/schools/${schoolId}/classes`);
    return response.data;
  }

  static async getClassroom(classroomId) {
    const response = await api.get("/classrooms/" + classroomId);
    return response.data;
  }

  static async getClassroomsForTeacher(teacherId) {
    const response = await api.get("/teachers/" + teacherId + "/classrooms");
    return response.data;
  }

  static async getLessonsForTeacher(teacherId) {
    const response = await api.get("/teachers/" + teacherId + "/lessons");
    return response.data;
  }

  static async getLinksForTeacher(teacherId) {
    const response = await api.get("/teachers/" + teacherId + "/links");
    return response.data;
  }

  static async getWorksheetsForTeacher(teacherId) {
    const response = await api.get("/teachers/" + teacherId + "/worksheets");
    return response.data;
  }

  static async getWorksheetAnswersForTeacher(teacherId) {
    const response = await api.get(
      "/teachers/" + teacherId + "/worksheetAnswers"
    );
    return response.data;
  }

  static async getHomepageLinks(schoolId) {
    const response = await api.get(`/schools/${schoolId}/homepages`);
    return response.data;
  }

  async getTeacher(teacherId) {
    const response = await api.get("/teachers/" + teacherId);
    this.teacher = response.data;
    return response.data;
  }

  static async addTeachersToClassroom(classroomId, teacherIds) {
    const response = await api.post(`classrooms/${classroomId}/teachers`, {
      teacherIds,
    });
    return response.data;
  }

  static async removeTeacherFromClassroom(classroomId, teacherId) {
    const response = await api.delete(
      `classrooms/${classroomId}/teachers/${teacherId}`
    );
    return response.data;
  }

  static async updateTeacher(teacherId, update) {
    const response = await api.patch(`/teachers/${teacherId}`, update);
    return response;
  }

  // static async setGCOnBoardingStepComplete(teacherId) {
  //   const response = await api.put(`/teachers/${teacherId}`, {
  //     gcOnBoardingStepComplete: true,
  //   });
  //   return response;
  // }

  static async updateStudent(studentId, update) {
    const response = await api.put(`/students/${studentId}`, update);
    return response;
  }

  static async inviteTeacher(email, inviterId) {
    const response = await api.post(`/teachers/invite`, {
      email,
      inviterId,
    });
    return response;
  }

  static async getTeacher(id) {
    const response = await api.get(`/teachers/${id}`);
    return response.data;
  }

  static async replyToInvitation(teacherId, accept) {
    const response = await api.post(
      `/teachers/${teacherId}/replyToInvitation`,
      { accept }
    );
    return response.data;
  }

  static async createStudents(schoolId, studentDetails) {
    const response = await api.post(`/students`, {
      students: studentDetails,
      schoolId,
    });
    return response.data;
  }

  static async getHistoryForStudent(studentId) {
    const response = await api.get(`/students/${studentId}/history`);
    return response;
  }

  static async getHistoryForDevice(deviceId) {
    const response = await api.get(`/schools/devices/${deviceId}/history`);
    return response;
  }

  static async getLatestBrowsingStates(schoolId) {
    const response = await api.get(`/schools/${schoolId}/latestBrowsingStates`);
    return response.data;
  }

  static async getLatestBrowsingState(deviceId) {
    const response = await api.get(`/schools/${deviceId}/browsingState`);
    return response.data;
  }

  static async monitorStudents(classroomId) {
    const response = await api.get(`/classrooms/${classroomId}/monitoring`);
    return response.data;
  }

  static async createLesson(lesson) {
    const response = await api.post(`/lessons`, lesson);
    return response.data;
  }

  static async createLessonContentSection(lessonId) {
    const response = await api.post(`/lessons/${lessonId}/contentSection`);
    return response.data;
  }

  static async deleteLessonContentSection(sectionId) {
    const response = await api.delete(`/lessons/contentSection/${sectionId}`);
    return response.data;
  }

  static async updateLessonContentSectionTitle(sectionId, title) {
    const response = await api.patch(`/lessons/contentSection/${sectionId}`, {
      title,
    });
    return response.data;
  }

  static async duplicateClass(classId, teacherOwnerId) {
    const response = await api.post(`/classrooms/${classId}/duplicate`, {
      teacherOwnerId,
    });
    return response;
  }

  static async duplicateLesson(lessonId, lessonDuplicationDetails) {
    const response = await api.post(
      `/lessons/${lessonId}/duplicate`,
      lessonDuplicationDetails
    );
    return response;
  }

  static async duplicateLink(id, creatorId) {
    const response = await api.post(`/links/${id}/duplicate`, {
      creatorId,
    });
    return response;
  }

  static async updateLesson(lessonId, update) {
    const response = await api.put(`/lessons/${lessonId}`, update);
    return response;
  }

  static async deleteLesson(lessonId) {
    const response = await api.delete(`/lessons/${lessonId}`);
    return response;
  }

  static async deleteClass(classId) {
    const response = await api.delete(`/classrooms/${classId}`);
    return response;
  }

  static async deleteTeacher(teacherId) {
    const response = await api.delete(`/teachers/${teacherId}`);
    return response;
  }

  static async removeStudent(studentId) {
    const response = await api.put(`/students/${studentId}/remove`);
    return response;
  }

  static async createStudentGroup(classroomId, students, name) {
    const response = await api.post(
      `/classrooms/${classroomId}/studentGroups`,
      {
        name,
        students,
      }
    );
    return response;
  }

  static async deleteStudentGroup(classroomId, groupId) {
    const response = await api.delete(
      `/classrooms/${classroomId}/studentGroups/${groupId}`
    );
    return response;
  }

  static async updateStudentGroup(classroomId, groupId, name) {
    const response = await api.put(
      `/classrooms/${classroomId}/studentGroups/${groupId}`,
      {
        name,
      }
    );
    return response;
  }

  static async changeGroupOfStudent(
    classroomId,
    currentGroupId,
    newGroupId,
    studentId
  ) {
    const response = await api.patch(
      `/classrooms/${classroomId}/studentGroups/move`,
      {
        studentId,
        newGroupId,
        currentGroupId,
      }
    );
    return response;
  }

  static async createLink(details) {
    const response = await api.post(`/links`, details);
    console.log(response.data);
    return response.data;
  }

  static async createPlatform(details) {
    const response = await api.post(`/platform`, details);
    return response.data;
  }

  static async updateLink(id, update) {
    const response = await api.patch(`/links/${id}`, update);
    return response.data;
  }

  static async updateChannel(id, update) {
    const response = await api.patch(`/channels/${id}`, update);
    return response.data;
  }

  static async updateStack(id, update) {
    const response = await api.patch(`/stacks/${id}`, update);
    return response.data;
  }

  static async updatePlatform(platformId, update) {
    const response = await api.patch(`/platform/${platformId}`, update);
    return response.data;
  }

  static async updateHomepageLink(schoolId, contentId, contentDetails) {
    const response = await api.put(
      `/schools/${schoolId}/homepages/${contentId}`,
      contentDetails
    );
    return response.data;
  }

  static async deleteLink(id) {
    await api.delete(`/links/${id}`);
  }

  static async deleteStack(id) {
    await api.delete(`/stacks/${id}`);
  }

  static async deletePlatform(platformId) {
    await api.delete(`/platform/${platformId}`);
  }

  static async getTags() {
    const response = await api.get("/tags");
    return response.data;
  }

  static async searchImages(searchQuery) {
    const config = {
      params: {
        query: searchQuery,
        count: 3,
      },
    };
    try {
      const result = (await api.get("/img/random", config)).data;
      return result;
    } catch (error) {
      return null;
    }
  }

  static async createTeacher(email, name, teacherName, isAdmin) {
    /* expect
            email: string
         */
    const postData = {
      email,
      name,
      teacherName,
      isAdmin,
    };

    const response = await api.post(
      "/teachers/createTeacherWithEmail",
      postData
    ); // static within this binds this to static properties
    return response.data;
  }

  static async createWorksheet(details) {
    const response = await api.post("/worksheets", details);
    return response.data;
  }

  // static async doit() {
  //   const response = await api.get("/classrooms/boo/doit");
  //   return response.data;
  // }

  static async updateWorksheet(id, details) {
    const response = await api.put(`/worksheets/${id}`, details);
    return response.data;
  }

  static async addWorksheetQuestion(id, question) {
    const response = await api.post(`/worksheets/${id}/question`, question);
    return response.data;
  }

  // static async addBlankWorksheetQuestionOption(id, questionId) {
  //   const response = await api.post(`/worksheets/${id}/question`, question);
  //   return response.data;
  // }

  // static async addBlankWorksheetQuestion(id) {
  //   const response = await api.post(`/worksheets/${id}/question/blank`);
  //   return response.data;
  // }

  static async removeWorksheetQuestion(id, questionId) {
    const response = await api.delete(
      `/worksheets/${id}/question/${questionId}`
    );
    return response.data;
  }

  static async updateWorksheetQuestion(id, details) {
    const response = await api.patch(`/worksheets/questions/${id}`, details);
    return response.data;
  }

  static async addBlankWorksheetQuestionOption(questionId) {
    const response = await api.post(
      `/worksheets/questions/${questionId}/option`
    );
    return response.data;
  }

  static async deleteWorksheetQuestionOption(questionId, optionId) {
    const response = await api.delete(
      `/worksheets/questions/${questionId}/option/${optionId}`
    );
    return response.data;
  }

  static async updateWorksheetAnswerScore(answersId, questionId, score) {
    const response = await api.patch(
      `/worksheets/answers/${answersId}/${questionId}/score`,
      { score }
    );
    return response.data;
  }

  static async updateWorksheetAnswerComment(answersId, questionId, comment) {
    const response = await api.patch(
      `/worksheets/answers/${answersId}/${questionId}/comment`,
      { comment }
    );
    return response.data;
  }

  static async updateWorksheetOverallComment(answersId, comment) {
    const response = await api.patch(
      `/worksheets/answers/${answersId}/overallComment`,
      { comment }
    );
    return response.data;
  }

  static async changeStudentAnswersState(studentId, worksheetId, state) {
    const response = await api.put(
      `/students/${studentId}/worksheets/${worksheetId}/state`,
      { state }
    );
    return response.data;
  }

  static async changeMultipleStudentAnswersStates(worksheetId, studentStates) {
    const response = await api.patch(
      `/students/worksheets/${worksheetId}/state`,
      { studentStates }
    );
    return response.data;
  }

  static async getClassroomFeed(id) {
    const response = await api.get(`/classrooms/${id}/feed`);
    return response.data;
  }

  static async getTeacherFeed(id) {
    const response = await api.get(`/teachers/${id}/feed`);
    return response.data;
  }

  static async getStudentFeed(id) {
    const response = await api.get(`/students/${id}/feed`);
    return response.data;
  }

  static async deleteWorksheet(id) {
    await api.delete(`/worksheets/${id}`);
  }

  static async getS3ImageUploadParams(fileExtension, contentType) {
    const response = await api.post(`/img/sign`, {
      fileExtension,
      contentType,
    });
    return response.data;
  }

  static async uploadToS3(signedUrl, uploadFile) {
    const response = await s3Api.put(signedUrl, uploadFile, {
      headers: {
        "Content-Type": uploadFile.type,
      },
    });
    return response.data;
  }

  static async getURLImagePreview(url) {
    const response = await api.post(`/img/getURLImagePreview`, { urlVal: url });
    return response.data;
  }

  static async updateLatestClassroomViewTime(classroomId, teacherId) {
    const response = await api.get(
      `/classrooms/${classroomId}/resetNewFeedItemCount/${teacherId}`
    );
    return response.data;
  }

  static async verifyJoinCode(joinCode) {
    const response = await api.post(`/schools/verifyJoinCode`, { joinCode });
    return response.data;
  }

  static async verifyTeacherCode(teacherCode) {
    const response = await api.post(`/schools/verifyTeacherCode`, {
      teacherCode,
    });
    return response.data;
  }

  static async changeSchool(teacherId, schoolId) {
    const response = await api.post(`/teachers/${teacherId}/changeSchool`, {
      schoolId,
    });
    return response.data;
  }

  static async getPendingDevices(schoolId) {
    const response = await api.get(`/schools/${schoolId}/devices/pending`, {
      schoolId,
    });
    console.log(response.data);
    return response.data;
  }

  static async getTeacherApprovalRequests(schoolId) {
    const response = await api.get(
      `/schools/${schoolId}/teachers/approvalRequests`,
      {
        schoolId,
      }
    );
    return response.data;
  }

  static async approveTeacherJoiningRequest(teacherId, reviewerId) {
    const response = await api.post(
      `/teachers/${teacherId}/approveJoiningRequest`,
      { reviewerId }
    );
    return response.data;
  }

  static async cancelTeacherJoiningRequest(teacherId) {
    const response = await api.post(
      `/teachers/${teacherId}/denyJoiningRequest`
    );
    return response.data;
  }

  static async createChannel(title, color, schoolId, creatorId) {
    const response = await api.post("/channels", {
      title,
      color,
      schoolId,
      creatorId,
    });
    return response.data;
  }

  static async createStack(details) {
    const response = await api.post("/stacks", details);
    return response.data;
  }

  static async deleteChannel(id) {
    const response = await api.delete(`/channels/${id}`);
    return response.data;
  }

  static async duplicateStack(id) {
    const response = await api.post(`/stacks/${id}/duplicate`);
    return response.data;
  }

  static async duplicateChannel(id) {
    const response = await api.post(`/channels/${id}/duplicate`);
    return response.data;
  }

  static async getDomainsWithLinks(
    id,
    page,
    sortColumn,
    sortDirection,
    searchValue
  ) {
    const response = await api.get(`/schools/${id}/domainsWithLinks`, {
      params: { page, sortColumn, sortDirection, searchValue },
    });
    return response.data;
  }

  static async getDomainLinks(schoolId, domain) {
    const response = await api.get(`/schools/${schoolId}/domainLinks`, {
      params: { domain },
    });
    return response.data;
  }

  static async deleteDomain(schoolId, domainId) {
    const response = await api.delete(
      `/schools/${schoolId}/domains/${domainId}`
    );
    return response.data;
  }

  static async unStackifyLink(id) {
    const response = await api.post(`/links/${id}/unStackify`);
    return response.data;
  }

  static async unFreeifySchool(id) {
    const response = await api.post(`/schools/${id}/unFreeify`);
    return response.data;
  }

  static async clearFilterNotificationCount(id) {
    const response = await api.post(
      `/teachers/${id}/clearFilterNotificationCount`
    );
    return response.data;
  }

  static async clearDevicesNotificationCount(id) {
    const response = await api.post(
      `/teachers/${id}/clearDevicesNotificationCount`
    );
    return response.data;
  }

  static async clearPeopleNotificationCount(id) {
    const response = await api.post(
      `/teachers/${id}/clearPeopleNotificationCount`
    );
    return response.data;
  }

  static async startLock(schoolId, deviceIds, endTime) {
    const response = await api.patch(`/schools/${schoolId}/startLock`, {
      deviceIds,
      endTime,
    });
    return response.data;
  }

  static async endLock(schoolId) {
    const response = await api.patch(`/schools/${schoolId}/endLock`);
    return response.data;
  }
}

export default ApiController;
