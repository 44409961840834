import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { Box, Stack, alpha } from "@mui/system";
import NotificationContext from "../../contexts/NotificationContext";
import ApiController from "../../controllers/ApiController";
import { useUserContext } from "../../contexts/UserContext";
import _ from "lodash";
import { useUserDataContext } from "../../contexts/UserDataContext";
import DynamicCardGrid from "../../components/DynamicCardGrid";
import GCLessonImportDialog from "../AuthFlow/states/GCLessonImportDialog";
import { PALETTE } from "../../palette";
import Typography from "../../components/Typography";
import UrsorButton from "../../components/buttons/UrsorButton";

const ONLINE_THRESHOLD = 70; // seconds
export const MONITORING_UPDATE_PERIOD = 15 * 1000; // milliseconds

const MIN_CARD_WIDTH = "260px";

export interface IDevicesPageProps {}

export interface IAddOn {
  name: string;
  description: string;
  logoUrl: string;
  //connectCallback: () => void;
}

const ADDONS: IAddOn[] = [
  {
    name: "Google Classroom",
    description:
      "Connect to share links, student accounts and login access from google classroom.",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Google_Classroom_Logo.svg/1024px-Google_Classroom_Logo.svg.png",
    //connectCallback: () => null,
  },
];

const AddOnCard = (props: { addOn: IAddOn; connected: boolean }) => (
  <Stack spacing="8px" p="10px" bgcolor="rgb(255,255,255)" borderRadius="12px">
    <Stack direction="row" spacing="8px">
      <img height="34px" width="34px" src={props.addOn.logoUrl} />
      <Stack>
        <Typography bold>{props.addOn.name}</Typography>
        <Typography variant="tiny" color={PALETTE.secondary.grey[3]}>
          Classroom Management Tool
        </Typography>
      </Stack>
    </Stack>
    <Stack>
      <Typography variant="small" color={PALETTE.secondary.grey[3]}>
        {props.addOn.description}
      </Typography>
    </Stack>
    <UrsorButton
      backgroundColor={props.connected ? PALETTE.secondary.green[4] : undefined}
      size="small"
      disabled={props.connected}
      onClick={() => null}
    >
      {props.connected ? "Connected" : "Connect"}
    </UrsorButton>
  </Stack>
);

export default function AddonsPage(props: IDevicesPageProps) {
  const userCtx = useUserContext();
  const dataCtx = useUserDataContext();

  const [gcLessonImportDialogOpen, setGCLessonImportDialogOpen] =
    useState<boolean>(false);

  const submitConnected = () =>
    ApiController.updateTeacher(userCtx.userDetails?.id, {
      gcConnectionStepDone: true,
    }).then(() => userCtx.load(userCtx.userDetails?.email));
  //ApiController.submitAddonConnected(id)

  const submitGCSync = (on: boolean) =>
    ApiController.updateTeacher(userCtx.userDetails?.id, {
      gcSync: on,
    }).then(() => userCtx.load(userCtx.userDetails?.email));

  return (
    <>
      <PageLayout title="Plugins" 
              description="Connect other education platforms to enable their content to automatically load into the AstroSafe Browser!"
              selectedSidebarItemId="plugins"
              >
        <DynamicCardGrid
          cardWidth={MIN_CARD_WIDTH}
          rowGap="24px"
          columnGap="24px"
        >
          {ADDONS.map((a) => (
            <Stack
              onClick={() =>
                !userCtx.userDetails?.gcConnectionStepDone &&
                setGCLessonImportDialogOpen(true)
              }
              sx={{
                cursor: "pointer",
                "&:hover": { opacity: 0.7 },
                transition: "0.2s",
              }}
            >
              <AddOnCard
                addOn={a}
                connected={!!userCtx.userDetails?.gcConnectionStepDone}
              />
            </Stack>
          ))}
        </DynamicCardGrid>
      </PageLayout>
      <GCLessonImportDialog
        open={gcLessonImportDialogOpen}
        closeCallback={() => {
          setGCLessonImportDialogOpen(false);
        }}
        callback={() => {
          submitConnected();
          dataCtx.refreshStacks();
          dataCtx.refreshLinks();
        }}
        syncCallback={submitGCSync}
      />
    </>
  );
}
