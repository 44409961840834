import React, { useState } from "react";
import { Box, Stack } from "@mui/system";
import Typography from "../../../components/Typography";
import { PALETTE } from "../../../palette";
import UrsorDialog from "../../../components/UrsorDialog";
import ApiController from "../../../controllers/ApiController";
import UrsorTabSwitch from "../../../components/UrsorTabSwitch";
import _ from "lodash";
import { Input } from "@mui/material";
import UrsorInputField from "../../../components/inputs/UrsorInputField";

const JOIN_CODE_LENGTH = 9;
export const FAILURE_DURATION = 2000;

const JoiningCodeInput = (props: {
  value: string;
  callback: (value: string) => void;
  showFailure: boolean;
  active: boolean;
  activeCallback: (active: boolean) => void;
}) => {
  return (
    <>
      {props.active ? (
        <Box height={0} sx={{ opacity: 0, pointerEvents: "none" }}>
          <Input
            autoFocus
            value={props.value}
            onChange={(event) =>
              event.target.value.length <= JOIN_CODE_LENGTH &&
              props.callback(event.target.value.toUpperCase())
            }
            onBlur={() => props.activeCallback(false)}
          />
        </Box>
      ) : null}
      <Stack direction="row" spacing="42px" height="100%" alignItems="center">
        {_.chunk([...Array(JOIN_CODE_LENGTH).keys()], 3).map((indices, i) => (
          <Stack
            key={i}
            height="100%"
            alignItems="center"
            direction="row"
            spacing="14px"
            onClick={() => props.activeCallback(true)}
          >
            {indices.map((i) => (
              <Stack
                key={i}
                width="60px"
                height="74px"
                bgcolor={PALETTE.secondary.grey[2]}
                borderRadius="12px"
                justifyContent="center"
                alignItems="center"
                border="3px solid white"
                sx={{
                  outline: props.showFailure
                    ? `3px solid ${PALETTE.system.red}`
                    : props.active && props.value.length === i
                    ? `3px solid ${PALETTE.secondary.purple[2]}`
                    : undefined,
                  "&:hover": { opacity: props.active ? 1 : 0.6 },
                  transition: "0.2s",
                  cursor: props.active ? "default" : "pointer",
                }}
              >
                <Typography variant="h4">
                  {props.value.split("")[i] ?? ""}
                </Typography>
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </>
  );
};

const SchoolJoiningDialog = (props: {
  open: boolean;
  //closeCallback: () => void;
  joinCallback: (schoolId: string) => void;
  createCallback: (schoolName: string) => void;
  skipCallback: () => void;
}) => {
  const [inputedCode, setInputedCode] = useState<string>("");
  const [showFailure, setShowFailure] = useState<boolean>(false);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<"join" | "create">("join");
  const [schoolName, setSchoolName] = useState<string>("");
  return (
    <UrsorDialog
      title={activeTab === "join" ? "Join a School" : "Create a School"}
      subtitle={
        activeTab === "join"
          ? [
              "Join a school account by inputting the Join School code",
              "provided by your Astro admin.",
            ]
          : ["Set up a new School by adding", "a School name."]
      }
      supertitle="Create Account"
      open={props.open}
      button={{
        text: activeTab === "join" ? "Join" : "Create",
        callback: () =>
          activeTab === "join"
            ? ApiController.verifyTeacherCode(inputedCode)
                .then((school) => props.joinCallback(school.id))
                .catch(() => {
                  setShowFailure(true);
                  setTimeout(() => {
                    setShowFailure(false);
                    setInputActive(true);
                    setInputedCode("");
                  }, FAILURE_DURATION);
                })
            : props.createCallback(schoolName),
        disabled:
          activeTab === "join"
            ? inputedCode.length !== JOIN_CODE_LENGTH
            : !schoolName,
      }}
      secondaryButton={{
        text: "Do this later",
        variant: "secondary",
        callback: props.skipCallback,
      }}
      noCloseButton
      noBackdrop
    >
      <Stack position="absolute" top="35px">
        <UrsorTabSwitch
          activeTab={activeTab}
          tabs={[
            {
              id: "join",
              value: "Join School",
            },
            {
              id: "create",
              value: "Create School",
            },
          ]}
          callback={(tab) => setActiveTab(tab)}
        />
      </Stack>

      {activeTab === "join" ? (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <JoiningCodeInput
            value={inputedCode}
            callback={(value) => setInputedCode(value)}
            showFailure={showFailure}
            active={inputActive}
            activeCallback={(active) => setInputActive(active)}
          />
        </Stack>
      ) : (
        <UrsorInputField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSchoolName(event.target.value)
          }
          value={schoolName}
          placeholder={"School Name"}
          width={410}
        />
      )}
    </UrsorDialog>
  );
};

export default SchoolJoiningDialog;
