import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import UrsorButton, {
  ButtonVariant,
} from "../../components/buttons/UrsorButton";
import UrsorInputField from "../../components/inputs/UrsorInputField";
import PageLayout, { SIDEBAR_Y_MARGIN } from "../../components/PageLayout";
import NotificationContext from "../../contexts/NotificationContext";
import { useUserContext } from "../../contexts/UserContext";
import ApiController from "../../controllers/ApiController";
import Typography from "../../components/Typography";
import { useUserDataContext } from "../../contexts/UserDataContext";
import { ISchool } from "../AdminPage/AdminPage";
import { ReactComponent as LogOutIcon } from "../../images/icons/LogOutIcon.svg";
import { ReactComponent as MortarBoardIcon } from "../../images/icons/MortarBoardIcon.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { redirectUrl } from "../../hooks/configureAmplify";
import axios from "axios";
import { useLocalStorage } from "usehooks-ts";
import { PALETTE } from "../../palette";
import JoiningCodeInput from "../../components/JoinCodeInput";
import { DEFAULT_DEVICE_LIMIT } from "../DevicesPage/DevicesPage";
import { FAILURE_DURATION } from "../AuthFlow/states/SchoolJoiningDialog";
import { ITeacher, kickOutOfSchool } from "../AdminPage/AdminPageTeachersTab";
import DeleteAccountDialog from "./dialogs/DeleteAccountDialog";
import { ReactComponent as Illustration } from "../../images/DefaultIllustration.svg";
import moment from "moment";
import { getPrefixRemovedUrl } from "../LibraryPage/components/LinkCard";
// import mixpanel from "mixpanel-browser";

const PADDING = "20px";
const SECTION_SPACING = "10px";
const TITLE_CONTENT_SPACING = "6px";

const SCHOOL_SECTION_FADEIN_DELAY = 600;

export interface IAccountPageProps {}

const AccountPageSection = (props: {
  title: string;
  button?: { variant: ButtonVariant; text: string; callback: () => void };
  children: React.ReactNode;
  flex?: boolean;
  yFlex?: boolean;
  orange?: boolean;
  fadeInDelay: number;
}) => (
  <Stack
    flex={props.flex ? 1 : props.yFlex ? "1 0" : undefined}
    position="relative"
  >
    <Stack
      spacing="16px"
      bgcolor={props.orange ? PALETTE.secondary.orange[1] : "rgb(255,255,255)"}
      p={PADDING}
      border={
        props.orange ? `2px solid ${PALETTE.secondary.orange[4]}` : undefined
      }
      borderRadius="12px"
      flex={props.flex ? 1 : props.yFlex ? "1 0" : undefined}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="large" bold>
          {props.title}
        </Typography>
        {props.button ? (
          <UrsorButton
            onClick={props.button.callback}
            variant={props.button.variant}
            size="small"
          >
            {props.button.text}
          </UrsorButton>
        ) : null}
      </Stack>
      {props.children}
    </Stack>
  </Stack>
);

const AccountPageSchoolDetailsSection = (props: {
  school: ISchool;
  leaveCallback: () => void;
  static?: boolean;
  updateCallback: () => void;
}) => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [postcode, setPostcode] = useState<string | undefined>(undefined);
  const [country, setCountry] = useState<string | undefined>(undefined);
  useEffect(() => {
    setName(props.school.name);
    props.school.website && setUrl(props.school.website);
    props.school.email && setEmail(props.school.email);
    props.school.address && setAddress(props.school.address);
    props.school.postcode && setPostcode(props.school.postcode);
    props.school.country && setCountry(props.school.country);
  }, [props.school]);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveButtonDisabled(
      ((!url && !props.school.website) || url === props.school.website) &&
        ((!email && !props.school.email) || email === props.school.email) &&
        ((!address && !props.school.address) ||
          address === props.school.address) &&
        ((!postcode && !props.school.postcode) ||
          postcode === props.school.postcode) &&
        ((!country && !props.school.country) ||
          country === props.school.country)
    );
  }, [
    url,
    email,
    address,
    postcode,
    country,
    props.school.website,
    props.school.email,
    props.school.address,
    props.school.postcode,
    props.school.country,
  ]);

  const notificationCtx = React.useContext(NotificationContext);
  const submitSchoolUpdate = () =>
    ApiController.updateSchool(props.school.id, {
      website: url,
      email,
      address,
      postcode,
      country,
    })
      .then(() => notificationCtx.success("Updated School"))
      .then(props.updateCallback);

  return (
    <AccountPageSection
      title={`Connected to ${props.school.name}`}
      button={{
        variant: "secondary",
        text: "Leave School",
        callback: props.leaveCallback,
      }}
      yFlex
      fadeInDelay={SCHOOL_SECTION_FADEIN_DELAY}
    >
      <Stack direction="row" spacing="14px">
        <Stack spacing={TITLE_CONTENT_SPACING} flex={1}>
          <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
            <Typography>School name</Typography>
            {props.static ? (
              <Stack pb="12px">
                <Typography bold>{name}</Typography>
              </Stack>
            ) : (
              <UrsorInputField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
                value={name}
                placeholder={"School name"}
                width="100%"
                leftAlign
              />
            )}
          </Stack>
          <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
            <Typography>Website</Typography>
            {props.static ? (
              <Stack
                pb="12px"
                sx={{
                  cursor: "pointer",
                  "&:hover": { opacity: 0.6 },
                  transition: "0.2s",
                }}
              >
                <a
                  target="_blank"
                  href={url}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography bold>
                    {url ? getPrefixRemovedUrl(url) : undefined}
                  </Typography>
                </a>
              </Stack>
            ) : (
              <UrsorInputField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUrl(event.target.value)
                }
                value={url}
                placeholder={"Website"}
                width="100%"
                leftAlign
              />
            )}
          </Stack>
          <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
            <Typography>Contact email</Typography>
            {props.static ? (
              <Stack pb="12px">
                <Typography bold>{email}</Typography>
              </Stack>
            ) : (
              <UrsorInputField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(event.target.value)
                }
                value={email}
                placeholder={"Contact email"}
                width="100%"
                leftAlign
              />
            )}
          </Stack>
        </Stack>
        <Stack spacing={TITLE_CONTENT_SPACING} flex={1}>
          <Stack spacing={TITLE_CONTENT_SPACING}>
            <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
              <Typography>Address</Typography>
              {props.static ? (
                <Stack pb="12px">
                  <Typography bold>{address}</Typography>
                </Stack>
              ) : (
                <UrsorInputField
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setAddress(event.target.value)
                  }
                  value={address}
                  placeholder={"Address"}
                  width="100%"
                  leftAlign
                />
              )}
            </Stack>
            <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
              <Typography>Zip / postcode</Typography>
              {props.static ? (
                <Stack pb="12px">
                  <Typography bold>{postcode}</Typography>
                </Stack>
              ) : (
                <UrsorInputField
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPostcode(event.target.value)
                  }
                  value={postcode}
                  placeholder={"Zip / postcode"}
                  width="100%"
                  leftAlign
                />
              )}
            </Stack>
            <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
              <Typography>Country</Typography>
              {props.static ? (
                <Stack pb="12px">
                  <Typography bold>{country}</Typography>
                </Stack>
              ) : (
                <UrsorInputField
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setCountry(event.target.value)
                  }
                  value={country}
                  placeholder={"Country"}
                  width="100%"
                  leftAlign
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        flex={1}
        direction="row"
        alignItems="flex-end"
        sx={{
          opacity: saveButtonDisabled ? 0.4 : 1,
          pointerEvents: saveButtonDisabled ? "none" : undefined,
        }}
      >
        <UrsorButton size="small" onClick={submitSchoolUpdate}>
          Save
        </UrsorButton>
      </Stack>
    </AccountPageSection>
  );
};

const AccountPageJoinSchoolSection = (props: {
  inputedCode: string;
  inputActive: boolean;
  setInputedCode: (code: string) => void;
  setInputActive: (active: boolean) => void;
  showFailure: boolean;
  changeSchoolCallback: () => void;
  createSchoolCallback: () => void;
}) => (
  <AccountPageSection
    title="School"
    button={{
      variant: "secondary",
      text: "Create School",
      callback: props.createSchoolCallback,
    }}
    yFlex
    fadeInDelay={SCHOOL_SECTION_FADEIN_DELAY}
  >
    <Stack flex={1} spacing="22px" alignItems="center" justifyContent="center">
      <Typography variant="medium" bold>
        Join a School with your Code
      </Typography>
      <div>
        <JoiningCodeInput
          value={props.inputedCode}
          callback={(value) => props.setInputedCode(value)}
          active={props.inputActive}
          activeCallback={(active) => props.setInputActive(active)}
          showFailure={props.showFailure}
          rectWidth="48px"
          rectHeight="60px"
          rectSpacing="8px"
          fontSize="h5"
        />
      </div>
      <div>
        <UrsorButton size="small" onClick={props.changeSchoolCallback}>
          Join School
        </UrsorButton>
      </div>
    </Stack>
  </AccountPageSection>
);

const AccountPagePendingApprovalSection = (props: { schoolName: string }) => (
  <AccountPageSection
    title="School"
    yFlex
    orange
    fadeInDelay={SCHOOL_SECTION_FADEIN_DELAY}
  >
    <Stack flex={1} alignItems="center" justifyContent="center">
      <Typography variant="medium" bold>
        {`Waiting for a colleague in ${props.schoolName} to approve you as a Teacher.`}
      </Typography>
      <Illustration height="200px" />
    </Stack>
  </AccountPageSection>
);

const AccountPageInvitationSection = (props: {
  schoolName: string;
  inviterName: string;
}) => {
  const userCtx = useUserContext();
  const notificationCtx = React.useContext(NotificationContext);
  const submitReplyToInvitation = (accept: boolean) =>
    ApiController.replyToInvitation(userCtx.userDetails?.id, accept).then(
      () => {
        userCtx.load(userCtx.userDetails?.email);
      }
    );
  return (
    <AccountPageSection
      title="School"
      yFlex
      orange
      fadeInDelay={SCHOOL_SECTION_FADEIN_DELAY}
    >
      <Stack flex={1} alignItems="center" justifyContent="center">
        <Stack direction="row" spacing="5px" pb="22px">
          <Typography variant="medium" bold>
            {props.inviterName}
          </Typography>
          <Typography variant="medium">has invited you to join</Typography>
          <Typography variant="medium" bold>
            {props.schoolName}
          </Typography>
        </Stack>
        <Stack direction="row" spacing="10px">
          <UrsorButton
            onClick={() =>
              submitReplyToInvitation(true).then(() =>
                notificationCtx.success(`Joined ${props.schoolName}`)
              )
            }
          >
            Accept
          </UrsorButton>
          <UrsorButton
            variant="secondary"
            backgroundColor="transparent"
            onClick={() => submitReplyToInvitation(false)}
          >
            Reject
          </UrsorButton>
        </Stack>
        <Illustration height="200px" />
      </Stack>
    </AccountPageSection>
  );
};

export default function AccountPage(props: IAccountPageProps) {
  const userCtx = useUserContext();
  const notificationCtx = React.useContext(NotificationContext);
  const dataCtx = useUserDataContext();
  const { logout } = useAuth0();

  const [showFailure, setShowFailure] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [teachingName, setTeachingName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [school, setSchool] = useState<ISchool | undefined>(undefined);

  const [inputedCode, setInputedCode] = useState<string>("");
  const [inputActive, setInputActive] = useState<boolean>(false);

  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (userCtx.userDetails) {
      setName(userCtx.userDetails.realName);
      setTeachingName(userCtx.userDetails.teacherName);
      setEmail(userCtx.userDetails.email);
    }
  }, [userCtx.userDetails]);

  const loadSchool = (id?: string) =>
    ApiController.getSchool(id || userCtx.userDetails?.schoolId)
      .then((school) => {
        setSchool(school);
      })
      .catch((error) => notificationCtx.error(error.message));

  useEffect(() => {
    if (userCtx.userDetails) {
      loadSchool();
    }
  }, [userCtx.userDetails?.id, userCtx.userDetails?.schoolId]);

  const [
    showPasswordChangeFlowTriggeredInfo,
    setShowPasswordChangeFlowTriggeredInfo,
  ] = useState<boolean>(false);
  const triggerPasswordChangeFlow = () =>
    axios
      .request({
        method: "POST",
        url: `${"https://"}${
          process.env.REACT_APP_AUTH0_DOMAIN as string
        }/dbconnections/change_password`,
        headers: { "content-type": "application/json" },
        data: {
          client_id: process.env.REACT_APP_CLIENT_ID as string,
          email: userCtx.userDetails?.email,
          connection: "Username-Password-Authentication",
        },
      })
      .then(() => setShowPasswordChangeFlowTriggeredInfo(true))
      .catch(function (error) {
        console.error(error);
      });

  const changeSchool = () =>
    ApiController.verifyTeacherCode(inputedCode)
      .then(
        (school) =>
          school?.id &&
          ApiController.updateTeacher(userCtx.userDetails?.id, {
            requestedSchoolId: school.id,
          })

        //loadSchool(school?.id);

        // ApiController.changeSchool(userCtx.userDetails?.id, school?.id).then(
        //   () => {
        //     userCtx.load(userCtx.userDetails?.email);
        //     loadSchool(school?.id);
        //   }
        // )
      )
      .then(() => userCtx.load(userCtx.userDetails?.email))
      .catch(() => {
        setShowFailure(true);
        setTimeout(() => {
          setShowFailure(false);
          setInputActive(true);
          setInputedCode("");
        }, FAILURE_DURATION);
      });

  const logOut = () => {
    //mixpanel.reset();
    localStorage.clear();
    userCtx.clear();
    logout({ logoutParams: { returnTo: redirectUrl } });
  };

  const [pendingSchoolName, setPendingSchoolName] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    if (userCtx.userDetails?.requestedSchoolId) {
      ApiController.getSchool(userCtx.userDetails?.requestedSchoolId).then(
        (school) => setPendingSchoolName(school.name)
      );
    } else {
      setPendingSchoolName(undefined);
    }
  }, [userCtx.userDetails?.requestedSchoolId]);

  const [invitedSchoolName, setInvitedSchoolName] = useState<
    string | undefined
  >(undefined);
  const [inviterName, setInviterName] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (userCtx.userDetails?.invitationPendingByInviterId) {
      ApiController.getTeacher(
        userCtx.userDetails?.invitationPendingByInviterId
      ).then((teacher) => {
        setInviterName(teacher.teacherName);
        ApiController.getSchool(teacher.schoolId).then((school) =>
          setInvitedSchoolName(school.name)
        );
      });
    } else {
      setInvitedSchoolName(undefined);
      setInviterName(undefined);
    }
  }, [
    userCtx.userDetails?.requestedSchoolId,
    userCtx.userDetails?.invitationPendingByInviterId,
  ]);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveButtonDisabled(
      name === userCtx.userDetails?.realName &&
        teachingName === userCtx.userDetails?.teacherName
    );
  }, [
    name,
    teachingName,
    userCtx.userDetails?.realName,
    userCtx.userDetails?.teacherName,
  ]);

  const submitUpdate = () =>
    ApiController.updateTeacher(userCtx.userDetails?.id, {
      realName: name,
      teacherName: teachingName,
    })
      .then(() => notificationCtx.success("Updated Account"))
      .then(() => userCtx.load(userCtx.userDetails?.email));

  return (
    <>
      <PageLayout
        scrollable
        bodyWidth="100%"
        selectedSidebarItemId="account"
        title="Account"
        description="Here you can manage your personal details, manage the School you are a part of, and manage your plan."
        secondaryButton={{
          text: "Log out",
          callback: logOut,
          icon: LogOutIcon,
        }}
        disableConnectionBar
      >
        <Stack
          direction="row"
          spacing={SECTION_SPACING}
          flex={1}
          pb={`calc(${SIDEBAR_Y_MARGIN} + 2px)`}
        >
          <Stack spacing={SECTION_SPACING} flex={1} minWidth="625px">
            <AccountPageSection
              title="Profile"
              button={{
                variant: "secondary",
                text: "Delete account",
                callback: () => setDeleteAccountDialogOpen(true),
              }}
              fadeInDelay={200}
            >
              <Stack direction="row" spacing="26px">
                <Stack>
                  <Stack
                    width="160px"
                    height="160px"
                    borderRadius="100%"
                    bgcolor={PALETTE.secondary.grey[1]}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      svg: {
                        path: {
                          fill: PALETTE.secondary.grey[3],
                        },
                      },
                    }}
                  >
                    <MortarBoardIcon height="60px" width="60px" />
                  </Stack>
                </Stack>
                <Stack width="100%" spacing="12px" alignItems="center">
                  <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
                    <Typography>My name</Typography>
                    <UrsorInputField
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setName(event.target.value)
                      }
                      value={name}
                      placeholder={"Name"}
                      width="100%"
                      leftAlign
                    />
                  </Stack>
                  <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
                    <Typography>My teaching name</Typography>
                    <UrsorInputField
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setTeachingName(event.target.value)
                      }
                      value={teachingName}
                      placeholder={"Teaching name"}
                      width="100%"
                      leftAlign
                    />
                  </Stack>
                  <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
                    <Typography>My email</Typography>
                    <Stack
                      sx={{
                        pointerEvents: "none",
                      }}
                    >
                      <UrsorInputField
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setEmail(event.target.value)}
                        value={email}
                        placeholder={"Email"}
                        width="100%"
                        color={PALETTE.secondary.grey[4]}
                        leftAlign
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                direction="row"
                alignItems="flex-end"
                sx={{
                  opacity: saveButtonDisabled ? 0.4 : 1,
                  pointerEvents: saveButtonDisabled ? "none" : undefined,
                }}
              >
                <UrsorButton size="small" onClick={submitUpdate}>
                  Save
                </UrsorButton>
              </Stack>
            </AccountPageSection>
            {school && pendingSchoolName ? (
              <AccountPagePendingApprovalSection
                schoolName={pendingSchoolName}
              />
            ) : school && invitedSchoolName && inviterName ? (
              <AccountPageInvitationSection
                schoolName={invitedSchoolName}
                inviterName={inviterName}
              />
            ) : school && !school?.free ? (
              <AccountPageSchoolDetailsSection
                school={school}
                leaveCallback={() =>
                  userCtx.userDetails?.teacherName &&
                  kickOutOfSchool(
                    userCtx.userDetails.teacherName,
                    userCtx.userDetails.id
                  )
                    .then(() =>
                      ApiController.cancelTeacherJoiningRequest(
                        userCtx.userDetails?.id
                      )
                    )
                    .then(() => userCtx.load(userCtx.userDetails?.email))
                    .then(() =>
                      notificationCtx.success("You've left your School.")
                    )
                }
                static={!userCtx.userDetails?.isAdmin}
                updateCallback={loadSchool}
              />
            ) : (
              <AccountPageJoinSchoolSection
                inputActive={inputActive}
                setInputActive={setInputActive}
                inputedCode={inputedCode}
                setInputedCode={setInputedCode}
                showFailure={showFailure}
                changeSchoolCallback={changeSchool}
                createSchoolCallback={() =>
                  ApiController.unFreeifySchool(
                    userCtx.userDetails?.schoolId
                  ).then(() => loadSchool())
                }
              />
            )}
          </Stack>
          <Stack spacing={SECTION_SPACING} flex={1}>
            <AccountPageSection
              title="Plan"
              button={{
                variant: "primary",
                text: "Contact Sales",
                callback: () => window.open("mailto:hello@astrosafe.co"),
              }}
              fadeInDelay={200}
            >
              {school ? (
                <>
                  <Stack spacing="8px" width="100%">
                    <Typography>Your Astro plan</Typography>
                    <Typography variant="h3">{`${
                      school?.deviceLimit || DEFAULT_DEVICE_LIMIT
                    } Device${
                      school?.deviceLimit === 1 ? "" : "s"
                    }`}</Typography>
                  </Stack>
                  <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
                    <Typography>Seats used</Typography>
                    <Typography variant="h3">{`${
                      school?.devices.filter((d) => d.connected !== "denied")
                        .length
                    }`}</Typography>
                  </Stack>
                  {school?.expirationDate ? (
                    <Stack spacing={TITLE_CONTENT_SPACING} width="100%">
                      <Typography>Expires on</Typography>
                      <Typography variant="h3">
                        {moment(school.expirationDate).format("Do MMMM YYYY")}
                      </Typography>
                    </Stack>
                  ) : null}
                </>
              ) : null}
            </AccountPageSection>
            <AccountPageSection
              title="Feedback"
              button={{
                variant: "primary",
                text: "Send",
                callback: () => window.open("mailto:hello@astrosafe.co"),
              }}
              fadeInDelay={700}
            >
              <Typography>
                We’d love to hear your thoughts! The good, the bad, and the
                ugly. Please send us through any considerations you have about
                the app, or let us know if you encounter any bugs or hiccups!
              </Typography>
            </AccountPageSection>
            <AccountPageSection title="Boring bits" flex fadeInDelay={1100}>
              <Stack spacing="6px">
                <a
                  target="_blank"
                  href="https://www.astrosafe.co/terms-and-conditions"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Stack
                    sx={{
                      cursor: "pointer",
                      "&:hover": { opacity: 0.6 },
                      transition: "0.2s",
                    }}
                  >
                    <Typography color={PALETTE.secondary.blue[3]}>
                      Terms & Conditions
                    </Typography>
                  </Stack>
                </a>
                <a
                  target="_blank"
                  href="https://www.astrosafe.co/app/privacy-policy"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Stack
                    sx={{
                      cursor: "pointer",
                      "&:hover": { opacity: 0.6 },
                      transition: "0.2s",
                    }}
                  >
                    <Typography color={PALETTE.secondary.blue[3]}>
                      Privacy policy
                    </Typography>
                  </Stack>
                </a>
              </Stack>
            </AccountPageSection>
          </Stack>
        </Stack>
      </PageLayout>
      <DeleteAccountDialog
        open={deleteAccountDialogOpen}
        closeCallback={() => setDeleteAccountDialogOpen(false)}
        callback={() => {
          ApiController.deleteTeacher(userCtx.userDetails?.id).then(
            logOut
            //navigate(getAbsoluteUrl("astrosafe.co"))
          );
        }}
      />
    </>
  );
}
