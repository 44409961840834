import _ from "lodash";
import React, { useContext, createContext, useState, useEffect } from "react";
import NotificationContext from "./NotificationContext";
import ApiController from "../controllers/ApiController";
import { useUserContext } from "./UserContext";
import { ITeacher } from "../pages/AdminPage/AdminPageTeachersTab";
import { IDevice, ISession } from "../pages/AdminPage/AdminPage";
import { MONITORING_UPDATE_PERIOD } from "../pages/DevicesPage/DevicesPage";
import { ILink } from "../pages/BrowserPage/dialogs/LinkDialog";
import { IChannel } from "../pages/LibraryPage/LibraryPage";
import { IPlatform } from "../pages/BrowserPage/components/PlatformCard";

export interface IStack {
  id: string;
  creatorId?: string;
  schoolId: string;
  channelId: string;
  title: string;
  description?: string;
  imageUrls: string[];
  backgroundColors: string[];
  createdAt: string;
  nLinks: number;
}

export interface IUserDataContext {
  links: ILink[] | undefined;
  refreshLinks: () => void;
  channels: IChannel[] | undefined;
  refreshChannels: () => void;
  stacks: IStack[] | undefined;
  refreshStacks: () => void;
  apps: IPlatform[] | undefined;
  refreshApps: () => void;
  teachers: ITeacher[];
  refreshTeachers: () => void;
  devices: IDevice[] | undefined;
  sessions: ISession[];
  refreshDevicesAndSessions: () => void;
}

const UserDataContext = createContext<IUserDataContext | undefined>(undefined);

const useUserDataContext = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error(
      "useUserDataContext must be used within a UserDataProvider"
    );
  }
  return context;
};

export interface IUserDataProviderProps {
  children: React.ReactNode;
}

const UserDataProvider = (props: IUserDataProviderProps) => {
  const userDetails = useUserContext().userDetails;
  const notificationCtx = useContext(NotificationContext);

  const [links, setLinks] = useState<ILink[] | undefined>(undefined);
  const [channels, setChannels] = useState<IChannel[] | undefined>(undefined);
  const [stacks, setStacks] = useState<IStack[] | undefined>(undefined);
  const [apps, setApps] = useState<IPlatform[] | undefined>(undefined);
  const [devices, setDevices] = useState<IDevice[] | undefined>(undefined);
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [teachers, setTeachers] = useState<ITeacher[]>([]);

  useEffect(() => {
    if (userDetails?.id) {
      loadLinks();
      loadChannels();
      loadStacks();
      loadApps();
      loadTeachers();
      loadDevicesAndSessions();
    }
  }, [userDetails?.id, userDetails?.schoolId]);

  const loadDevicesAndSessions = () =>
    userDetails?.schoolId &&
    ApiController.getSchool(userDetails.schoolId)
      .then((school) => {
        setSessions(school.sessions);
        setDevices(school.devices);
      })
      .catch((error) => notificationCtx.error(error.message));

  useEffect(() => {
    if (!userDetails?.id) return;
    const interval = setInterval(() => {
      loadDevicesAndSessions();
    }, MONITORING_UPDATE_PERIOD);
    return () => clearInterval(interval);
  }, [userDetails?.id, userDetails?.schoolId]);

  const loadTeachers = () =>
    ApiController.getTeachersInSchool(userDetails!.schoolId)
      .then((students) => setTeachers(students))
      .catch((error) => notificationCtx.error(error.message));

  const loadLinks = () =>
    ApiController.getLinksInSchool(userDetails!.schoolId)
      .then((links) => setLinks(links))
      .catch((error) => notificationCtx.error(error.message));

  const loadStacks = () =>
    ApiController.getStacksInSchool(userDetails!.schoolId)
      .then((stacks) => setStacks(stacks))
      .catch((error) => notificationCtx.error(error.message));

  const loadChannels = () =>
    ApiController.getChannelsInSchool(userDetails!.schoolId)
      .then((channels) => setChannels(channels))
      .catch((error) => notificationCtx.error(error.message));

  const loadApps = () =>
    ApiController.getAppsInSchool(userDetails!.schoolId)
      .then((apps) => setApps(apps))
      .catch((error) => notificationCtx.error(error.message));

  return (
    <UserDataContext.Provider
      value={{
        links,
        refreshLinks: loadLinks,
        channels,
        refreshChannels: loadChannels,
        stacks,
        refreshStacks: loadStacks,
        refreshApps: loadApps,
        apps: apps,
        teachers,
        refreshTeachers: loadTeachers,
        devices,
        sessions,
        refreshDevicesAndSessions: loadDevicesAndSessions,
      }}
    >
      {props.children}
    </UserDataContext.Provider>
  );
};

export { UserDataProvider, useUserDataContext };
