import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/system";
import FixedBottomDialog from "../../components/FixedBottomDialog";
import { PALETTE } from "../../palette";
import { AstroContent, GRID_SPACING } from "./LibraryPage";
import UrsorButton from "../../components/buttons/UrsorButton";
import Typography from "../../components/Typography";
import { IStack, useUserDataContext } from "../../contexts/UserDataContext";
import { ReactComponent as PersonIcon } from "../../images/icons/PersonIcon.svg";
import { ReactComponent as PencilIcon } from "../../images/icons/PencilIcon.svg";
import { ReactComponent as ClippyIcon } from "../../images/icons/ClippyIcon.svg";
import GraphIllustration from "../../images/GraphIllustration.svg";
import { ReactComponent as LinkIcon } from "../../images/icons/LinkIcon.svg";
import { ReactComponent as ChevronLeft } from "../../images/icons/ChevronLeft.svg";
import { ReactComponent as PlusIcon } from "../../images/icons/PlusIcon.svg";
import { ReactComponent as TrashcanIcon } from "../../images/icons/TrashcanIcon.svg";
import { ILink } from "../BrowserPage/dialogs/LinkDialog";
import moment from "moment";
import LinkCard from "./components/LinkCard";
import _ from "lodash";
import UrsorActionButton from "../../components/UrsorActionButton";
import { IActionPopupItem } from "../../components/ActionPopup";
import { useOverallDialogContext } from "../../contexts/DialogContext";
import ApiController from "../../controllers/ApiController";
import UrsorFadeIn from "../../components/UrsorFadeIn";
import useColumnWidth from "./useColumnWidth";
import NotificationContext from "../../contexts/NotificationContext";
import { useUserContext } from "../../contexts/UserContext";

const WIDTH = "85%";

export interface IStackViewDialogProps {
  open: boolean;
  closeCallback: () => void;
  newLinkCallback: () => void;
  editCallback: () => void;
  openLinkCallback: (id: string) => void;
  editLinkCallback: (id: string) => void;
  stackId: string;
}

export default function StackViewDialog(props: IStackViewDialogProps) {
  const dataCtx = useUserDataContext();
  const dialogCtx = useOverallDialogContext();
  const userCtx = useUserContext();
  const notificationCtx = useContext(NotificationContext);

  const [cardColumns, setCardColumns] = useState<
    {
      type: AstroContent;
      details: ILink | IStack;
    }[][]
  >([]);

  const [stack, setStack] = useState<IStack | undefined>(undefined);
  useEffect(
    () => setStack(dataCtx.stacks?.find((s) => s.id === props.stackId)),
    [props.stackId, dataCtx.stacks]
  );
  // const [selectedChannelId, setSelectedChannelId] = useState<
  //   string | undefined
  // >(undefined);
  // useEffect(() => {
  //   stack && setSelectedChannelId(stack.id);
  // }, [stack]);

  // const [selectedStackId, setSelectedStackId] = useState<string | undefined>(
  //   props.stackId
  // );

  const actions: IActionPopupItem[] = [
    // {
    //   text: "Enter class",
    //   icon: ArrowUpRightIcon,
    //   kallback: () => navigate("/classroom/" + props.classroom.id),
    // },
    {
      text: "Edit",
      icon: PencilIcon,
      kallback: () => props.editCallback?.(),
    },
    {
      text: "Duplicate",
      icon: ClippyIcon,
      kallback: () =>
        ApiController.duplicateStack(props.stackId)
          .then(dataCtx.refreshStacks)
          .then(dataCtx.refreshChannels)
          .then(dataCtx.refreshLinks)
          .then(() => notificationCtx.success("Stack duplicated")),
    },
    // {
    //   text: "Duplicate",
    //   icon: ClippyIcon,
    //   kallback: duplicate,
    // },
    // {
    //   text: "Export",
    //   icon: UploadIcon,
    //   kallback: props.exportCallback,
    // },
    // {
    //   text: props.classroom.isArchived ? "Unarchive" : "Archive",
    //   icon: ClockIcon,
    //   kallback: () => archive(props.classroom.isArchived),
    // },
    {
      text: "Delete",
      icon: TrashcanIcon,
      kallback: () =>
        dialogCtx.setDeletionDialogProps({
          category: "Stack",
          title: stack?.title ?? "",
          open: true,
          deletionCallback: () =>
            ApiController.deleteStack(stack?.id)
              .then(props.closeCallback)
              .then(dataCtx.refreshStacks)
              .then(dataCtx.refreshChannels)
              .then(() => notificationCtx.negativeSuccess("Stack deleted")),
          closeCallback: () => null,
        }),
      color: PALETTE.system.red,
    },
  ];

  const { nColumns, setColumnsContainerRef } = useColumnWidth();
  useEffect(() => {
    if (nColumns < 0) return;
    const linkDetails = (
      dataCtx.links?.filter((l) => l.stackId === props.stackId) || []
    ).map((l) => ({
      type: "link" as AstroContent,
      details: l,
    }));
    const details = _.reverse(
      _.sortBy(linkDetails, (c) => new Date(c.details.createdAt)).slice()
    );
    const chunked = _.chunk(details, nColumns);
    setCardColumns(
      [...Array(nColumns).keys()].map((i) =>
        _.compact(chunked.map((chunk) => chunk[i]))
      )
    );
  }, [dataCtx.links, dataCtx.stacks, nColumns]);

  return (
    <>
      <FixedBottomDialog
        open={props.open}
        closeCallback={props.closeCallback}
        width={WIDTH}
        backgroundColor={PALETTE.secondary.grey[1]}
      >
        <Stack
          flex={1}
          width="100%"
          borderRadius="16px 16px 0 0"
          bgcolor={PALETTE.secondary.grey[1]}
          p={GRID_SPACING}
        >
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            pb="60px"
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                svg: {
                  path: {
                    fill: PALETTE.secondary.grey[4],
                  },
                },
                cursor: "pointer",
                "&:hover": { opacity: 0.6 },
                transition: "0.2s",
              }}
              onClick={props.closeCallback}
            >
              <ChevronLeft width="20px" height="20px" />
              <Typography color={PALETTE.secondary.grey[4]}>Back</Typography>
            </Stack>
            <Stack direction="row" spacing="12px">
              <Stack
                width="42px"
                height="42px"
                //bgcolor="rgb(255,255,255)"
                justifyContent="center"
                alignItems="center"
                borderRadius="100%"
                sx={{
                  cursor: "pointer",
                  "&:hover": { opacity: 0.6 },
                  transition: "0.2s",
                }}
              >
                <UrsorActionButton
                  background="rgb(255,255,255)"
                  size="42px"
                  actions={actions}
                  large
                />
              </Stack>
              <UrsorButton
                mode="dark"
                variant="tertiary"
                endIcon={<PlusIcon width="24px" height="24px" />}
                onClick={props.newLinkCallback}
              >
                Add Link
              </UrsorButton>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing="12px"
            >
              <Stack flex={1}>
                <Typography>
                  {moment(stack?.createdAt).format("Do MMMM YYYY")}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    minWidth: "100%",
                    maxWidth: 0,
                  }}
                  noWrap
                >
                  {stack?.title}
                </Typography>
              </Stack>
              <Stack spacing="6px" pr="2px">
                <Stack spacing="8px" alignItems="center" direction="row">
                  <LinkIcon width="16px" height="16px" />
                  <Typography variant="medium">{`${stack?.nLinks} Link${
                    stack?.nLinks === 1 ? "" : "s"
                  }`}</Typography>
                </Stack>
                <Stack spacing="8px" alignItems="center" direction="row">
                  <PersonIcon width="16px" height="16px" />
                  <Typography variant="medium">{`By ${
                    dataCtx.teachers.find((t) => t.id === stack?.creatorId)
                      ?.teacherName
                  }`}</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              height={stack?.description ? "80px" : "30px"}
              justifyContent="center"
            >
              <Typography>{stack?.description}</Typography>
            </Stack>
          </Stack>
          {cardColumns.flat().length === 0 ? (
            <Stack flex={1} justifyContent="center" alignItems="center">
              <Stack>
                <UrsorFadeIn delay={300} duration={1000}>
                  <Stack spacing="0px" position="relative">
                    <Stack sx={{ opacity: 0.3, filter: "grayscale(1)" }}>
                      <img
                        height="207px"
                        width="217px"
                        src={GraphIllustration}
                      />
                    </Stack>
                    <Stack
                      position="absolute"
                      bottom="-8px"
                      width="100%"
                      alignItems="center"
                    >
                      <UrsorButton
                        onClick={props.newLinkCallback}
                        endIcon={<PlusIcon height="24px" width="24px" />}
                        mode="dark"
                        variant="tertiary"
                      >
                        Add Link
                      </UrsorButton>
                    </Stack>
                  </Stack>
                </UrsorFadeIn>
              </Stack>
            </Stack>
          ) : (
            <Stack
              ref={setColumnsContainerRef}
              direction="row"
              flex={1}
              spacing={GRID_SPACING}
            >
              {cardColumns.map((column, i) => (
                <Stack key={i} flex={1} spacing={GRID_SPACING}>
                  {column.map((item, j) => (
                    <Stack>
                      <UrsorFadeIn
                        key={item.details.id}
                        delay={j * 150 + i * 80}
                        duration={800}
                      >
                        <LinkCard
                          key={item.details.id}
                          link={item.details as ILink}
                          clickCallback={() => {
                            props.openLinkCallback(item.details.id);
                          }}
                          editCallback={() => {
                            props.editLinkCallback(item.details.id);
                          }}
                          duplicateCallback={() => {
                            ApiController.duplicateLink(
                              item.details.id,
                              userCtx.userDetails?.id
                            )
                              .then(dataCtx.refreshStacks)
                              .then(dataCtx.refreshChannels)
                              .then(dataCtx.refreshLinks);
                          }}
                        />
                      </UrsorFadeIn>
                    </Stack>
                  ))}
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      </FixedBottomDialog>
      {/* <LinkDialog
        channelId={selectedChannelId}
        stackId={selectedStackId}
        newChannelCallback={() =>
          dialogCtx.setChannelDialogProps({
            completionCallback: (id) => {
              setSelectedChannelId(id);
            },
            open: true,
            closeCallback: () => null,
          })
        }
        newStackCallback={() => setStackDialogOpen(true)}
        open={linkDialogOpen}
        closeCallback={() => setLinkDialogOpen(false)}
      /> */}
    </>
  );
}
