import React, { useState } from "react";
import { Box } from "@mui/material";
import Typography from "./Typography";
import { PALETTE } from "../palette";
import { alpha, Stack } from "@mui/system";

const TAB_SWITCH_INNER_Y_PADDING = "9px";
const TAB_SWITCH_INNER_X_PADDING = "33px";
const TAB_SWITCH_OUTER_PADDING = "4px";

export interface ITabDetails<T> {
  id: T;
  value: string;
}

export interface IUrsorTabSwitchProps<T> {
  tabs: ITabDetails<T>[];
  activeTab: string;
  buttonWidth?: string;
  callback: (activeTab: T) => void;
}

export default function UrsorTabSwitch<T extends string>(
  props: IUrsorTabSwitchProps<T> & { children?: React.ReactNode }
) {
  const [hovering, setHovering] = useState<T | null>(null);
  return (
    <Stack alignItems="center">
      <Stack
        width="fit-content"
        height="32px"
        direction="row"
        //p={TAB_SWITCH_OUTER_PADDING}
        bgcolor={PALETTE.secondary.grey[2]}
        borderRadius="25px"
        display="inline-flex"
      >
        {props.tabs.map((tab) => (
          <Stack
            key={tab.id}
            //py={TAB_SWITCH_INNER_Y_PADDING}
            alignItems="center"
            px={TAB_SWITCH_INNER_X_PADDING}
            borderRadius="25px"
            width={props.buttonWidth ?? "auto"}
            justifyContent="center"
            sx={{
              cursor: props.activeTab !== tab.id ? "pointer" : "unset",
              background:
                hovering === tab.id
                  ? alpha(
                      PALETTE.primary.indigo,
                      props.activeTab === tab.id ? 1 : 0.3
                    )
                  : props.activeTab === tab.id
                  ? PALETTE.primary.indigo
                  : undefined,
              transition: "0.3s",
            }}
            onClick={() => props.callback(tab.id)}
            onMouseEnter={() =>
              props.activeTab !== tab.id && setHovering(tab.id)
            }
            onMouseLeave={() => setHovering(null)}
          >
            <Typography
              variant="small"
              bold
              color={
                props.activeTab === tab.id
                  ? PALETTE.font.light
                  : PALETTE.font.dark
              }
              sx={{ pointerEvents: "none" }}
            >
              {[...tab.value[0].toUpperCase(), tab.value.slice(1)]}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
