import React, { useContext, useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { ReactComponent as DarkPrintIcon } from "../../images/icons/PrinterBlack_NOT_SVG.svg";
import { ReactComponent as PersonIcon } from "../../images/icons/PersonIcon.svg";
import { ReactComponent as MortarBoardIcon } from "../../images/icons/MortarBoardIcon.svg";
import { ReactComponent as SearchIcon } from "../../images/icons/SearchIcon.svg";
import { Box, Stack } from "@mui/system";
import NotificationContext from "../../contexts/NotificationContext";
import ApiController from "../../controllers/ApiController";
import { useUserContext } from "../../contexts/UserContext";
import AdminPageTeachersTab, { ITeacher } from "./AdminPageTeachersTab";
import Tag from "../../components/Tag";
import _ from "lodash";
import { useGoogleClassroomAPIContext } from "../../contexts/GoogleClassroomAPIContext";
import TeacherInvitationDialog from "./dialogs/TeacherInvitationDialog";
import InputTypography from "../BrowserPage/components/InputTypography";
import { PALETTE } from "../../palette";
import UrsorButton from "../../components/buttons/UrsorButton";
import { useLocation, useNavigate } from "react-router";
import { IStack, useUserDataContext } from "../../contexts/UserDataContext";
import { ILink } from "../BrowserPage/dialogs/LinkDialog";
import { IChannel } from "../LibraryPage/LibraryPage";
import { SearchInput } from "../BrowserPage/LinksPage";

export const getUsername = (name: string, allNames: string[]) => {
  const amount = allNames.filter(
    (n) =>
      (n ?? "").replaceAll(/\s/g, "").toLowerCase() ===
      name.replaceAll(/\s/g, "").toLowerCase()
  ).length;
  return `${name.replaceAll(/\s/g, "")}${
    amount === 0 ? "" : amount + 1
  }`.toLowerCase();
};

export interface ISchool {
  id: string;
  name: string;
  email?: string;
  emailDomain?: string;
  website?: string;
  address?: string;
  postcode?: string;
  country?: string;
  isDeleted: boolean;
  devices: IDevice[];
  lock?: ILock;
  joinCode: string;
  teacherCode: string;
  deviceLimit?: number;
  expirationDate?: string;
  free?: boolean;
}

export interface ILock {
  devices: string[];
  endTime: string;
}

export type ContentAgeMode = "trailblazer" | "explorer" | "adventurer";

export interface IDevice {
  id: string;
  name: string;
  lastOnline?: string;
  history: { studentId: string; startTime: string; endTime?: string }[];
  connected?: "approved" | "denied";
  reviewerId?: string;
  type: "chrome" | "ipad";
  contentAgeMode: ContentAgeMode;
}

export interface ISession {
  id: string;
  name: string;
  students: string[];
  joinCode: string;
}

type SchoolTab = "teachers";

export interface IAdminPageProps {}

export default function AdminPage(props: IAdminPageProps) {
  const notificationCtx = useContext(NotificationContext);
  const userDetails = useUserContext().userDetails;
  const dataCtx = useUserDataContext();

  const [teachers, setTeachers] = useState<ITeacher[]>([]);
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [stacks, setStacks] = useState<IStack[]>([]);
  useEffect(() => setChannels(dataCtx.channels || []), [dataCtx.channels]);
  useEffect(() => setStacks(dataCtx.stacks || []), [dataCtx.stacks]);
  const [links, setLinks] = useState<ILink[]>([]);

  const [selectedTab, setSelectedTab] = useState<SchoolTab>("teachers");

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [printAllDialogOpen, setPrintAllDialogOpen] = useState<boolean>(false);

  const loadTeachers = () =>
    ApiController.getTeachersInSchool(userDetails!.schoolId)
      .then((students) => setTeachers(students))
      .catch((error) => notificationCtx.error(error.message));

  const loadLinks = () =>
    ApiController.getLinksInSchool(userDetails!.schoolId)
      .then((links) => setLinks(links))
      .catch((error) => notificationCtx.error(error.message));

  useEffect(() => {
    if (userDetails?.id) {
      // loadStudents();
      loadTeachers();
      // loadClasses();
      // loadLessons();
      loadLinks();
    }
  }, [userDetails?.id]);

  const navigate = useNavigate();

  const navigationHash = useLocation()?.hash;
  useEffect(() => {
    if (navigationHash === "#teachers") {
      setSelectedTab("teachers");
    } else if (navigationHash === "") {
      setSelectedTab("teachers");
    }
  }, [navigationHash]);

  const [school, setSchool] = useState<ISchool | undefined>(undefined);
  useEffect(() => {
    if (userDetails) {
      ApiController.getSchool(userDetails.schoolId)
        .then((school) => {
          setSchool(school);
        })
        .catch((error) => notificationCtx.error(error.message));
    }
  }, [userDetails]);

  const TABS: Record<
    SchoolTab,
    {
      component: JSX.Element;
      titleDisplayName: string;
      counter: string;
      mainButtonText: string;
      mainButtonCallback?: () => void;
      icon: React.FC<React.SVGProps<SVGSVGElement>>;
      numberGetter?: () => number;
      printAllButton?: boolean;
      //sortTypes?: Sort[];
    }
  > = {
    teachers: {
      component: (
        <AdminPageTeachersTab
          teachers={teachers}
          channels={channels}
          stacks={stacks}
          links={links}
          submitCallback={loadTeachers}
          searchValue={searchValue}
        />
      ),
      titleDisplayName: "Teachers",
      counter: "Teacher",
      mainButtonText: "Invite Teacher",
      mainButtonCallback: () => setTeacherInvitationDialogOpen(true),
      icon: MortarBoardIcon,
      numberGetter: () => teachers.length,
    },
  };
  const TAB_ORDER: SchoolTab[] = ["teachers"];

  const [teacherInvitationDialogOpen, setTeacherInvitationDialogOpen] =
    useState<boolean>(false);

  return (
    <>
      <PageLayout
        scrollable
        title={"People"}
        description="These are all people who can add Links to the private Browser and who can monitor Devices."
        bodyWidth="100%"
        selectedSidebarItemId="people"
        button={{
          text: "Invite Teacher",
          callback: () => setTeacherInvitationDialogOpen(true),
          icon: PersonIcon,
        }}
      >
        <Stack spacing="12px">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing="12px">
              {TAB_ORDER.filter((tab) => userDetails?.isAdmin).map((tab) => (
                <Box
                  key={tab}
                  sx={{
                    "&:hover": { opacity: selectedTab === tab ? 1 : 0.6 },
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("#" + tab)}
                >
                  <Tag
                    selected={selectedTab === tab}
                    text={`${TABS[tab].counter}${
                      !TABS[tab]?.numberGetter ||
                      TABS[tab]?.numberGetter?.() === 1
                        ? ""
                        : "s"
                    }`}
                    icon={TABS[tab].icon}
                    n={TABS[tab]?.numberGetter?.()}
                  />
                </Box>
              ))}
            </Stack>
            {/* )} */}
            <Stack
              direction="row"
              spacing="30px"
              alignItems="center"
              width="fit-content"
            >
              <SearchInput
                value={searchValue ?? ""}
                callback={(value: string) => {
                  setSearchValue(value);
                }}
                clearCallback={() => setSearchValue(undefined)}
              />
              {/* <Stack
                direction="row"
                alignItems="center"
                spacing="12px"
                sx={{
                  svg: {
                    path: {
                      fill: PALETTE.secondary.grey[3],
                    },
                  },
                }}
              >
                <InputTypography //@ts-ignore
                  value={searchValue}
                  placeholder={"Search"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchValue(event.target.value)
                  }
                  dynamicWidth
                />
                <Box width="16px" height="16px">
                  <SearchIcon width="16px" height="16px" />
                </Box>
              </Stack> */}
              {TABS[selectedTab].printAllButton ? (
                <UrsorButton
                  size="small"
                  variant="secondary"
                  onClick={() => setPrintAllDialogOpen(true)}
                  endIcon={<DarkPrintIcon width="16px" height="16px" />}
                >
                  Print all
                </UrsorButton>
              ) : null}
            </Stack>
          </Stack>
          <Stack>{TABS[selectedTab].component}</Stack>
        </Stack>
      </PageLayout>
      {school ? (
        <TeacherInvitationDialog
          open={teacherInvitationDialogOpen}
          closeCallback={() => setTeacherInvitationDialogOpen(false)}
          callback={loadTeachers}
          school={school}
        />
      ) : null}
    </>
  );
}
