import React, { useEffect, useState } from "react";
import UrsorDialog from "../../../components/UrsorDialog";
import { useUserContext } from "../../../contexts/UserContext";
import ApiController from "../../../controllers/ApiController";
import Typography from "../../../components/Typography";
import Illustration from "../../../images/BlocksIllustration.png";
import { Box } from "@mui/system";

export interface IInvitationForkDialogProps {
  open: boolean;
  acceptCallback: () => void;
  rejectCallback: () => void;
  //backCallback: () => void;
  schoolName?: string;
}

export default function InvitationForkDialog(
  props: IInvitationForkDialogProps
) {
  return (
    <UrsorDialog
      title="Accept invitation"
      subtitle={[
        "You've been invited to join",
        <Typography bold>{props.schoolName ?? ""}</Typography>,
      ]}
      supertitle="Accept invitation"
      open={props.open}
      button={{
        text: "Accept invitation",
        callback: props.acceptCallback,
      }}
      secondaryButton={{
        text: "Reject invitation",
        callback: props.rejectCallback,
      }}
      noBackdrop
      noCloseButton
    >
      <Box
        component="img"
        height="80%"
        minHeight={0}
        maxHeight="100%"
        width="fit-content"
        src={Illustration}
      />
    </UrsorDialog>
  );
}
