import _ from "lodash";
import React, { useContext, createContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

export interface IHistoryContext {
  push: (url: string) => void;
  back: () => void;
}

const HistoryContext = createContext<IHistoryContext>({
  push: () => null,
  back: () => null,
});

const useHistoryContext = () => {
  const context = useContext(HistoryContext);
  if (context === undefined) {
    throw new Error(
      "useHistoryContext must be used within a HistoryContextProvider"
    );
  }
  return context;
};

export interface IHistoryProviderProps {
  children: React.ReactNode;
}

const HistoryProvider = (props: IHistoryProviderProps) => {
  const [history, setHistory] = useState<string[]>([]);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const newUrl = `${location.pathname}${location.hash ?? ""}`;
    history[history.length - 1] !== newUrl && setHistory([...history, newUrl]);
  }, [location]);

  return (
    <HistoryContext.Provider
      value={{
        push: (url) => {
          //history[history.length - 1] !== url && setHistory([...history, url]);
        },
        back: () => {
          setHistory(history.slice(0, -1));
          navigate(history.length > 1 ? history[history.length - 2] : "/");
        },
      }}
    >
      {props.children}
    </HistoryContext.Provider>
  );
};

export { HistoryProvider, useHistoryContext };
