import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { PALETTE } from "../palette";
import Sidebar, { SideBarItemId, WIDTH } from "./Sidebar";
import DarkMode from "./DarkMode";
import LightMode from "./LightMode";
import Typography from "./Typography";
import UrsorButton from "./buttons/UrsorButton";
import { ReactComponent as ChevronIcon } from "../images/icons/ChevronLeft.svg";
import { useWindowSize } from "usehooks-ts";
import ApiController from "../controllers/ApiController";
import { useUserContext } from "../contexts/UserContext";
import UrsorFadeIn from "./UrsorFadeIn";
import NotificationContext from "../contexts/NotificationContext";
import { HIDE_SCROLLBAR } from "./DynamicCardGrid";
// import mixpanel from "mixpanel-browser";

const PADDING_TOP = "51px";
export const SIDEBAR_X_MARGIN = 48;
export const SIDEBAR_Y_MARGIN = "31px";
const SHOW_JOINCLASSCODE_WINDOW_WIDTH_THRESHOLD = 1130;
const GRADIENT = "linear-gradient(178deg, #F279C5, #FD9B41)";

const PendingStateBar = (props: { schoolName: string }) => (
  <Stack
    width="100%"
    height="47px"
    mb="17px"
    justifyContent="center"
    alignItems="center"
    borderRadius="12px"
    sx={{ background: GRADIENT }}
  >
    <Typography bold color={PALETTE.font.light}>
      {`Waiting for a colleague in ${props.schoolName} to approve you as a Teacher.`}
    </Typography>
  </Stack>
);

const InvitationBar = (props: {
  schoolName: string;
  inviterName: string;
  acceptCallback: () => void;
  rejectCallback: () => void;
}) => (
  <Stack
    width="100%"
    height="47px"
    mb="17px"
    justifyContent="center"
    alignItems="center"
    borderRadius="12px"
    sx={{ background: GRADIENT }}
    direction="row"
    spacing="20px"
  >
    <Stack direction="row" spacing="5px">
      <Typography bold color={PALETTE.font.light}>
        {props.inviterName}
      </Typography>
      <Typography color={PALETTE.font.light}>
        has invited you to join
      </Typography>
      <Typography bold color={PALETTE.font.light}>
        {props.schoolName}
      </Typography>
    </Stack>
    <Stack direction="row" spacing="6px">
      <UrsorButton
        mode="dark"
        size="small"
        fontColor="#F98E76"
        backgroundColor="rgb(255,255,255)"
        onClick={props.acceptCallback}
      >
        Accept
      </UrsorButton>
      <UrsorButton
        mode="dark"
        variant="secondary"
        size="small"
        onClick={props.rejectCallback}
      >
        Reject
      </UrsorButton>
    </Stack>
  </Stack>
);

export interface IPageLayoutProps {
  dark?: boolean;
  title: string;
  description?: string;
  dotColor?: string;
  titleBackButton?: boolean;
  titleBackButtonCallback?: () => void;
  particles?: boolean;
  bodyWidth?: string;
  noSidebar?: boolean;
  scrollable?: boolean;
  button?: {
    text: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    callback: () => void;
    disabled?: boolean;
    tourId?: string;
  };
  secondaryButton?: {
    text: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    callback: () => void;
    svgStrokeInsteadOfFill?: boolean;
    disabled?: boolean;
    tourId?: string;
  };
  selectedSidebarItemId: SideBarItemId;
  titleRowRighthandElement?: JSX.Element;
  titleRowLefthandElement?: JSX.Element;
  disableSearchBarCollapsing?: boolean;
  classroomId?: string;
  disableConnectionBar?: boolean;
  children: React.ReactNode;
}

export default function PageLayout(props: IPageLayoutProps) {
  const ColorMode = props.dark ? DarkMode : LightMode;
  const notificationCtx = React.useContext(NotificationContext);
  const userCtx = useUserContext();
  const [feedPopupOpen, setFeedPopupOpen] = useState<boolean>(false);

  const [collapseSearchBar, setCollapseSearchBar] = useState<boolean>(false);

  const { width } = useWindowSize();

  // useEffect(() => {
  //   userCtx.userDetails?.id && mixpanel.track("Page View");
  // }, []);

  useEffect(() => {
    !props.disableSearchBarCollapsing &&
      setCollapseSearchBar(width < SHOW_JOINCLASSCODE_WINDOW_WIDTH_THRESHOLD);
  }, [width]);

  const [unseenFeedItems, setUnseenFeedItems] = useState<boolean>(false);
  useEffect(() => {
    setUnseenFeedItems(!!userCtx.userDetails?.unseenFeedItems);
  }, [userCtx.userDetails?.unseenFeedItems]);
  useEffect(() => {
    if (feedPopupOpen) {
      setUnseenFeedItems(false);
      ApiController.updateTeacher(userCtx.userDetails?.id, {
        unseenFeedItems: false,
      }).then(() => userCtx.load());
    }
  }, [feedPopupOpen]);

  const [pendingSchoolName, setPendingSchoolName] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    userCtx.userDetails?.requestedSchoolId &&
      ApiController.getSchool(userCtx.userDetails?.requestedSchoolId).then(
        (school) => setPendingSchoolName(school.name)
      );
  }, [userCtx.userDetails?.requestedSchoolId]);

  const [invitedSchoolName, setInvitedSchoolName] = useState<
    string | undefined
  >(undefined);
  const [inviterName, setInviterName] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (userCtx.userDetails?.invitationPendingByInviterId) {
      ApiController.getTeacher(
        userCtx.userDetails?.invitationPendingByInviterId
      ).then((teacher) => {
        setInviterName(teacher.teacherName);
        ApiController.getSchool(teacher.schoolId).then((school) =>
          setInvitedSchoolName(school.name)
        );
      });
    } else {
      setInvitedSchoolName(undefined);
      setInviterName(undefined);
    }
  }, [
    userCtx.userDetails?.requestedSchoolId,
    userCtx.userDetails?.invitationPendingByInviterId,
  ]);

  const submitReplyToInvitation = (accept: boolean) =>
    ApiController.replyToInvitation(userCtx.userDetails?.id, accept).then(
      () => {
        userCtx.load(userCtx.userDetails?.email);
      }
    );

  const [showingBar, setShowingBar] = useState<boolean>(false);
  useEffect(
    () =>
      setShowingBar(
        !props.disableConnectionBar &&
          (!!pendingSchoolName || (!!invitedSchoolName && !!inviterName))
      ),
    [
      pendingSchoolName,
      invitedSchoolName,
      inviterName,
      props.disableConnectionBar,
    ]
  );

  return (
    <ColorMode>
      <Stack
        direction="row"
        height="100vh"
        width="100vw"
        overflow="hidden"
        sx={{
          background: props.dark
            ? props.particles
              ? null
              : PALETTE.primary.navy
            : PALETTE.secondary.grey[1],
        }}
      >
        {!props.noSidebar ? (
          <Stack
            minWidth={`calc(${WIDTH} + 2*${SIDEBAR_X_MARGIN}px)`}
            height="100%"
            alignItems="center"
            py={SIDEBAR_Y_MARGIN}
            mr={"5px"}
            justifyContent="center"
          >
            <Box flex={1}>
              <Sidebar
                selectedItemId={props.selectedSidebarItemId}
                classroomId={props.classroomId}
              />
            </Box>
          </Stack>
        ) : null}
        <Stack
          sx={{
            height: "100%",
            width: "100%",
          }}
          overflow={props.scrollable ? "scroll" : "hidden"}
          spacing="20px"
          pr={`${SIDEBAR_X_MARGIN}px`}
          pt={showingBar ? "31px" : PADDING_TOP}
        >
          <Stack spacing="30px" justifyContent="space-between">
            {!props.disableConnectionBar && pendingSchoolName ? (
              <UrsorFadeIn duration={600}>
                <PendingStateBar schoolName={pendingSchoolName} />
              </UrsorFadeIn>
            ) : null}
            {!props.disableConnectionBar && invitedSchoolName && inviterName ? (
              <UrsorFadeIn duration={600}>
                <InvitationBar
                  schoolName={invitedSchoolName}
                  inviterName={inviterName}
                  acceptCallback={() =>
                    submitReplyToInvitation(true).then(() =>
                      notificationCtx.success(`Joined ${invitedSchoolName}`)
                    )
                  }
                  rejectCallback={() => submitReplyToInvitation(false)}
                />
              </UrsorFadeIn>
            ) : null}
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              spacing="18px"
            >
              <Stack
                direction="row"
                spacing="30px"
                alignItems="flex-end"
                //flex={1}
                width="100%"
              >
                <Stack flex={1} direction="row" justifyContent="space-between">
                  <Stack direction="row" spacing="15px" alignItems="center">
                    {props.titleBackButtonCallback ? (
                      <Stack width="25px">
                        <Stack
                          sx={{
                            cursor: "pointer",
                            "&:hover": { opacity: 0.6 },
                            transition: "0.2s",
                          }}
                          onClick={props.titleBackButtonCallback}
                          justifyContent="center"
                        >
                          <ChevronIcon height="32px" width="32px" />
                        </Stack>
                      </Stack>
                    ) : null}
                    <Stack
                      direction="row"
                      spacing="12px"
                      alignItems="center"
                      overflow="hidden"
                    >
                      {props.dotColor ? (
                        <Box
                          height="23px"
                          width="23px"
                          minWidth="23px"
                          bgcolor={props.dotColor}
                          borderRadius="100%"
                        />
                      ) : null}
                      <UrsorFadeIn delay={200} duration={600}>
                        <Stack
                          direction="row"
                          spacing="30px"
                          alignItems="flex-end"
                          width="100%"
                          overflow="hidden"
                        >
                          <Stack overflow="hidden" spacing="5px">
                            <Typography
                              variant="h3"
                              color={
                                props.dark
                                  ? PALETTE.font.light
                                  : PALETTE.font.dark
                              }
                              noWrap
                            >
                              {props.title}
                            </Typography>
                            {props.description ? (
                              <Typography
                                variant="small"
                                color={PALETTE.secondary.grey[4]}
                              >
                                {props.description}
                              </Typography>
                            ) : null}
                          </Stack>

                          <Stack
                            style={{
                              paddingBottom: "3px",
                              // overflow: "hidden",
                            }}
                            //overflow="hidden"
                            position="relative"
                            overflow="visible"
                          >
                            {props.titleRowLefthandElement}
                          </Stack>
                        </Stack>
                      </UrsorFadeIn>
                    </Stack>
                  </Stack>
                  {props.button ||
                  props.secondaryButton ||
                  props.titleRowRighthandElement ? (
                    <UrsorFadeIn delay={500} duration={800}>
                      <Stack direction="row" spacing="16px" position="relative">
                        {props.titleRowRighthandElement}
                        {props.secondaryButton ? (
                          <Box id={props.button?.tourId}>
                            <UrsorButton
                              onClick={props.secondaryButton.callback}
                              endIcon={
                                <props.secondaryButton.icon
                                  height="24px"
                                  width="24px"
                                />
                              }
                              variant="secondary"
                              disabled={props.button?.disabled}
                              svgStrokeInsteadOfFill={
                                props.secondaryButton.svgStrokeInsteadOfFill
                              }
                            >
                              {props.secondaryButton.text}
                            </UrsorButton>
                          </Box>
                        ) : null}
                        {props.button ? (
                          <Box id={props.button?.tourId}>
                            <UrsorButton
                              onClick={props.button.callback}
                              endIcon={
                                <props.button.icon height="24px" width="24px" />
                              }
                              variant="tertiary"
                              disabled={props.button?.disabled}
                            >
                              {props.button.text}
                            </UrsorButton>
                          </Box>
                        ) : null}
                      </Stack>
                    </UrsorFadeIn>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <UrsorFadeIn delay={200} duration={600}>
            <Stack
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                //overflowY: props.scrollableContent ? "scroll" : "visible",
                ...HIDE_SCROLLBAR,
              }}
            >
              <Stack width={props.bodyWidth ?? "100%"} height="100%">
                {props.children}
              </Stack>
            </Stack>
          </UrsorFadeIn>
        </Stack>
      </Stack>
    </ColorMode>
  );
}
