import * as React from "react";
const WIDTH = "292px";
const HEIGHT = "168px";
import { Box, Stack, keyframes } from "@mui/system";
import Joyride, { TooltipRenderProps, CallBackProps } from "react-joyride";
import Typography from "./Typography";
import { PALETTE } from "../palette";
import UrsorButton from "./buttons/UrsorButton";
import { IDialogButtonDetails, fadeIn } from "./UrsorDialog";
import { useOverallDialogContext } from "../contexts/DialogContext";
import AddDeviceDialog from "../pages/DevicesPage/AddDeviceDialog";
import { useUserContext } from "../contexts/UserContext";
import ApiController from "../controllers/ApiController";

export const pulse = keyframes`
  from {
    transform: translateY(-3px)
  }
  to {
    transform: translateY(3px)
  }
`;

const TourPopupContent = (
  props: TooltipRenderProps & {
    totalStepsN: number;
    primaryButton: IDialogButtonDetails;
  } //(props: { title: string; description: string }) => (
) => {
  return (
    // <Stack sx={{ animation: `${fadeIn} 0.7s ease-out` }}>
    <Stack
      width={WIDTH}
      bgcolor="rgb(255,255,255)"
      px="20px"
      py="10px"
      spacing="16px"
      borderRadius="8px"
      sx={{
        filter: "none",
        animation: `${pulse} 2s ease-in-out`,

        //   animation: `${pulse} 0.7s ease-out`,
        animationDirection: "alternate",
        animationIterationCount: "infinite",
      }}
      ml="5px"
    >
      <Stack spacing="3px">
        <Stack direction="row" spacing="3px">
          <Typography variant="tiny" bold color={PALETTE.secondary.grey[4]}>
            {props.index + 1}
          </Typography>
          <Typography variant="tiny" color={PALETTE.secondary.grey[4]}>
            of
          </Typography>
          <Typography variant="tiny" color={PALETTE.secondary.grey[4]}>
            {props.totalStepsN}
          </Typography>
        </Stack>
        <Typography variant="medium" bold>
          {props.step.title}
        </Typography>
        <Typography variant="small" color={PALETTE.secondary.grey[4]}>
          {props.step.content}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        {/* <UrsorButton size="small" onClick={props.primaryProps.onClick}> */}
        <UrsorButton
          size="small" //@ts-ignore
          onClick={(event: any) => {
            props.primaryButton?.callback?.();
            props.primaryProps?.onClick(event);
          }}
        >
          {props.primaryButton?.text || "Next"}
        </UrsorButton>
        <UrsorButton
          size="small"
          onClick={props.skipProps.onClick}
          variant="secondary"
        >
          {props.index + 1 === props.totalStepsN ? "End" : "Skip all"}
        </UrsorButton>
      </Stack>
    </Stack>
    // </Stack>
  );
};

export const IntroTour = () => {
  const STEPS = [
    {
      disableBeacon: true,
      target: "#devices-button",
      title: "Monitor & Manage Devices",
      content:
        "This is where you can monitor internet usage of all your Student Devices in real time.",
      placement: "right",
    },
    {
      disableBeacon: true,
      target: "#library-button",
      title: "Manage your content",
      content:
        "This is where you can create Channels, Stacks and Links for your Students.",
      placement: "right",
    },
    {
      disableBeacon: true,
      target: "#filter-button",
      title: "Manage your Filter",
      content:
        "This is where you can see and manage all the Links which your Students can access.",
      placement: "right",
      //   route: "/links",
    },
    {
      disableBeacon: true,
      target: "#apps-button",
      title: "Set up Apps",
      content:
        "A list of handy Apps you want to always be there for your Students inside their homepage.",
      placement: "right",
    },
    {
      disableBeacon: true,
      target: "#plugins-button",
      title: "Useful integrations",
      content:
        "Connect your favourite classroom management software with the AstroSafe Browser.",
      placement: "right",
      //   route: "/addons",
    },
    {
      disableBeacon: true,
      target: "#people-button",
      title: "Invite Teachers",
      content:
        "This is where you can invite new Teachers to join AstroSafe and manage their permission levels.",
      placement: "right",
      //   route: "/addons",
    },
    {
      disableBeacon: true,
      target: "#add-device-button",
      title: "Now connect a Device",
      content:
        "This is where you go every time you want to connect a new Device to your School for monitoring.",
      placement: "bottom",
      //   route: "/addons",
      primaryButton: {
        text: "Connect",
        callback: () => setAddDeviceDialogOpen(true),
        //   dialogCtx.setAddDeviceDialogProps({
        //     open: true,
        //     closeCallback: () => null,
        //   }),
      },
    },
  ];

  const userCtx = useUserContext();
  const [showIntroTour, setShowIntroTour] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (userCtx.userDetails && !userCtx.userDetails?.viewedTutorial) {
      setTimeout(() => setShowIntroTour(true), 1000);
      ApiController.updateTeacher(userCtx.userDetails?.id, {
        viewedTutorial: true,
      }).then(() => userCtx.load(userCtx.userDetails?.email));
    }
  }, [userCtx.userDetails?.viewedTutorial]);
  //   React.useEffect(() => {
  //     setTimeout(() => setShowIntroTour(true), 1000);
  //   }, []);
  const [addDeviceDialogOpen, setAddDeviceDialogOpen] =
    React.useState<boolean>(false);
  return (
    <>
      <AddDeviceDialog
        open={addDeviceDialogOpen}
        closeCallback={() => {
          setAddDeviceDialogOpen(false);
        }}
      />
      <Joyride
        run={showIntroTour}
        continuous
        steps={STEPS}
        styles={
          {
            //   overlay: {
            //     height: "100vh",
            //     animation: `${fadeIn} 7s ease-out`,
            //   },
            // floater: {
            //   filter: "none",
            // },
          }
        }
        tooltipComponent={(tooltipProps: TooltipRenderProps) => (
          <TourPopupContent
            {...tooltipProps}
            totalStepsN={STEPS.length}
            primaryButton={STEPS[tooltipProps.index].primaryButton}
          />
        )}
        floaterProps={{
          hideArrow: true,
          disableAnimation: true,
          arrow: {
            // display: "none",
            //animation: `${fadeIn} 0.8s ease-out`,
          },
          styles: {
            filter: "none !important",
            arrow: {
              //display: "none",
              animation: `${fadeIn} 0.8s ease-out`,
            },
          },
        }}
      />
    </>
  );
};

export default IntroTour;
