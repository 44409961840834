import shadows, { Shadows } from "@mui/material/styles/shadows";

export const customThemeConfig = {
  shadows: shadows.map(() => "none") as Shadows,
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
    pxToRem: (size: number) => `${size / 16}rem`,
    button: {
      textTransform: undefined,
    },
    h1: {
      color: "#0D2839",
      fontWeight: 500,
      fontSize: "56px",
    },
    h2: {
      color: "#0D2839",
      fontWeight: 500,
      fontSize: "48px",
    },
    h3: {
      color: "#0D2839",
      fontWeight: 500,
      fontSize: "40px",
    },
    h4: {
      color: "#0D2839",
      fontWeight: 500,
      fontSize: "32px",
    },
    h5: {
      color: "#0D2839",
      fontWeight: 500,
      fontSize: "24px",
    },
    body1: {
      color: "#0D2839",
      fontWeight: 400,
      fontSize: "24px",
    },
    body2: {
      color: "#0D2839",
      fontWeight: 400,
      fontSize: "18px",
    },
    subtitle1: {
      color: "#0D2839",
      fontWeight: 500,
      fontSize: "24px",
    },
    subtitle2: {
      color: "#0D2839",
      fontWeight: 500,
      fontSize: "18px",
    },
    caption: {
      color: "#0D2839",
      fontStyle: "italic",
    },
    overline: {
      color: "#0D2839",
    },
  },
  palette: {
    mode: "light",
    background: { main: "#F7F7F7", paper: "white" },
    text: {
      primary: "#0AE799",
    },
    primary: {
      // purple
      main: "#0AE799",
    },
    secondary: {
      // purple
      main: "#7B61FF",
      textContrast: "#FFFFFF",
    },
    purple: {
      // purple
      main: "#7B61FF",
      textContrast: "#FFFFFF",
    },
    purple2: {
      // dark purple
      main: "#2E2657",
      textContrast: "#FFFFFF",
    },
    active: {
      // green
      main: "#0AE799",
    },
    inactive: {
      // red
      main: "#FC5C5C",
    },
    idle: {
      // gray
      main: "#A9A9A9",
    },
    neutral: {
      main: "#F5F5F7",
    },
    neutral2: {
      main: "#F0F0F0",
    },
    gray: {
      main: "#A9A9A9",
    },
    gray2: {
      main: "#9BAAB44D",
    },
    gray3: {
      main: "#0D2839",
    },
    gray4: {
      main: "#787878",
    },
    white: {
      main: "#FFFFFF",
      textContrast: "#0D2839",
    },
    darkBlueText: {
      main: "#0D2839",
    },
    navy: {
      main: "#0D2839",
    },
    action: {
      hover: "#0D2839",
      active: "#0D2839",
      hoverOpacity: 0.7,
    },
    error: {
      main: "#7B61FF",
    },
    divider: "#7B61FF",
  },
  neutral: {
    main: "#F7F7F7",
  },
};

export default customThemeConfig;
