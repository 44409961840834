import _ from "lodash";
import React, { useContext, createContext, useState } from "react";
import ApiController from "../controllers/ApiController";
import { useUserContext } from "./UserContext";

export interface ISidebarNotificationState {
  pendingPeopleCount: number;
  refreshPendingPeopleCount: () => void;
  pendingDevicesCount: number;
  refreshPendingDevicesCount: () => void;
  approvalRequestsCount: number;
  refreshApprovalRequestsCount: () => void;
}

const SidebarNotificationContext = createContext<ISidebarNotificationState>({
  pendingPeopleCount: 0,
  refreshPendingPeopleCount: () => null,
  pendingDevicesCount: 0,
  refreshPendingDevicesCount: () => null,
  approvalRequestsCount: 0,
  refreshApprovalRequestsCount: () => null,
});

const useSidebarNotificationContext = () => {
  const context = useContext(SidebarNotificationContext);
  if (context === undefined) {
    throw new Error(
      "useSidebarNotificationContext must be used within a SidebarNotificationContextProvider"
    );
  }
  return context;
};

export interface ISidebarNotificationProviderProps {
  children: React.ReactNode;
}

const SidebarNotificationProvider = (
  props: ISidebarNotificationProviderProps
) => {
  const userCtx = useUserContext();
  const [pendingPeopleCount, setPendingPeopleCount] = useState<number>(0);
  const loadPendingPeople = () =>
    ApiController.getTeacherApprovalRequests(
      userCtx.userDetails?.schoolId
    ).then((teachers) => setPendingPeopleCount(teachers.length));
  React.useEffect(() => {
    userCtx.userDetails?.schoolId && loadPendingPeople();
  }, [userCtx.userDetails?.schoolId]);

  const [pendingDevicesCount, setPendingDevicesCount] = useState<number>(0);
  const loadPendingDevices = () =>
    ApiController.getPendingDevices(userCtx.userDetails?.schoolId).then(
      (devices) => setPendingDevicesCount(devices.length)
    );
  React.useEffect(() => {
    userCtx.userDetails?.schoolId && loadPendingDevices();
  }, [userCtx.userDetails?.schoolId]);

  const [approvalRequestsCount, setApprovalRequestsCount] =
    React.useState<number>(0);
  const loadApprovalRequests = () =>
    ApiController.getApprovalRequestsInSchool(
      userCtx.userDetails?.schoolId
    ).then((ar) => setApprovalRequestsCount(ar.length));
  React.useEffect(() => {
    userCtx.userDetails?.schoolId && loadApprovalRequests();
  }, [userCtx.userDetails?.schoolId]);

  return (
    <SidebarNotificationContext.Provider
      value={{
        pendingPeopleCount,
        refreshPendingPeopleCount: loadPendingPeople,
        pendingDevicesCount,
        refreshPendingDevicesCount: loadPendingDevices,
        approvalRequestsCount,
        refreshApprovalRequestsCount: loadApprovalRequests,
      }}
    >
      {props.children}
    </SidebarNotificationContext.Provider>
  );
};

export { SidebarNotificationProvider, useSidebarNotificationContext };
