import React, { useContext, useState } from "react";
import { Dialog } from "@mui/material";
import {
  BACKDROP_STYLE,
  BORDER_RADIUS,
  DEFAULT_FADEIN_DURATION,
} from "../../components/UrsorDialog";
import Typography from "../../components/Typography";
import { PALETTE } from "../../palette";
import { Stack, alpha } from "@mui/system";
import UrsorButton from "../../components/buttons/UrsorButton";
import { ReactComponent as VersionsIcon } from "../../images/icons/VersionsIcon.svg";
import { useUserDataContext } from "../../contexts/UserDataContext";
import ApiController from "../../controllers/ApiController";
import NotificationContext from "../../contexts/NotificationContext";

const WIDTH = "550px";

export interface IMovingDialogProps {
  open: boolean;
  closeCallback: () => void;
  title: string;
  category: "link" | "stack";
  id: string;
}

export default function MovingDialog(props: IMovingDialogProps) {
  const notificationCtx = useContext(NotificationContext);
  const [hoveringRowId, setHoveringRowId] = useState<string | undefined>(
    undefined
  );
  const dataCtx = useUserDataContext();
  return (
    <Dialog
      transitionDuration={DEFAULT_FADEIN_DURATION}
      open={props.open}
      onClose={props.closeCallback}
      PaperProps={{
        style: {
          //zIndex: zIndices.POPUP,
          width: WIDTH,
          maxWidth: WIDTH,
          borderRadius: BORDER_RADIUS,
        },
      }}
      sx={{
        py: "10px",
        ".MuiBackdrop-root": BACKDROP_STYLE,
      }}
    >
      <Stack
        spacing="24px"
        p="40px"
        flex={1}
        justifyContent="space-between"
        overflow="hidden"
      >
        <Stack spacing="12px" alignItems="center">
          <Typography
            variant="h4"
            color={PALETTE.secondary.purple[2]}
          >{`Move ${props.title}`}</Typography>
        </Stack>
        <Stack flex={1} overflow="scroll">
          {dataCtx.channels?.map((c) => (
            <Stack
              minHeight="50px"
              direction="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              onMouseEnter={() => setHoveringRowId(c.id)}
              onMouseLeave={() => setHoveringRowId(undefined)}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing="10px"
                sx={{
                  cursor: "default",
                  svg: {
                    path: {
                      fill:
                        hoveringRowId === c.id
                          ? PALETTE.secondary.purple[2]
                          : PALETTE.font.dark,
                    },
                  },
                }}
              >
                <VersionsIcon width="18px" height="18px" />
                <Typography
                  bold
                  color={
                    hoveringRowId === c.id
                      ? PALETTE.secondary.purple[2]
                      : PALETTE.secondary.grey[4]
                  }
                >
                  {c.title}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  opacity: hoveringRowId === c.id ? 1 : 0,
                  transition: "0.2s",
                }}
              >
                <UrsorButton
                  onClick={() =>
                    (props.category === "link"
                      ? ApiController.updateLink
                      : ApiController.updateStack)(props.id, {
                      channelId: c.id,
                    })
                      .then(dataCtx.refreshLinks)
                      .then(dataCtx.refreshStacks)
                      .then(dataCtx.refreshChannels)
                      .then(props.closeCallback)
                      .then(() =>
                        notificationCtx.success(`Moved ${props.category}`)
                      )
                  }
                  size="small"
                  mode="dark"
                  variant="tertiary"
                >
                  Move
                </UrsorButton>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <UrsorButton onClick={props.closeCallback} variant="secondary">
          Go back
        </UrsorButton>
      </Stack>
    </Dialog>
  );
}
