import React from "react";
import { TextField } from "@mui/material";
import { PALETTE } from "../../palette";
import { DEFAULT_WIDTH } from "./UrsorInputField";
import { BOLD_FONT_WEIGHT, FONT_SIZES, LINE_HEIGHTS } from "../Typography";

const N_ROWS = 2;

export default function UrsorTextField(props) {
  const { onChange, value, placeholder, width, height, onKeyPress, nRows } = props;
  return (
    <TextField
      inputProps={{
        style: {
          fontSize: props.fontSize ?? FONT_SIZES["normal"],
          textAlign: props.leftAlign ? "left" : "center",
          color: PALETTE.font.dark,
          fontWeight: !!value ? BOLD_FONT_WEIGHT : "unset",
          padding: "0 !important",
          lineHeight: `${LINE_HEIGHTS.normal}px`,
        },
        form: {
          autoComplete: "off",
        },
      }}
      multiline
      rows={props.nRows || N_ROWS}
      value={value}
      sx={{
        ".MuiInputBase-root": {
          paddingTop: "9px",
          paddingBottom: "9px",
        },
        // ".MuiInputBase-root": {
        //   padding: "0 !important",
        // },
        width: width ?? DEFAULT_WIDTH,
        //minHeight: height,
        //maxHeight: height,
        borderRadius: "8px",
        outline: props.outline,
        bgcolor: props.backgroundColor ?? PALETTE.secondary.grey[1],
        "& fieldset": { border: "none" },
      }}
      onChange={onChange}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      onBlur={props.onBlur}
    />
  );
}
