import { Amplify } from 'aws-amplify';
import awsConfig from '../aws-exports';

const REDIRECT_URLS = {
    dev: 'http://localhost:3000',
    staging: 'https://dev.app.astrosafe.co',
    prod: 'https://app.astrosafe.co'
}

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const redirectUrl = REDIRECT_URLS[isLocalhost ? 'dev' : process.env.REACT_APP_BUILD_ENV]

const configureAmplify = () =>
  Amplify.configure({
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
    },
  });

export default configureAmplify;