import React from "react";
import { Box, Stack } from "@mui/material";
import Typography from "../../components/Typography";
import FadeIn from "react-fade-in";

export const FADEIN_DURATION = 1000;
export const PLACEHOLDER_FADEIN_DELAY = 800;
export const PADDING = "7%";
export const HEADER_CONTENT_SEPARATION = "35px";

export interface IAdminPageContentLayoutProps {
  counter: string;
  count: number;
  description: string;
  mainButton: JSX.Element;
  secondaryButton?: JSX.Element;
  children: React.ReactNode;
}

export default function AdminPageContentLayout(
  props: IAdminPageContentLayoutProps
) {
  return (
    <Stack spacing={HEADER_CONTENT_SEPARATION} width="100%" height="100%">
      <FadeIn transitionDuration={FADEIN_DURATION}>
        <Stack direction="row">
          <Stack width="100%" sx={{ display: "flex" }} spacing={1}>
            <Typography variant="h3" sx={{ display: "flex" }}>
              <Stack direction="row" spacing={1.5}>
                {props.count ? (
                  <Box sx={{ opacity: 0.55 }}>{props.count}</Box>
                ) : null}
                <Box>{props.counter}</Box>
              </Stack>
            </Typography>
            <Typography variant="normal" faded sx={{ paddingLeft: "3px" }}>
              {props.description}
            </Typography>
          </Stack>
          <Stack direction="row" spacing="10px" alignItems="flex-end">
            {props.secondaryButton}
            {props.mainButton}
          </Stack>
        </Stack>
      </FadeIn>
      {props.children}
    </Stack>
  );
}
