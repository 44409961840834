import React, { useState } from "react";
import UrsorDialog from "../../../components/UrsorDialog";
import UrsorInputField from "../../../components/inputs/UrsorInputField";
import ApiController from "../../../controllers/ApiController";
import { useUserContext } from "../../../contexts/UserContext";
import NotificationContext from "../../../contexts/NotificationContext";
import Typography from "../../../components/Typography";
import { ISchool } from "../AdminPage";
import _ from "lodash";
import { Stack } from "@mui/system";
import { useUserDataContext } from "../../../contexts/UserDataContext";

export interface ITeacherInvitationDialogProps {
  open: boolean;
  closeCallback: () => void;
  callback: () => void;
  school: ISchool;
}

export default function TeacherInvitationDialog(
  props: ITeacherInvitationDialogProps
) {
  const notificationCtx = React.useContext(NotificationContext);
  const [email, setEmail] = useState<string>("");
  const userCtx = useUserContext();
  const dataCtx = useUserDataContext();
  return (
    <UrsorDialog
      title="Add a Teacher"
      subtitle={["Invite a Teacher by email."]}
      supertitle="Add a Teacher"
      open={props.open}
      onCloseCallback={() => {
        props.closeCallback();
        setEmail("");
      }}
      backButtonCallback={() => {
        props.closeCallback();
        setEmail("");
      }}
      button={{
        text: "Invite",
        callback: () =>
          ApiController.inviteTeacher(
            //userCtx.userDetails?.schoolId,
            email,
            userCtx.userDetails?.id
          )
            .then(() => notificationCtx.success("Invited Teacher"))
            .then(props.callback)
            .then(props.closeCallback)
            .then(() => setEmail("")),
        disabled: !email,
      }}
      secondaryButton={{
        variant: "ghost",
        text: "Copy Join Code",
        callback: () => {
          navigator.clipboard.writeText(props.school.teacherCode);
          notificationCtx.success("Copied to clipboard.");
        },
      }}
    >
      <Stack spacing="60px" alignItems="center">
        <UrsorInputField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value)
          }
          value={email}
          placeholder={"r.feynman@academy.co.uk"}
          width={410}
        />
        <Stack spacing="16px" alignItems="center">
          <Typography variant="medium">Or share the Join Code:</Typography>
          <Typography variant="h3">
            {_.chunk(props.school.teacherCode, 3).join("-").replaceAll(",", "")}
          </Typography>
        </Stack>
      </Stack>
    </UrsorDialog>
  );
}
