import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useUserDataContext } from "../../contexts/UserDataContext";
import { useUserContext } from "../../contexts/UserContext";
import NotificationContext from "../../contexts/NotificationContext";
import UrsorDialog from "../../components/UrsorDialog";
import {
  CharactersIndicator,
  DialogSection,
  PaletteButton,
  SECONDARY_COLOR_ORDER,
} from "../BrowserPage/dialogs/LinkDialog";
import UrsorInputField from "../../components/inputs/UrsorInputField";
import { Stack } from "@mui/system";
import ApiController from "../../controllers/ApiController";
import { IChannel } from "./LibraryPage";
import { PALETTE } from "../../palette";
// import mixpanel from "mixpanel-browser";

const CREATION_SUCCESS_MESSAGE = "Lesson added";
const UPDATE_SUCCESS_MESSAGE = "Lesson updated";
const MAX_CHARACTERS = 20;

export interface IChannelDialogProps {
  channel?: IChannel;
  open: boolean;
  closeCallback: () => void;
  backCallback?: () => void;
  completionCallback?: (id: string) => void;
}

export default function ChannelDialog(props: IChannelDialogProps) {
  const notificationCtx = React.useContext(NotificationContext);
  const dataCtx = useUserDataContext();
  const userCtx = useUserContext();
  const [title, setTitle] = useState<string>("");
  useEffect(() => {
    props.channel?.title && setTitle(props.channel.title);
  }, [props.channel?.title]);

  const submitCreation = () =>
    ApiController.createChannel(
      title,
      color,
      userCtx.userDetails?.schoolId,
      userCtx.userDetails?.id
    )
      .then((channel) => {
        props.completionCallback?.(channel.id);
      })
      .then(dataCtx.refreshChannels)
      // .then(() =>
      //   mixpanel.track("lesson created", {
      //     creatorId: userCtx.userDetails?.id,
      //     schoolId: userCtx.userDetails?.schoolId,
      //   })
      // )
      .then(props.closeCallback)
      .then(() => notificationCtx.success(CREATION_SUCCESS_MESSAGE));

  const submitUpdate = () =>
    ApiController.updateChannel(props.channel?.id, { title, color })
      .then(dataCtx.refreshChannels)
      .then(props.closeCallback)
      .then(() => notificationCtx.success(UPDATE_SUCCESS_MESSAGE));

  const [color, setColor] = useState<string>(PALETTE.secondary.green[2]);
  useEffect(() => {
    props.channel?.color && setColor(props.channel.color);
  }, [props.channel?.color]);
  useEffect(() => {
    !props.channel &&
      setColor(
        PALETTE.secondary[
          SECONDARY_COLOR_ORDER[_.random(SECONDARY_COLOR_ORDER.length - 1)]
        ][_.random(2, 5)]
      );
  }, [props.open]);

  return (
    <UrsorDialog
      open={props.open}
      title={props.channel ? "Edit Lesson" : "Add a Lesson to your Library"}
      subtitle={[
        props.channel ? "Edit your Lesson" : "Choose a color and a name.",
      ]}
      supertitle={props.channel ? "Update Lesson" : "Add a Lesson"}
      button={{
        text: props.channel ? "Complete" : "Add",
        disabled: !title,
        callback: props.channel ? submitUpdate : submitCreation,
      }}
      onCloseCallback={props.closeCallback}
      backButtonCallback={() => {
        props.closeCallback();
        props.backCallback?.();
      }}
    >
      <Stack width="60%" height="90px" direction="row" spacing="16px">
        <Stack width="14%">
          <DialogSection title="Color">
            <Stack
              height="40px"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <PaletteButton selected={color} callback={(c) => setColor(c)} />
            </Stack>
          </DialogSection>
        </Stack>
        <DialogSection title="Name">
          <Stack position="absolute" top="9px" right="13px">
            <CharactersIndicator n={title.length} max={MAX_CHARACTERS} />
          </Stack>
          <UrsorInputField
            value={title}
            placeholder="Add a name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTitle(event.target.value.slice(0, MAX_CHARACTERS))
            }
            width="100%"
          />
        </DialogSection>
      </Stack>
    </UrsorDialog>
  );
}
