import React, { useState } from "react";
import { Box, Stack } from "@mui/system";
import Typography from "../../../components/Typography";
import { PALETTE } from "../../../palette";
import UrsorDialog from "../../../components/UrsorDialog";
import ApiController from "../../../controllers/ApiController";
import _ from "lodash";
import { Input } from "@mui/material";
import JoiningCodeInput from "../../../components/JoinCodeInput";

export const JOIN_CODE_LENGTH = 9;
const FAILURE_DURATION = 2000;

const SchoolJoinCodeDialog = (props: {
  open: boolean;
  closeCallback: () => void;
  successCallback: (schoolId: string) => void;
  skipCallback: () => void;
}) => {
  const [inputedCode, setInputedCode] = useState<string>("");
  const [showFailure, setShowFailure] = useState<boolean>(false);
  const [inputActive, setInputActive] = useState<boolean>(false);
  return (
    <UrsorDialog
      title="Join a School"
      subtitle={["Input your School Joining Code."]}
      supertitle="Create Account"
      open={props.open}
      onCloseCallback={props.closeCallback}
      backButtonCallback={props.closeCallback}
      button={{
        text: "Join",
        callback: () =>
          ApiController.verifyTeacherCode(inputedCode)
            // .then((school) =>
            //   ApiController.updateTeacher(props.userId, { schoolId: school.id })
            // )
            .then((school) => props.successCallback(school.id))
            .catch(() => {
              setShowFailure(true);
              setTimeout(() => {
                setShowFailure(false);
                setInputActive(true);
                setInputedCode("");
              }, FAILURE_DURATION);
            }),
        disabled: inputedCode.length !== JOIN_CODE_LENGTH,
      }}
      secondaryButton={{
        text: "Do this later",
        variant: "ghost",
        callback: props.skipCallback,
      }}
      noBackdrop
    >
      <Stack flex={1} justifyContent="center" alignItems="center">
        <JoiningCodeInput
          value={inputedCode}
          callback={(value) => setInputedCode(value)}
          showFailure={showFailure}
          active={inputActive}
          activeCallback={(active) => setInputActive(active)}
        />
      </Stack>
    </UrsorDialog>
  );
};

export default SchoolJoinCodeDialog;
