import React, { useState, useEffect } from "react";
import configureAmplify from "../../hooks/configureAmplify";
import Login from "./states/Login";
import UrsorParticles from "../../components/UrsorParticles";
import { useUserContext } from "../../contexts/UserContext";
import OnBoarding from "./states/OnBoarding";
import HoldingPen from "./states/HoldingPen";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "usehooks-ts";
import jwtDecode from "jwt-decode";
import ApiController from "../../controllers/ApiController";
import NotificationContext from "../../contexts/NotificationContext";
import { ISchool } from "../BrowserPage/LinksPage";

const HOUR = 3600000;
const AUTH0_EMAIL_CONNECTION_TYPE_NAME = "Username-Password-Authentication";

export type AuthIssue = "user" | "name" | "school";

configureAmplify();

export interface IUrsorAuthProps {
  children: React.ReactNode;
}

export default function AuthFlowController(props: IUrsorAuthProps) {
  const userCtx = useUserContext();
  const notificationCtx = React.useContext(NotificationContext);

  const [isFreeSchool, setIsFreeSchool] = useLocalStorage<boolean>(
    "isFreeSchool",
    false
  );
  useEffect(() => {
    userCtx.userDetails?.schoolId &&
      ApiController.getSchool(userCtx.userDetails?.schoolId)
        .then((school) => {
          isFreeSchool &&
            !school.free &&
            notificationCtx.success("Joined School");
          setIsFreeSchool(school.free);
        })
        .catch((error) => notificationCtx.error(error.message));
  }, [userCtx.userDetails?.schoolId]);

  const {
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>(
    "accessToken",
    undefined
  );
  const [email, setEmail] = useLocalStorage<string | undefined>(
    "email",
    undefined
  );

  const [authConnectionType, setAuthConnectionType] = useLocalStorage<
    "email" | "social"
  >("authConnectionType", "social");
  useEffect(() => {
    if (user) {
      userCtx.load(user.email);
      setEmail(user.email);
      getIdTokenClaims().then((idToken) =>
        setAuthConnectionType(
          idToken?.connectionType === AUTH0_EMAIL_CONNECTION_TYPE_NAME
            ? "email"
            : "social"
        )
      );
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [user?.email, isLoading]);

  useEffect(() => {
    const tokenIsExpired = accessToken
      ? ((jwtDecode(accessToken) as any)["exp"] as number) <
        new Date().getTime()
      : false;
    (!accessToken || tokenIsExpired) &&
      user &&
      getAccessTokenSilently().then((at) => setAccessToken(at));
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      getAccessTokenSilently().then((at) => setAccessToken(at));
    }, HOUR);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const [authIssue, setAuthIssue] = useState<AuthIssue | null>(null);

  useEffect(() => {
    if (!userCtx.userDetails) {
      setAuthIssue("user");
    } else if (!userCtx.userDetails.teacherName) {
      setAuthIssue("name");
    } else if (!userCtx.userDetails.schoolId) {
      setAuthIssue("school");
    } else {
      setAuthIssue(null);
    }
  }, [userCtx.userDetails]);

  // useEffect(() => {
  //   !!userCtx.userDetails?.teacherName &&
  //     !!userCtx.userDetails?.email &&
  //     !userCtx.userDetails?.schoolId &&
  //     ApiController.createSchool(userCtx.userDetails?.id).then(() =>
  //       userCtx.load(userCtx.userDetails?.email)
  //     );
  // }, [userCtx.userDetails?.teacherName, userCtx.userDetails?.email]);

  return (
    <>
      <UrsorParticles />
      {!authIssue && userCtx.userDetails?.schoolId ? ( //&&
        // (!userCtx.userDetails.invitationPendingByInviterId ||
        //userCtx.userDetails.onBoardingDone)
        props.children
      ) : (
        <>
          {authIssue === "user" ? <Login /> : null}
          {/* {authIssue === "name" || authIssue === "gc" ? (
            <OnBoarding gcStepGoingOnCallback={(on) => setGCStepGoingOn(on)} />
          ) : null} */}
          {authIssue === "name" || authIssue === "school" ? (
            <OnBoarding />
          ) : null}
        </>
      )}
    </>
  );
}
