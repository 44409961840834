import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import {
  BACKDROP_STYLE,
  BORDER_RADIUS,
  DEFAULT_FADEIN_DURATION,
} from "../../components/UrsorDialog";
import LinkCard from "./components/LinkCard";
import { useUserDataContext } from "../../contexts/UserDataContext";
import { CARD_WIDTH, ILink } from "../BrowserPage/dialogs/LinkDialog";
import { Stack } from "@mui/system";
import Typography from "../../components/Typography";
import { PALETTE } from "../../palette";
import moment from "moment";
import DetailsSection from "../../components/DetailsSection";
import { ReactComponent as LinkIcon } from "../../images/icons/LinkIcon.svg";
import { ReactComponent as PersonIcon } from "../../images/icons/PersonIcon.svg";
import { ReactComponent as ShieldLockIcon } from "../../images/icons/ShieldLockIcon.svg";
import { ReactComponent as ArrowUpRightIcon } from "../../images/icons/ArrowUpRightIcon.svg";
import { ReactComponent as PencilIcon } from "../../images/icons/PencilIcon.svg";
import { ReactComponent as X } from "../../images/icons/x.svg";
import { useUserContext } from "../../contexts/UserContext";
import UrsorButton from "../../components/buttons/UrsorButton";

const WIDTH = "847px";
const MIN_HEIGHT = "432px";

export interface ILinkViewDialogProps {
  linkId: string;
  open: boolean;
  closeCallback: () => void;
  openEditDialogCallback: () => void;
}

export default function LinkViewDialog(props: ILinkViewDialogProps) {
  const dataCtx = useUserDataContext();
  const userCtx = useUserContext();
  const [link, setLink] = useState<ILink | undefined>(undefined);
  useEffect(
    () => setLink(dataCtx.links?.find((l) => l.id === props.linkId)),
    [props.linkId, dataCtx.stacks]
  );
  return link ? (
    <Dialog
      transitionDuration={DEFAULT_FADEIN_DURATION}
      open={props.open}
      onClose={props.closeCallback}
      PaperProps={{
        style: {
          width: WIDTH,
          maxWidth: WIDTH,
          height: MIN_HEIGHT,
          minHeight: MIN_HEIGHT,
          borderRadius: BORDER_RADIUS,
        },
      }}
      sx={{
        py: "10px",
        ".MuiBackdrop-root": BACKDROP_STYLE,
      }}
    >
      <Stack
        p="40px"
        boxSizing="border-box"
        flex={1}
        direction="row"
        spacing="60px"
        alignItems="center"
      >
        <Stack
          width={CARD_WIDTH}
          minWidth={CARD_WIDTH}
          sx={{
            pointerEvents: "none",
          }}
        >
          <LinkCard link={link} />
        </Stack>
        <Stack width="404px" flex={1} height="100%">
          <Stack flex={1} justifyContent="space-between">
            <Stack spacing="8px">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">{link.title}</Typography>
                <Stack
                  sx={{
                    cursor: "pointer",
                    "&:hover": { opacity: 0.6 },
                    transition: "0.2s",
                  }}
                  onClick={props.closeCallback}
                  zIndex={2}
                >
                  <X height="27px" width="27px" />
                </Stack>
              </Stack>
              <Stack direction="row" spacing="5px">
                <Typography color={PALETTE.secondary.grey[4]} bold>
                  Created on
                </Typography>
                <Typography color={PALETTE.secondary.grey[4]}>
                  {moment(link?.createdAt).format("Do MMMM YYYY")}
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing="16px">
              <DetailsSection
                title="URL"
                icon={LinkIcon}
                titleSize="medium"
                contentFontSize="medium"
                contentFontColor={PALETTE.secondary.grey[4]}
                iconSize="17px"
              >
                <a
                  target="_blank"
                  href={link.url}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="medium"
                    color={PALETTE.secondary.grey[4]}
                    sx={{
                      "&:hover": { opacity: 0.7 },
                      transition: "0.2s",
                    }}
                  >
                    {link.url}
                  </Typography>
                </a>
              </DetailsSection>
              <DetailsSection
                title="Accessible URL"
                icon={ShieldLockIcon}
                titleSize="medium"
                contentFontSize="medium"
                contentFontColor={PALETTE.secondary.grey[4]}
                iconSize="17px"
              >
                <a
                  target="_blank"
                  href={link.accessibleUrl}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="medium"
                    color={PALETTE.secondary.grey[4]}
                    sx={{
                      "&:hover": { opacity: 0.7 },
                      transition: "0.2s",
                    }}
                  >
                    {link.accessibleUrl}
                  </Typography>
                </a>
              </DetailsSection>
              <DetailsSection
                title="Added by"
                icon={PersonIcon}
                titleSize="medium"
                contentFontSize="medium"
                contentFontColor={PALETTE.secondary.grey[4]}
                iconSize="17px"
              >
                {
                  dataCtx.teachers.find((t) => t.id === userCtx.userDetails?.id)
                    ?.teacherName
                }
              </DetailsSection>
            </Stack>
            <Stack justifyContent="flex-end" direction="row" spacing="10px">
              <div>
                <UrsorButton
                  variant="secondary"
                  onClick={props.openEditDialogCallback}
                  endIcon={<PencilIcon height="16px" width="16px" />}
                >
                  Edit
                </UrsorButton>
              </div>
              <div>
                <a target="_blank" href={link.url}>
                  <UrsorButton
                    onClick={() => null}
                    endIcon={<ArrowUpRightIcon height="20px" width="20px" />}
                  >
                    Visit site
                  </UrsorButton>
                </a>
              </div>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  ) : (
    <></>
  );
}
