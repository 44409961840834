import React, { useEffect, useState } from "react";
import { Input } from "@mui/material";
import { PALETTE } from "../../palette";
import InputAdornment from "@mui/material/InputAdornment";
import { BOLD_FONT_WEIGHT, FONT_SIZES } from "../Typography";

export const DEFAULT_WIDTH = "536px";
export const HEIGHT = "40px";
export const BORDER_RADIUS = "8px";

export default function UrsorInputField(props) {
  const {
    error,
    onChange,
    onEnterKey,
    value,
    placeholder,
    width,
    register,
    leftAlign,
    white,
    border,
    outline,
    password,
    onBlur,
    focusDelay,
    paddingLeft,
  } = props;

  const [active, setActive] = useState(false);
  const [hovering, setHovering] = useState(false);

  const customSx = {
    width: width ?? DEFAULT_WIDTH,
    height: props.height ?? HEIGHT,
    minHeight: props.height ?? HEIGHT,
    borderRadius: BORDER_RADIUS,
    background: props.backgroundColor ?? PALETTE.secondary.grey[1],
    border: `${active || hovering ? 2 : 0}px solid ${
      PALETTE.secondary.purple[active ? 2 : 1]
    }`,
    transition: "0.2s",
    outline: props.outline,
  };

  if (error) {
    customSx["border"] = "2px solid red";
  }

  const inputProps = {
    type: props.password ? "password" : undefined,
    style: {
      fontFamily: "Rubik",
      paddingLeft: props.paddingLeft ?? "12px",
      paddingRight: props.leftAlign ? "12px" : 0,
      textAlign: props.leftAlign ? "left" : "center",
      textOverflow: "ellipsis",
      fontSize:  FONT_SIZES[props.fontSize || "small"],
      color: props.color ?? PALETTE.font.dark,
      fontWeight: BOLD_FONT_WEIGHT,
      lineHeight: "100%",
      transition: "0.2s",
    },
    form: {
      autoComplete: "off",
    },
  };

  if (register) {
    return (
      <Input
        {...register}
        inputProps={inputProps}
        disableUnderline={true}
        sx={customSx}
        placeholder={placeholder}
      />
    );
  }

  const getIsBelowScreen = () =>
    ref?.getBoundingClientRect().bottom > window.innerHeight; // prevents a jump to the bottom of the worksheet

  const [ref, setRef] = useState();
  useEffect(() => {
    if (props.autoFocus && props.focusDelay && ref) {
      setTimeout(() => {
        !getIsBelowScreen() && ref.focus();
      }, props.focusDelay);
    }
  }, [props.focusDelay, ref]);

  return (
    <Input
      inputRef={setRef}
      autoFocus={props.autoFocus && !props.focusDelay}
      inputProps={inputProps}
      endAdornment={
        props.endIcon ? (
          <InputAdornment sx={{ pr: "11px" }} position="end">
            {props.endIcon}
          </InputAdornment>
        ) : null
      }
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      value={value}
      disableUnderline={true}
      sx={customSx}
      onChange={onChange}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          onEnterKey?.();
          ref.blur();
        }
      }}
      placeholder={placeholder}
      onBlur={() => {
        props.onBlur?.();
        setActive(false);
      }}
      onFocus={() => setActive(true)}
    />
  );
}
