import React, { useEffect, useState } from "react";
import NotificationContext from "../../../contexts/NotificationContext";
import ApiController from "../../../controllers/ApiController";
import TeachingNameDialog from "./TeachingNameDialog";
import SchoolJoiningDialog from "./SchoolJoiningDialog";
import InvitationForkDialog from "./InvitationForkDialog";
import { useUserContext } from "../../../contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
// import mixpanel from "mixpanel-browser";
import _ from "lodash";
import { ISchool } from "../../BrowserPage/LinksPage";

export interface IOnBoardingProps {}

export default function OnBoarding(props: IOnBoardingProps) {
  const userCtx = useUserContext();
  const notificationCtx = React.useContext(NotificationContext);

  const { user } = useAuth0();

  const [invitationFlow, setInvitationFlow] = useState(false);
  const [joinCodeStep, setJoinCodeStep] = useState(false);
  const [invitationStep, setInvitationStep] = useState(false);
  const [teachingNameStep, setTeachingNameStep] = useState(true);
  const [teacherName, setTeacherName] = useState<string>("");
  const [schoolId, setSchoolId] = useState<string | undefined>(undefined);
  const [invitedSchoolName, setInvitedSchoolName] = useState<
    string | undefined
  >(undefined);

  // useEffect(() => {
  //   mixpanel.track("signup - init");
  // }, []);

  useEffect(
    () => setSchoolId(userCtx.userDetails?.schoolId),
    [userCtx.userDetails?.schoolId]
  );
  useEffect(() => {
    if (userCtx.userDetails?.invitationPendingByInviterId) {
      ApiController.getTeacher(
        userCtx.userDetails.invitationPendingByInviterId
      ).then((inviter) =>
        ApiController.getSchool(inviter.schoolId).then((school) =>
          setInvitedSchoolName(school.name)
        )
      );
    }
  }, [userCtx.userDetails?.invitationPendingByInviterId]);

  const createTemporarySchool = (schoolName?: string) =>
    ApiController.createTemporarySchool(
      schoolName ||
        `${teacherName}'${
          teacherName[teacherName.length - 1] === "s" ? "" : "s"
        } Space`,
      userCtx.userDetails?.id
    ).then((school: ISchool) => {
      //   mixpanel?.track("signup - temporary school created", {
      //     schoolId: school.id,
      //   });
      setSchoolId(school.id);
    });

  const createTeacher = () =>
    ApiController.createTeacher(
      user?.email,
      user?.name,
      teacherName,
      true
    ).then(() => {
      //mixpanel?.track("signup - account created", { email: user?.email });
      userCtx.load(user?.email);
    });

  const submitOnboardingDone = () =>
    ApiController.updateTeacher(userCtx.userDetails?.id, {
      onBoardingDone: true,
    }).then(() => {
      userCtx.load(user?.email);
    });

  const submitTeacherName = () =>
    ApiController.updateTeacher(userCtx.userDetails?.id, { teacherName }).then(
      () => {
        userCtx.load(user?.email);
      }
    );

  const submitSchoolId = (schoolId: string) =>
    ApiController.updateTeacher(userCtx.userDetails?.id, {
      schoolId,
    }).then(() => {
      userCtx.load(user?.email);
    });

  const submitSchoolJoiningRequest = (schoolId: string) =>
    ApiController.updateTeacher(userCtx.userDetails?.id, {
      requestedSchoolId: schoolId,
    }).then(() => {
      userCtx.load(user?.email);
    });

  const submitReplyToInvitation = (accept: boolean) =>
    ApiController.replyToInvitation(userCtx.userDetails?.id, accept).then(
      () => {
        userCtx.load(user?.email);
      }
    );

  useEffect(() => {
    userCtx.userDetails?.id &&
      schoolId &&
      ApiController.updateTeacher(userCtx.userDetails?.id, { schoolId }).then(
        () => {
          userCtx.load(userCtx.userDetails?.email);
        }
      );
  }, [schoolId, userCtx.userDetails?.id]);

  useEffect(() => {
    if (userCtx.userDetails?.invitationPendingByInviterId) {
      setInvitationFlow(true);
      setInvitationStep(true);
      setTeachingNameStep(false);
    } else {
      setTeachingNameStep(true);
    }
  }, []);

  return (
    <>
      <InvitationForkDialog
        open={invitationStep}
        rejectCallback={() => {
          submitReplyToInvitation(false);
          setInvitationStep(false);
          setTeachingNameStep(true);
        }}
        acceptCallback={() => {
          submitReplyToInvitation(true);
          setInvitationStep(false);
          setTeachingNameStep(true);
        }}
        schoolName={invitedSchoolName}
      />
      <TeachingNameDialog
        open={teachingNameStep}
        name={teacherName}
        setName={setTeacherName}
        submitCallback={() => {
          if (invitationFlow) {
            submitTeacherName();
            submitOnboardingDone();
            setTeachingNameStep(false);
            !userCtx.userDetails?.schoolId && setJoinCodeStep(true);
          } else {
            createTeacher().then(() =>
              notificationCtx.success("Account created")
            );
            setTeachingNameStep(false);
            setJoinCodeStep(true);
          }
        }}
      />
      <SchoolJoiningDialog
        open={joinCodeStep}
        joinCallback={(id) => {
          ApiController.getTeachersInSchool(id).then((teachers) => {
            if (teachers && teachers.length > 0) {
              submitSchoolJoiningRequest(id);
              console.log(schoolId, "0-0-0-");
              createTemporarySchool().then(() => submitSchoolId(schoolId!));
            } else {
              submitSchoolId(id);
            }
          });
          setJoinCodeStep(false);
        }}
        createCallback={(schoolName) => {
          createTemporarySchool(schoolName).then(() =>
            submitSchoolId(schoolId!)
          );
        }}
        skipCallback={() => {
          createTemporarySchool().then(() => submitSchoolId(schoolId!));
          setJoinCodeStep(false);
        }}
      />
    </>
  );
}
