import React, { useEffect, useState } from "react";
import { useUserContext } from "../../../contexts/UserContext";
import ApiController from "../../../controllers/ApiController";
import _ from "lodash";
import StepDialog, { IDialogStepDetails } from "../../../components/StepDialog";
import { Box, Stack } from "@mui/system";
import { Grid, Switch } from "@mui/material";
import Typography from "../../../components/Typography";
import { PALETTE } from "../../../palette";
import UrsorFadeIn from "../../../components/UrsorFadeIn";
import { hexToRgb } from "../../BrowserPage/dialogs/PlatformDialog";
import { useGoogleClassroomAPIContext } from "../../../contexts/GoogleClassroomAPIContext";
import { ReactComponent as PlusIcon } from "../../../images/icons/PlusIcon.svg";
import {
  getSwitchIcon,
  switchStyle,
} from "../../AdminPage/dialogs/TeacherEditingDialog";
import { DialogSection, ILink } from "../../BrowserPage/dialogs/LinkDialog";
import { IStack, useUserDataContext } from "../../../contexts/UserDataContext";
import UrsorSelect from "../../../components/inputs/UrsorSelect";
import { IChannel } from "../../LibraryPage/LibraryPage";
import { useOverallDialogContext } from "../../../contexts/DialogContext";
import UrsorLoading from "../../../components/spinners/UrsorLoading";

export const getRandomColor = () => {
  const keys = Object.keys(PALETTE.secondary); //@ts-ignore
  return PALETTE.secondary[ //@ts-ignore
    keys[Math.floor(keys.length * Math.random())] as SecondaryColor
  ][Math.floor(4 * Math.random()) + 1];
};

interface IImportDialogButtonProps {
  selectable?: boolean;
  selected?: boolean;
  text: string;
}

export function ImportDialogButton(props: IImportDialogButtonProps) {
  const [hovering, setHovering] = useState<boolean>(false);
  return (
    <Stack
      py="5px"
      px="11px"
      bgcolor={
        props.selected
          ? PALETTE.secondary.purple[2]
          : props.selectable && hovering
          ? PALETTE.secondary.purple[1]
          : PALETTE.secondary.grey[2]
      }
      borderRadius="8px"
      sx={{
        transition: "0.2s",
        cursor: props.selectable ? "pointer" : "auto",
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Typography
        bold
        noWrap
        color={
          props.selectable && (props.selected || hovering)
            ? PALETTE.font.light
            : PALETTE.font.dark
        }
        sx={{ pointerEvents: "none" }}
      >
        {props.text}
      </Typography>
    </Stack>
  );
}

export interface IGCLessonImportDialogProps {
  open: boolean;
  closeCallback: () => void;
  lessonId?: string;
  callback?: () => void;
  syncCallback: (on: boolean) => void;
}

export default function GCLessonImportDialog(
  props: IGCLessonImportDialogProps
) {
  const userDetails = useUserContext().userDetails;
  const gcApi = useGoogleClassroomAPIContext();
  const dataCtx = useUserDataContext();
  const dialogCtx = useOverallDialogContext();

  //@ts-ignore
  const [gcCourses, setGCCourses] = useState<gapi.client.classroom.Course[]>(
    []
  );
  const [stacksWithLinks, setLessonsWithLinks] = useState<
    { stack: IStack; links: ILink[] }[]
  >([]);
  const [step, setStep] = useState<number>(0);

  const [gcSync, setGCSync] = useState<boolean>(false);

  const [userChannels, setUserChannels] = useState<IChannel[]>([]);
  useEffect(
    () =>
      setUserChannels(
        dataCtx.channels?.filter((c) => c.creatorId === userDetails?.id) || []
      ),
    [dataCtx.channels]
  );
  const [selectedChannelId, setSelectedChannelId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    props.open &&
      userDetails?.id &&
      userDetails?.gapiRefreshToken &&
      ApiController.getGCCourses(userDetails?.id).then((courses) =>
        setGCCourses(courses)
      );
  }, [props.open, userDetails?.id, userDetails?.gapiRefreshToken]);

  useEffect(() => {
    userDetails?.id &&
      gcCourses.length > 0 &&
      userDetails?.gapiRefreshToken &&
      ApiController.getLessonsWithLinksFromGCCourses(
        userDetails?.id,
        gcCourses.map((c) => c.id)
      ).then((lessons) => setLessonsWithLinks(lessons));
  }, [gcCourses.length, userDetails?.id, userDetails?.gapiRefreshToken]);

  const submit = async () =>
    Promise.all(
      stacksWithLinks.map((swl) => {
        /* import a whole lesson */
        return ApiController.createStack({
          ..._.omit(swl.stack, "id"),
          backgroundColors: [
            getRandomColor(),
            getRandomColor(),
            getRandomColor(),
          ],
          schoolId: userDetails?.schoolId,
          channelId: selectedChannelId,
          creatorId: userDetails?.id,
        }).then((newLesson) =>
          Promise.all(
            swl.links.map((link: any) =>
              ApiController.createLink({
                ..._.omit(link, "id"),
                accessibleUrl: link.url,
                schoolId: userDetails?.schoolId,
                stackId: newLesson.id,
                channelId: selectedChannelId,
                creatorId: userDetails?.id,
                color: getRandomColor(),
              })
            )
          )
            .then(() => {
              ApiController.updateLatestGCSyncTime(userDetails?.id);
              props.callback?.();
            })
            .then(() => {
              dataCtx.refreshLinks();
              dataCtx.refreshStacks();
              dataCtx.refreshChannels();
            })
        );
      })
    );

  const clearAll = () => {
    setStep(0);
    setLessonsWithLinks([]);
  };

  const [loading, setLoading] = useState<boolean>();

  const steps: IDialogStepDetails[] = [
    {
      title: "Access to Google Classroom",
      subtitle: [
        "In order to import from Google Classroom you need to",
        "provide permission for Astro to access this data.",
        "You will need to check all the boxes to proceed.",
      ],
      button: {
        variant: "google",
        text: "Connect",
        callback: () =>
          gcApi.requestAccessToFeatureScopes("importLesson", () =>
            setStep(step + 1)
          ),
      },
      secondaryButton: {
        text: "Do this later",
        variant: "ghost",
        callback: props.closeCallback,
      },
    },
    {
      title: "Your Google Classroom Courses",
      subtitle: [
        'We will bring these "Courses" over to Astro',
        "as Stacks, as well as their Links.",
      ],
      content: (
        <Grid
          container
          rowGap="10px"
          columnGap="10px"
          justifyContent="center"
          width="79%"
        >
          {stacksWithLinks.map((swl, index) => (
            <Grid item key={swl.stack.id}>
              <UrsorFadeIn duration={800} delay={index * 100}>
                <ImportDialogButton text={swl.stack.title} />
              </UrsorFadeIn>
            </Grid>
          ))}
        </Grid>
      ),
    },
    {
      title: "Select Channel",
      subtitle: ["Which Channel should we put your", "new Stacks into?"],
      button: {
        text: "Import",
        callback: () => {
          setLoading(true);
          return submit()
            .then(() => setLoading(false))
            .then(() => true);
        },
        disabled: !selectedChannelId || loading,
      },
      content: loading ? (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <UrsorLoading />
        </Stack>
      ) : (
        <Stack width="40%">
          <DialogSection title="Channel">
            <UrsorSelect
              items={userChannels.map((c) => ({
                id: c.id,
                value: c.title,
              }))}
              selected={selectedChannelId ? [selectedChannelId] : []}
              callback={(id) => setSelectedChannelId(id)}
              width="100%"
              placeholder="Select Channel"
              leftAlign
              listButtons={[
                {
                  title: "New Channel",
                  icon: PlusIcon,
                  callback: () =>
                    dialogCtx.setChannelDialogProps({
                      completionCallback: (id) => setSelectedChannelId(id),
                      open: true,
                      closeCallback: () => null,
                    }),
                },
              ]}
            />
          </DialogSection>
        </Stack>
      ),
    },
    {
      title:
        stacksWithLinks.length > 1
          ? "Your Stacks are ready"
          : "Your Stack is ready",
      subtitle: [
        "Do you want Astro to automatically sync",
        "with your Google Classroom?",
      ],
      content: (
        <Stack flex={1} justifyContent="space-between">
          <Stack spacing="5px" alignItems="center">
            <Typography bold color={PALETTE.secondary.grey[4]}>{`Sync ${
              gcSync ? "On" : "Off"
            }`}</Typography>
            <Switch
              sx={switchStyle}
              checked={gcSync}
              icon={getSwitchIcon(false)}
              checkedIcon={getSwitchIcon(true)}
              onChange={(change: React.ChangeEvent<HTMLInputElement>) => {
                setGCSync(change.target.checked);
                props.syncCallback(change.target.checked);
              }}
            />
          </Stack>
          <Box
            component="img"
            height="240px"
            src="https://ursorassets.s3.eu-west-1.amazonaws.com/img/ursorOnboardingImage.png"
          />
        </Stack>
      ),
      noBackButton: true,
    },
  ];

  return (
    <StepDialog
      open={props.open}
      steps={steps}
      step={step}
      callback={(newStep: number) => setStep(newStep)}
      closeCallback={() => {
        clearAll();
        props.closeCallback();
      }}
    />
  );
}
