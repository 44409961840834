import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import customThemeConfig from "./styles/theme";
import AccountPage from "./pages/AccountPage/AccountPage";
import LibraryPage from "./pages/LibraryPage/LibraryPage";
import DevicesPage from "./pages/DevicesPage/DevicesPage";
import { Helmet } from "react-helmet";
import NotFound from "./pages/NotFound";
import NotificationProvider from "./contexts/NotificationProvider";
import { SidebarNotificationProvider } from "./contexts/SidebarNotificationContext";
import UrsorNotificationBar from "./components/UrsorNotificationBar";
import AuthFlowController from "./pages/AuthFlow/AuthFlowController";
import { UserProvider } from "./contexts/UserContext";
import { UserDataProvider } from "./contexts/UserDataContext";
import { HistoryProvider } from "./contexts/HistoryContext";
import AddonsPage from "./pages/AddonsPage/AddonsPage";
import LinksPage from "./pages/BrowserPage/LinksPage";
import { GoogleClassroomAPIProvider } from "./contexts/GoogleClassroomAPIContext";
import { DialogProvider } from "./contexts/DialogContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { redirectUrl } from "./hooks/configureAmplify";
import IntroTour from "./components/IntroTour";
import AdminPage from "./pages/AdminPage/AdminPage";
import Hotjar from "@hotjar/browser";
import AppsPage from "./pages/BrowserPage/AppsPage";
// import mixpanel from "mixpanel-browser";

const siteId = 3697469;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

// mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
//   debug: true,
//   track_pageview: false,
//   persistence: "localStorage",
// });

const theme = responsiveFontSizes(createTheme(customThemeConfig as any));

interface IRoute {
  path: string;
  element: JSX.Element;
}

const ROUTES: IRoute[] = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/filter",
    element: <LinksPage />,
  },
  {
    path: "/library",
    element: <LibraryPage />,
  },
  {
    path: "/school",
    element: <AdminPage />,
  },
  {
    path: "/account",
    element: <AccountPage />,
  },
  {
    path: "/",
    element: <DevicesPage />,
  },
  {
    path: "/apps",
    element: <AppsPage />,
  },
  {
    path: "/devices",
    element: <DevicesPage />,
  },
  {
    path: "/plugins",
    element: <AddonsPage />,
  },
];

export default function App() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ASTRO Teacher Portal</title>
        <link rel="canonical" href="https://app.astrosafe.co" />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
            authorizationParams={{
              audience: "https://api-gateway-authorizer",
              redirect_uri: redirectUrl,
            }}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
          >
            <GoogleClassroomAPIProvider>
              <NotificationProvider>
                <AuthFlowController>
                  <UserDataProvider>
                    <SidebarNotificationProvider>
                      <UrsorNotificationBar />
                      <BrowserRouter>
                        <HistoryProvider>
                          <DialogProvider>
                            <IntroTour />
                            <Routes>
                              {ROUTES.map((route) => (
                                <Route
                                  key={route.path}
                                  path={route.path}
                                  element={route.element}
                                />
                              ))}
                            </Routes>
                          </DialogProvider>
                        </HistoryProvider>
                      </BrowserRouter>
                    </SidebarNotificationProvider>
                  </UserDataProvider>
                </AuthFlowController>
              </NotificationProvider>
            </GoogleClassroomAPIProvider>
          </Auth0Provider>
        </UserProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}
