import { createTheme, ThemeProvider } from "@mui/material/styles";
import basicTheme from "../styles/theme";
import React, { useMemo } from "react";

export interface ILightModeProps {
  children: React.ReactNode;
}

export default function LightMode(props: ILightModeProps) {
  const theme = useMemo(
    () =>
      createTheme({
        ...basicTheme,
        palette: { ...basicTheme.palette, mode: "light" },
      }),
    []
  );
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
