import { createTheme, ThemeProvider } from "@mui/system";
import basicTheme from "../styles/theme";
import React, { useMemo } from "react";

export interface IDarkModeProps {
  children: React.ReactNode;
}

export default function DarkMode(props: IDarkModeProps) {
  const theme = useMemo(
    () =>
      createTheme({
        ...basicTheme,
        palette: { ...basicTheme.palette, mode: "dark" },
      }),
    []
  );
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
