import React, { useMemo } from "react";
import { Box, Stack } from "@mui/system";
import Typography from "./Typography";
import { PALETTE } from "../palette";
import _ from "lodash";

const SMALL_CORNER_RADIUS = "8px";
const FULL_CORNER_RADIUS = "16px";

export interface ITagProps {
  selected?: boolean;
  text: string;
  dotColor?: string;
  backgroundColor?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  n?: number;
  callback?: () => void;
  notificationN?: number;
  fullyRounded?: boolean;
}

export default function Tag(props: ITagProps) {
  //const Icon = props.symbol ? ICONS[props.symbol] : null;
  return (
    <Stack
      height="32px"
      width="fit-content"
      px="12px"
      borderRadius={
        props.fullyRounded ? FULL_CORNER_RADIUS : SMALL_CORNER_RADIUS
      }
      bgcolor={
        props.selected
          ? PALETTE.secondary.purple[2]
          : props.backgroundColor || PALETTE.secondary.grey[2]
      }
      justifyContent="center"
      sx={{
        transition: "0.2s",
        svg: {
          path: {
            transition: "0.2s",
            fill: props.selected ? PALETTE.font.light : PALETTE.font.dark,
          },
        },
      }}
      position="relative"
      overflow="visible"
    >
      {props.notificationN ? (
        <Stack
          position="absolute"
          top="-8px"
          right="-8px"
          width="16px"
          height="16px"
          bgcolor={PALETTE.system.orange}
          justifyContent="center"
          alignItems="center"
          borderRadius="100%"
        >
          <Typography variant="tiny" bold color={PALETTE.font.light}>
            {props.notificationN}
          </Typography>
        </Stack>
      ) : null}
      <Stack direction="row" spacing="8px">
        {props.dotColor ? (
          <Box
            bgcolor={props.dotColor}
            width="20px"
            height="20px"
            borderRadius="100%"
          />
        ) : null}
        <Stack direction="row" spacing="4px">
          {_.isNumber(props.n) ? (
            <Typography
              variant="small"
              bold
              color={props.selected ? PALETTE.font.light : PALETTE.font.dark}
            >
              {props.n}
            </Typography>
          ) : null}
          <Typography
            variant="small"
            bold
            color={props.selected ? PALETTE.font.light : PALETTE.font.dark}
            noWrap
          >
            {props.text}
          </Typography>
        </Stack>
        {props.icon ? (
          <Stack
            onClick={props.callback}
            sx={{
              "&:hover": { opacity: props.callback ? 0.5 : 1 },
              transition: "0.2s",
              cursor: "pointer",
            }}
          >
            <props.icon height="20px" width="20px" />
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
}
