import React from 'react';
import { useTheme } from "@mui/system";
import { useLottie } from 'lottie-react';
// import ursorLoadingSpinnerDark from '../../lotties/ursorLoadingLottie.json';
// import ursorLoadingSpinnerLight from '../../lotties/ursorLoadingLottieLight.json';
import byteLoading from '../../lotties/byteLoading.json'
import { Box } from "@mui/material";

export default function UrsorLoading(props) {

  const theme = useTheme();

  const options = {
    animationData: byteLoading,
    // animationData: theme.palette.mode === "dark" ? ursorLoadingSpinnerLight : ursorLoadingSpinnerDark,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, { height: props.size ?? '80px' });

  return <Box
    height={props.size ?? "80px"}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >{View}</Box>
}
