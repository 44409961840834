import React, { useContext, createContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import ApiController from "../controllers/ApiController";
import { ITeacher } from "../pages/AdminPage/AdminPageTeachersTab";
// import mixpanel from "mixpanel-browser";

export interface IUserContext {
  userDetails: ITeacher | undefined;
  googleId: string | undefined;
  setUsername: (username: string) => void;
  // setAuthConnectionType: (type: "email" | "social") => void;
  setGoogleId: (id: string) => void;
  load: (newUsername?: string) => void;
  clear: () => void;
  //setGCOnBoardingStepComplete: () => void;
}

const UserContext = createContext<IUserContext>({
  userDetails: undefined,
  googleId: undefined,
  setUsername: () => null,
  // setAuthConnectionType: () => null,
  setGoogleId: () => null,
  load: () => null,
  clear: () => null,
  //setGCOnBoardingStepComplete: () => null,
});

const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};

interface IUserProviderProps {
  children: React.ReactNode;
}

const UserProvider = (props: IUserProviderProps) => {
  const [userDetails, setUserDetails] = useLocalStorage<ITeacher | undefined>(
    "userDetails",
    undefined
  );
  const [googleId, setGoogleId] = useLocalStorage<string | undefined>(
    "googleId",
    undefined
  );
  const [username, setUsername] = useLocalStorage<string | undefined>(
    "username",
    undefined
  );

  const load = (newUsername?: string) => {
    (username || newUsername) &&
      ApiController.checkTeacherExists(username || newUsername).then((ud) => {
        setUserDetails(ud);
      });
  };

  // useEffect(() => {
  //   userDetails?.id && mixpanel.identify(userDetails.id);
  // }, [userDetails?.id]);

  return (
    <UserContext.Provider
      value={{
        userDetails,
        googleId,
        setUsername,
        // setAuthConnectionType,
        setGoogleId,
        load,
        clear: () => setUserDetails(undefined),
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider, useUserContext };
