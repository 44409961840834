import React from "react";
import { Box, Stack } from "@mui/material";
import Blocks from "../images/404_blocks.png";
import Typography from "../components/Typography";
import UrsorButton from "../components/buttons/UrsorButton";
import { useLocation, useNavigate } from "react-router";
import DarkMode from "../components/DarkMode";

export interface INotFoundProps {}

export default function NotFound(props: INotFoundProps) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <DarkMode>
      <Box height="100vh" width="100vw">
        <Stack
          spacing="20px"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          width="100%"
          height="100%"
        >
          <Typography variant="h0">404</Typography>
          <Stack spacing="2px" alignItems="center">
            <Stack direction="row" spacing="5px">
              <Typography variant="medium">
                {"Ooops... We do not have a page called"}
              </Typography>
              <Typography bold variant="medium">
                {location.pathname}
              </Typography>
            </Stack>
            <Typography faded variant="medium">
              Click the button below to return to the home page.
            </Typography>
          </Stack>
          <Box pt="11px">
            <UrsorButton size="large" onClick={() => navigate("/")}>
              Home
            </UrsorButton>
          </Box>
        </Stack>
        <Stack
          alignItems="center"
          position="absolute"
          bottom={0}
          width="100%"
          height="19vh"
        >
          <img height="100%" width="auto" src={Blocks} />
        </Stack>
      </Box>
    </DarkMode>
  );
}
