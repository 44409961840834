import _ from "lodash";
import React, { useContext, createContext, useState } from "react";
import { ILinkDialogProps } from "../pages/BrowserPage/dialogs/LinkDialog";
import LinkDialog from "../pages/BrowserPage/dialogs/LinkDialog";
import PlatformDialog, {
  IPlatformDialogProps,
} from "../pages/BrowserPage/dialogs/PlatformDialog";
import DeviceDialog, {
  IDeviceDialogProps,
} from "../pages/DeviceDialog/DeviceDialog";
import AddDeviceDialog, {
  IAddDeviceDialogProps,
} from "../pages/DevicesPage/AddDeviceDialog";
import ChannelDialog, {
  IChannelDialogProps,
} from "../pages/LibraryPage/ChannelDialog";
import StackDialog, {
  IStackDialogProps,
} from "../pages/LibraryPage/StackDialog";
import DeletionDialog, {
  IDeletionDialogProps,
} from "../pages/LibraryPage/DeletionDialog";

export interface IDialogContext {
  setLinkDialogProps: (props: ILinkDialogProps) => void;
  setChannelDialogProps: (props: IChannelDialogProps) => void;
  setStackDialogProps: (props: IStackDialogProps) => void;
  setPlatformDialogProps: (props: IPlatformDialogProps) => void;
  setDeviceDialogProps: (props: IDeviceDialogProps) => void;
  setAddDeviceDialogProps: (props: IAddDeviceDialogProps) => void;
  setDeletionDialogProps: (props: IDeletionDialogProps) => void;
}

const DialogContext = createContext<IDialogContext>({
  setLinkDialogProps: () => null,
  setStackDialogProps: () => null,
  setChannelDialogProps: () => null,
  setPlatformDialogProps: () => null,
  setDeviceDialogProps: () => null,
  setAddDeviceDialogProps: () => null,
  setDeletionDialogProps: () => null,
});

const useOverallDialogContext = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialogContext must be used within a DialogProvider");
  }
  return context;
};

export interface IDialogProviderProps {
  children: React.ReactNode;
}

const DialogProvider = (props: IDialogProviderProps) => {
  const [linkDialogProps, setLinkDialogProps] = useState<
    ILinkDialogProps | undefined
  >(undefined);
  const [stackDialogProps, setStackDialogProps] = useState<
    IStackDialogProps | undefined
  >(undefined);
  const [platformDialogProps, setPlatformDialogProps] = useState<
    IPlatformDialogProps | undefined
  >(undefined);
  const [channelDialogProps, setChannelDialogProps] = useState<
    IChannelDialogProps | undefined
  >(undefined);
  const [deviceDialogProps, setDeviceDialogProps] = useState<
    IDeviceDialogProps | undefined
  >(undefined);
  const [addDeviceDialogProps, setAddDeviceDialogProps] = useState<
    IAddDeviceDialogProps | undefined
  >(undefined);
  const [deletionDialogProps, setDeletionDialogProps] = useState<
    IDeletionDialogProps | undefined
  >(undefined);

  return (
    <>
      <DialogContext.Provider
        value={{
          setLinkDialogProps,
          setPlatformDialogProps,
          setStackDialogProps,
          setChannelDialogProps,
          setDeviceDialogProps,
          setAddDeviceDialogProps,
          setDeletionDialogProps,
        }}
      >
        {props.children}
      </DialogContext.Provider>
      {linkDialogProps ? (
        <LinkDialog
          {...linkDialogProps}
          closeCallback={() => {
            setLinkDialogProps(undefined);
          }}
        />
      ) : null}
      {platformDialogProps ? (
        <PlatformDialog
          {...platformDialogProps}
          closeCallback={() => {
            setPlatformDialogProps(undefined);
          }}
        />
      ) : null}
      {stackDialogProps ? (
        <StackDialog
          {...stackDialogProps}
          closeCallback={() => {
            setStackDialogProps(undefined);
          }}
        />
      ) : null}
      {channelDialogProps ? (
        <ChannelDialog
          {...channelDialogProps}
          closeCallback={() => {
            setChannelDialogProps(undefined);
          }}
        />
      ) : null}
      {deviceDialogProps ? (
        <DeviceDialog
          {...deviceDialogProps}
          closeCallback={() => {
            setDeviceDialogProps(undefined);
          }}
        />
      ) : null}
      {addDeviceDialogProps ? (
        <AddDeviceDialog
          {...addDeviceDialogProps}
          closeCallback={() => {
            setAddDeviceDialogProps(undefined);
          }}
        />
      ) : null}
      {deletionDialogProps ? (
        <DeletionDialog
          {...deletionDialogProps}
          closeCallback={() => {
            setDeletionDialogProps(undefined);
          }}
        />
      ) : null}
    </>
  );
};

export { DialogProvider, useOverallDialogContext };
