import { useEffect, useState } from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "usehooks-ts";

const GOOGLE_CLIENT_ID =
  "111899680723-kevqjb6ri7d4ll4o1b62rsod6ghv4m5d.apps.googleusercontent.com";

export default function Login() {
  const userCtx = useUserContext();
  const { loginWithRedirect, user, isLoading } = useAuth0();
  // const [onGoingLogin, setOngoingLogin] = useState<boolean>(false);

  // const [accessToken, setAccessToken] = useLocalStorage<string | undefined>(
  //   "accessToken",
  //   undefined
  // );

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);
  // useEffect(() => {
  //   !user && !isLoading && loginWithRedirect();
  //   // setOngoingLogin(true);
  // }, [user, isLoading]);

  // const mc = new ManagementClient({
  //   domain: "dev-qhvzm1lw4rhymnel.auth0.com",
  //   clientId: "mMcsqWilXtQD18Z8gGEudF3g0u0UA6Ro",
  //   audience: `https://dev-qhvzm1lw4rhymnel.auth0.com/api/v2/`,
  //   telemetry: false,
  // });

  // mc.getConnection({ id: "google-oauth2" }).then((x) => console.log("aaaa", x));

  return (
    <></>
    // <UrsorDialog
    //   title="Welcome to ASTRO"
    //   subtitle={[
    //     "Please log in.",
    //     "If you do not currently have an account with ASTRO,",
    //     "one will be created for you.",
    //   ]}
    //   open={true}
    //   button={
    //     <Stack spacing="7px" id="HEY">
    //       <UrsorButton
    //         onClick={() => {
    //           loginWithRedirect();
    //         }}
    //         startIcon={<MailIcon width="20px" height="20px" />}
    //       >
    //         Sign in with email
    //       </UrsorButton>
    //       {/* <UrsorButton
    //         onClick={() => {
    //           // loginWithPopup({
    //           //   authorizationParams: {
    //           //     connection: "google-oauth2",
    //           //   },
    //           // });
    //           null; //loginWithRedirect();
    //         }}
    //         variant="secondary"
    //         startIcon={
    //           <Avatar
    //             src={
    //               "https://ursorassets.s3.eu-west-1.amazonaws.com/img/icons/googleIcon.png"
    //             }
    //             sx={{ width: "20px", height: "20px" }}
    //           />
    //         }
    //       >
    //         Sign in with Google
    //       </UrsorButton> */}
    //     </Stack>
    //   }
    //   noBackdrop
    //   longFadeIn
    //   noCloseButton
    // >
    //   <Stack
    //     height="100%"
    //     minHeight={0}
    //     justifyContent="space-between"
    //     alignItems="center"
    //     spacing="5px"
    //   >
    //     <Box
    //       component="img"
    //       height="86%"
    //       minHeight={0}
    //       maxHeight="100%"
    //       width="fit-content"
    //       src="https://ursorassets.s3.eu-west-1.amazonaws.com/img/ursorOnboardingImage.png"
    //     />
    //     <Typography variant="small">
    //       By signing up you agree to our&nbsp;
    //       <Link
    //         href={"https://www.astrosafe.co/terms-and-conditions"}
    //         target="_blank"
    //         underline="none"
    //         sx={{ color: PALETTE.primary.indigo, fontWeight: 500 }}
    //       >
    //         Terms
    //       </Link>
    //       &nbsp;and our&nbsp;
    //       <Link
    //         href={"https://www.astrosafe.co/app/privacy-policy"}
    //         target="_blank"
    //         underline="none"
    //         sx={{ color: PALETTE.primary.indigo, fontWeight: 500 }}
    //       >
    //         Privacy policy.
    //       </Link>
    //     </Typography>
    //   </Stack>
    // </UrsorDialog>
  );
}
