import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import {
  BACKDROP_STYLE,
  BORDER_RADIUS,
  DEFAULT_FADEIN_DURATION,
} from "../../components/UrsorDialog";
import Typography from "../../components/Typography";
import { Stack, alpha } from "@mui/system";
import UrsorTable, {
  IUrsorTableColumn,
  IUrsorTableRow,
} from "../../components/UrsorTable";
import { useUserDataContext } from "../../contexts/UserDataContext";
import { ContentAgeMode, IDevice } from "../AdminPage/AdminPage";
import { ReactComponent as InfoIcon } from "../../images/icons/InfoIcon.svg";
import { ReactComponent as PlusIcon } from "../../images/icons/PlusIcon.svg";
import { ReactComponent as X } from "../../images/icons/x.svg";
import ContentAgeInfoDialog from "./ContentAgeInfoDialog";
import UrsorButton from "../../components/buttons/UrsorButton";
import { useOverallDialogContext } from "../../contexts/DialogContext";
import ContentAgeSelectionPopup from "./ContentAgeSelectionPopup";
import ApiController from "../../controllers/ApiController";
import ContentAgeModeIcon from "./ContentAgeModeIcon";
import _ from "lodash";
import { PALETTE } from "../../palette";
import ChromeIllustration from "../../images/ChromeDeviceIllustrationSmall.png";
import iPadIllustration from "../../images/iPadIllustrationSmall.png";
import GraphIllustration from "../../images/GraphIllustration.svg";
import AddDeviceDialog from "../DevicesPage/AddDeviceDialog";

const WIDTH = "850px";
const MIN_HEIGHT = "470px";
const ILLUST_SIZE = "14px";

const CONTENT_AGE_MODE_YEARS_TEXT: Record<ContentAgeMode, string> = {
  trailblazer: "Years 4-5",
  explorer: "Years 6-9",
  adventurer: "Years 10+",
};
export const DEFAULT_CONTENT_AGE_MODE: ContentAgeMode = "explorer";

interface IDeviceFiltersTableRowItems {
  name: string;
  mode: IDevice["contentAgeMode"];
}

export interface IDeviceFiltersDialogProps {
  open: boolean;
  closeCallback: () => void;
}

export default function DeviceFiltersDialog(props: IDeviceFiltersDialogProps) {
  const dataCtx = useUserDataContext();
  const dialogCtx = useOverallDialogContext();

  const [contentAgePopupOpen, setContentAgePopupOpen] =
    useState<boolean>(false);

  const TABLE_COLUMNS: IUrsorTableColumn[] = [
    {
      name: "name",
      displayName: "Device name",
      sortable: true,
      getAvatar: (id) => {
        const deviceType = dataCtx.devices?.find((d) => d.id === id)?.type;
        return (
          <Stack
            width="20px"
            minWidth="20px"
            height="20px"
            bgcolor={PALETTE.secondary.grey[2]}
            borderRadius="3px"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              component="img"
              width={deviceType === "chrome" ? "84%" : "74%"}
              src={
                deviceType === "chrome" ? ChromeIllustration : iPadIllustration
              }
            />
          </Stack>
        );
      },
    },
    {
      name: "mode",
      displayName: "Content age",
      headerButton: (
        <Stack
          onClick={() => setContentAgePopupOpen(true)}
          sx={{
            cursor: "pointer",
            transition: "0.2s",
            "&:hover": { opacity: 0.6 },
          }}
        >
          <InfoIcon width="18px" height="18px" />
        </Stack>
      ),
      itemDisplay: (mode: ContentAgeMode) => (
        <Stack direction="row" spacing="9px" alignItems="center">
          <ContentAgeModeIcon
            mode={mode || DEFAULT_CONTENT_AGE_MODE}
            size={ILLUST_SIZE}
          />
          <Typography>
            {_.capitalize(mode || DEFAULT_CONTENT_AGE_MODE)}
          </Typography>
          <Typography color={alpha(PALETTE.secondary.grey[4], 0.8)}>
            {`${CONTENT_AGE_MODE_YEARS_TEXT[mode || DEFAULT_CONTENT_AGE_MODE]}`}
          </Typography>
        </Stack>
      ),
      getExtraElement: (id) => (
        <ContentAgeSelectionPopup
          contentAgeMode={
            rows.find((row) => row.id === id)?.items.mode ??
            DEFAULT_CONTENT_AGE_MODE
          }
          callback={(mode) =>
            ApiController.updateDeviceAge(id, mode).then(
              dataCtx.refreshDevicesAndSessions
            )
          }
        />
      ),
      link: true,
    },
  ];

  const [rows, setRows] = useState<
    IUrsorTableRow<IDeviceFiltersTableRowItems>[]
  >([]);
  useEffect(() => {
    const deviceRows: IUrsorTableRow<IDeviceFiltersTableRowItems>[] =
      dataCtx.devices
        ?.filter((d) => !d.connected || d.connected === "approved")
        ?.map((d) => ({
          id: d.id,
          tags: [],
          disabled: false,
          items: {
            name: d.name,
            mode: d.contentAgeMode,
          },
        })) || [];
    setRows(deviceRows);
  }, [dataCtx.devices]);

  const [sortedColumn, setSortedColumn] = useState<string>("updatedAt");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const [addDeviceDialogOpen, setAddDeviceDialogOpen] =
    React.useState<boolean>(false);
  return (
    <>
      <Dialog
        transitionDuration={DEFAULT_FADEIN_DURATION}
        open={props.open}
        onClose={props.closeCallback}
        PaperProps={{
          style: {
            width: WIDTH,
            maxWidth: WIDTH,
            height: "74%",
            minHeight: MIN_HEIGHT,
            borderRadius: BORDER_RADIUS,
          },
        }}
        sx={{
          p: "10px",
          ".MuiBackdrop-root": BACKDROP_STYLE,
        }}
      >
        <Stack
          position="absolute"
          right="30px"
          top="30px"
          sx={{
            cursor: "pointer",
            "&:hover": { opacity: 0.6 },
            transition: "0.2s",
          }}
          onClick={props.closeCallback}
          zIndex={2}
        >
          <X height="26px" width="26px" />
        </Stack>
        <Stack flex={1} p="32px" spacing="24px" overflow="hidden">
          <Typography variant="large" bold>
            Customize Device Filters
          </Typography>
          <Stack overflow="scroll">
            <UrsorTable
              columns={TABLE_COLUMNS}
              rows={rows}
              defaultSortedByColumn="creationDate"
              defaultSortedAscending
              selectedSort={sortedColumn}
              ascending={sortDirection === "asc"}
              sortSelectionCallback={(columnId) => {
                if (columnId === sortedColumn) {
                  setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                } else {
                  setSortedColumn(columnId);
                  setSortDirection("asc");
                }
              }}
              getEndButton={(id) => (
                <UrsorButton
                  size="small"
                  onClick={() =>
                    dialogCtx.setDeviceDialogProps({
                      open: true,
                      closeCallback: () => null,
                      deviceId: id,
                    })
                  }
                >
                  Go to Device
                </UrsorButton>
              )}
              noHeaderGradient
            />
          </Stack>
          {rows.length === 0 ? (
            <Stack flex={1} justifyContent="center" alignItems="center">
              <Stack position="relative">
                <Stack sx={{ opacity: 0.3, filter: "grayscale(1)" }}>
                  <img height="240px" width="250px" src={GraphIllustration} />
                </Stack>
                <Stack
                  position="absolute"
                  bottom="-2px"
                  width="100%"
                  alignItems="center"
                >
                  <UrsorButton
                    endIcon={<PlusIcon height="16px" width="16px" />}
                    onClick={() =>
                      dialogCtx.setAddDeviceDialogProps({
                        open: true,
                        closeCallback: () => null,
                      })
                    }
                    mode="dark"
                    variant="tertiary"
                  >
                    Add Device
                  </UrsorButton>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Dialog>
      <ContentAgeInfoDialog
        open={contentAgePopupOpen}
        closeCallback={() => setContentAgePopupOpen(false)}
      />
      <AddDeviceDialog
        open={addDeviceDialogOpen}
        closeCallback={() => {
          setAddDeviceDialogOpen(false);
        }}
      />
    </>
  );
}
