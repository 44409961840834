import React, { Dialog } from "@mui/material";
import {
  BACKDROP_STYLE,
  DEFAULT_FADEIN_DURATION,
} from "../../components/UrsorDialog";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import UrsorTable, {
  IUrsorTableColumn,
  IUrsorTableRow,
} from "../../components/UrsorTable";
import { ReactComponent as TrashcanIcon } from "../../images/icons/TrashcanIcon.svg";
import { ReactComponent as PencilIcon } from "../../images/icons/PencilIcon.svg";
import { ReactComponent as X } from "../../images/icons/x.svg";
import Typography from "../../components/Typography";
import moment from "moment";
import { PALETTE } from "../../palette";
import _ from "lodash";
import UrsorFadeIn from "../../components/UrsorFadeIn";
import { ILink } from "./dialogs/LinkDialog";
import ApiController from "../../controllers/ApiController";
import { useUserContext } from "../../contexts/UserContext";
import { useUserDataContext } from "../../contexts/UserDataContext";
import { useOverallDialogContext } from "../../contexts/DialogContext";
import NotificationContext from "../../contexts/NotificationContext";
import UrsorActionButton from "../../components/UrsorActionButton";
import { IFilterDomain } from "./LinksPage";
import { getAbsoluteUrl } from "../LibraryPage/components/LinkCard";
import LinkDeletionDialog from "./LinkDeletionDialog";

const WIDTH = "80%";
const PADDING = "32px";

export interface IDomainLinksDialog {
  domain: IFilterDomain;
  open: boolean;
  closeCallback: () => void;
  deletionCallback: () => void;
}

interface IDomainLinksDialogTableRowItems {
  title: string;
  url: string;
  creatorId?: string;
  createdAt: string;
}

export default function DomainLinksDialog(props: IDomainLinksDialog) {
  const userCtx = useUserContext();
  const dataCtx = useUserDataContext();
  const dialogCtx = useOverallDialogContext();
  const [links, setLinks] = useState<ILink[]>([]);

  const loadLinks = () =>
    ApiController.getDomainLinks(
      userCtx.userDetails?.schoolId,
      props.domain.domain
    ).then((links) => setLinks(links));

  useEffect(() => {
    loadLinks();
  }, [userCtx.userDetails?.schoolId, props.domain]);
  const [rows, setRows] = useState<
    IUrsorTableRow<IDomainLinksDialogTableRowItems>[]
  >([]);
  const [filteredRows, setFilteredRows] = useState<
    IUrsorTableRow<IDomainLinksDialogTableRowItems>[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>("");
  useEffect(() => {
    setFilteredRows(
      rows.filter((row) =>
        searchValue
          ? [row.items.title, row.items.url.replace("www.", "")]
              .join("_")
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          : true
      )
    );
  }, [rows, searchValue]);

  const TABLE_COLUMNS: IUrsorTableColumn[] = [
    {
      name: "title",
      displayName: "Title",
      sortable: true,
      newTag: true,
    },
    {
      name: "url",
      displayName: "URL",
      sortable: true,
      link: true,
    },
    {
      name: "createdAt",
      displayName: "Added on",
      itemDisplay: (datetime) => moment(datetime).format("HH:mm, MM/DD/YY"),
    },
    {
      name: "creatorId",
      displayName: "Added by",
      itemDisplay: (id) =>
        dataCtx.teachers.find((t) => t.id === id)?.teacherName ?? "",
    },
    // {
    //   name: "accessLevel",
    //   displayName: "Access",
    //   itemDisplay: (level) => (
    //     <Typography
    //       noWrap
    //       color={
    //         level === "all" ? PALETTE.font.dark : PALETTE.secondary.grey[4]
    //       }
    //     >
    //       {level === "all" ? "Full site" : "Single page"}
    //     </Typography>
    //   ),
    // },
    // {
    //   name: "creationDate",
    //   displayName: "Creation",
    //   sortable: true,
    //   itemDisplay: (date) => (
    //     <Typography variant="small" noWrap>
    //       {moment(date).format("YYYY-MM-DD")}
    //     </Typography>
    //   ),
    // },
  ];

  useEffect(() => {
    (async () => {
      const linkRows: IUrsorTableRow<IDomainLinksDialogTableRowItems>[] =
        links?.map((l) => ({
          id: l.id,
          items: {
            title: l.title,
            url: l.url,
            creatorId: l.creatorId,
            createdAt: l.createdAt,
          },
          tags: [],
          disabled: false,
          url: l.url,
          newTagDatetime: l.createdAt,
        })) || [];
      setRows(
        _.reverse([
          ..._.sortBy(linkRows, (row) => new Date(row.items.createdAt)),
        ])
      );
    })();
  }, [links]);

  const [sortedColumn, setSortedColumn] = useState<string>("createdAt");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortedRows, setSortedRows] = useState<
    IUrsorTableRow<IDomainLinksDialogTableRowItems>[]
  >([]);
  useEffect(() => {
    const sorted = _.sortBy(filteredRows, (row) =>
      //@ts-ignore
      row.items[sortedColumn].toLowerCase()
    );
    setSortedRows(sortDirection === "asc" ? _.reverse(sorted.slice()) : sorted);
  }, [filteredRows, sortDirection, sortedColumn]);

  const notificationCtx = useContext(NotificationContext);

  const actions = [
    // {
    //   icon: PencilIcon,
    //   text: "Edit",
    //   kallback: () =>
    //     dialogCtx.setLinkDialogProps({
    //       open: true,
    //       closeCallback: () => null,
    //       updateCallback: dataCtx.refreshLinks,
    //       link: dataCtx.links?.find((l) => l.id === props.domain.id),
    //     }),
    // },
    {
      icon: TrashcanIcon,
      text: "Delete",
      kallback: () =>
        dialogCtx.setDeletionDialogProps({
          category: "Domain",
          title: props.domain.title ?? "Title",
          open: true,
          closeCallback: () => null,
          deletionCallback: () =>
            ApiController.deleteDomain(
              userCtx.userDetails?.schoolId,
              props.domain.id
            )
              .then(props.deletionCallback)
              .then(() => notificationCtx.negativeSuccess("Domain deleted.")),
        }),
      color: PALETTE.system.red,
    },
  ];

  const [deletionDialogId, setDeletionDialogId] = useState<string | undefined>(
    undefined
  );

  return (
    <>
      <Dialog
        transitionDuration={DEFAULT_FADEIN_DURATION}
        open={props.open}
        onClose={props.closeCallback}
        PaperProps={{
          style: {
            width: WIDTH,
            maxWidth: WIDTH,
            maxHeight: "80%",
            height: "80%",
            borderRadius: "24px",
          },
        }}
        sx={{
          py: "10px",
          ".MuiBackdrop-root": BACKDROP_STYLE,
        }}
      >
        <Stack
          flex={1}
          borderRadius="24px"
          bgcolor="rgb(255,255,255)"
          p={PADDING}
          spacing="24px"
          boxSizing="border-box"
        >
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Stack direction="row" spacing="12px">
              {props.domain.logoUrl ? (
                <Stack
                  borderRadius="12px"
                  width="56px"
                  height="56px"
                  minWidth="20px"
                  minHeight="20px"
                  boxShadow="0 0 30px rgba(0,0,0,0.08)"
                  sx={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${props.domain.logoUrl})`,
                  }}
                />
              ) : null}
              <Stack pt="2px">
                <Typography variant="large" bold>
                  {props.domain.title || "Untitled Domain"}
                </Typography>
                <a
                  target="_blank"
                  href={getAbsoluteUrl(props.domain.domain)}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                  }}
                >
                  <Typography
                    color={PALETTE.secondary.grey[4]}
                    bold
                    sx={{
                      "&:hover": { opacity: 0.7 },
                      transition: "0.2s",
                    }}
                  >
                    {props.domain.domain}
                  </Typography>
                </a>
              </Stack>
            </Stack>
            <Stack direction="row" spacing="10px" position="relative">
              <Stack
                width="42px"
                height="42px"
                justifyContent="center"
                alignItems="center"
                borderRadius="100%"
                sx={{
                  cursor: "pointer",
                  "&:hover": { opacity: 0.6 },
                  transition: "0.2s",
                }}
                position="absolute"
                right="42px"
                top="-6px"
              >
                <UrsorActionButton
                  background="rgb(255,255,255)"
                  size="42px"
                  actions={actions}
                  large
                />
              </Stack>
              <Stack
                sx={{
                  cursor: "pointer",
                  "&:hover": { opacity: 0.6 },
                  transition: "0.2s",
                }}
                onClick={props.closeCallback}
              >
                <X height="30px" width="30px" />
              </Stack>
            </Stack>
          </Stack>
          {sortedRows.length > 0 ? (
            <Stack flex={1}>
              <UrsorFadeIn duration={800}>
                <UrsorTable
                  columns={TABLE_COLUMNS}
                  rows={sortedRows}
                  defaultSortedByColumn="creationDate"
                  defaultSortedAscending
                  selectedSort={sortedColumn}
                  ascending={sortDirection === "asc"}
                  sortSelectionCallback={(columnId) => {
                    if (columnId === sortedColumn) {
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    } else {
                      setSortedColumn(columnId);
                      setSortDirection("asc");
                    }
                  }}
                  getActionButtonItems={(id) => [
                    {
                      icon: PencilIcon,
                      text: "Edit",
                      kallback: () =>
                        dialogCtx.setLinkDialogProps({
                          open: true,
                          closeCallback: () => null,
                          link: links.find((l) => l.id === id),
                          updateCallback: loadLinks,
                        }),
                    },
                    {
                      icon: TrashcanIcon,
                      text: "Delete",
                      kallback: () => setDeletionDialogId(id),
                      // dialogCtx.setDeletionDialogProps({
                      //   category: "Link",
                      //   title: links.find((l) => l.id === id)?.title ?? "",
                      //   open: true,
                      //   deletionCallback: () =>
                      //     ApiController.deleteLink(id)
                      //       .then(dataCtx.refreshLinks)
                      //       .then(dataCtx.refreshStacks)
                      //       .then(dataCtx.refreshChannels)
                      //       .then(loadLinks)
                      //       .then(props.deletionCallback)
                      //       .then(() =>
                      //         notificationCtx.negativeSuccess("Link deleted")
                      //       ),
                      //   closeCallback: () => null,
                      // }),
                      color: PALETTE.system.red,
                    },
                  ]}
                  noHeaderGradient
                />
              </UrsorFadeIn>
            </Stack>
          ) : null}
        </Stack>
      </Dialog>
      {deletionDialogId ? (
        <LinkDeletionDialog
          open={true}
          links={links.filter((l) => l.id === deletionDialogId)}
          singleLink
          closeCallback={() => setDeletionDialogId(undefined)}
          deletionCallback={() =>
            ApiController.deleteLink(deletionDialogId)
              .then(dataCtx.refreshLinks)
              .then(dataCtx.refreshStacks)
              .then(dataCtx.refreshChannels)
              .then(loadLinks)
              .then(props.deletionCallback)
              .then(() => notificationCtx.negativeSuccess("Link deleted"))
          }
        />
      ) : null}
    </>
  );
}
