import React, { useEffect, useState } from "react";
import { Box, Stack, Switch } from "@mui/material";
import UrsorDialog from "../../components/UrsorDialog";
import ChromeDeviceIllustration from "../../images/ChromeDeviceIllustration.png";
import iPadIllustration from "../../images/iPadIllustration.png";
import { IDevice } from "../AdminPage/AdminPage";
import ApiController from "../../controllers/ApiController";
import { useUserContext } from "../../contexts/UserContext";
import { useUserDataContext } from "../../contexts/UserDataContext";
import { PALETTE } from "../../palette";
import NotificationContext from "../../contexts/NotificationContext";
import Typography from "../../components/Typography";

export const SWITCH_LENGTH = 61; // px
export const SWITCH_HEIGHT = 33; // px
export const SWITCH_THUMB_MARGIN = 3;
export const SWITCH_THUMB_SIZE = SWITCH_HEIGHT - 2 * SWITCH_THUMB_MARGIN;
export const SWITCH_MOVEMENT_DISTANCE =
  SWITCH_LENGTH - SWITCH_THUMB_SIZE - 2 * SWITCH_THUMB_MARGIN;

export interface IDeviceRemovalDialogProps {
  open: boolean;
  device: IDevice;
  onCloseCallback: () => void;
  callback?: () => void;
}

export default function DeviceRemovalDialog(props: IDeviceRemovalDialogProps) {
  const userCtx = useUserContext();
  const dataCtx = useUserDataContext();
  const notificationCtx = React.useContext(NotificationContext);
  return (
    <UrsorDialog
      title={"Remove Device"}
      subtitle={[
        "This device will be disconnected from your school and it will no longer be",
        "connected to your safe internet. Are you sure you want to proceed?",
      ]}
      supertitle="Remove Device"
      open={props.open}
      button={{
        text: "Remove",
        color: PALETTE.system.red,
        callback: () => {
          props.onCloseCallback();
          props.callback?.();
          ApiController.rejectDevice(props.device.id, userCtx.userDetails?.id)
            .then(dataCtx.refreshDevicesAndSessions)
            .then(() => notificationCtx.negativeSuccess("Removed Device"));
        },
      }}
      onCloseCallback={props.onCloseCallback}
      backButtonCallback={props.onCloseCallback}
    >
      <Stack
        spacing="12px"
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        <img
          height="140px"
          width="230px"
          src={
            props.device.type === "chrome"
              ? ChromeDeviceIllustration
              : iPadIllustration
          }
        />
        <Typography variant="h5">{props.device.name}</Typography>
      </Stack>
    </UrsorDialog>
  );
}
