import React, { useContext, createContext, useEffect, useState } from "react";
import { useUserContext } from "./UserContext";
import ApiController from "../controllers/ApiController";
import UrsorDialog from "../components/UrsorDialog";

export type BuildEnv = "dev" | "staging" | "prod";

type UrsorGoogleClassroomFeature =
  //| "all"
  "importClass" | "exportClass" | "importLesson" | "importStudents";
const FEATURE_DETAILS: Record<
  UrsorGoogleClassroomFeature,
  { displayName: string; scopes: string[] }
> = {
  //all: { displayName: "all", scopes: [] },
  importClass: {
    displayName: "importing a class",
    scopes: [
      "https://www.googleapis.com/auth/classroom.courses",
      "https://www.googleapis.com/auth/classroom.student-submissions.students.readonly",
      "https://www.googleapis.com/auth/classroom.student-submissions.me.readonly",
      "https://www.googleapis.com/auth/classroom.profile.emails",
      "https://www.googleapis.com/auth/classroom.profile.photos",
      "https://www.googleapis.com/auth/classroom.rosters",
      "https://www.googleapis.com/auth/classroom.courseworkmaterials",
      "https://www.googleapis.com/auth/classroom.coursework.students",
      "https://www.googleapis.com/auth/classroom.coursework.me",
      // "https://www.googleapis.com/auth/forms.body",
      // "https://www.googleapis.com/auth/drive",
      // "https://www.googleapis.com/auth/drive.file",
      // "https://www.googleapis.com/auth/drive.metadata",
      // "https://www.googleapis.com/auth/drive.appdata",
    ],
  },
  exportClass: {
    displayName: "exporting a class",
    scopes: ["https://www.googleapis.com/auth/classroom.courses"],
  },
  importLesson: {
    displayName: "importing a lesson",
    scopes: [
      "https://www.googleapis.com/auth/classroom.courses",
      "https://www.googleapis.com/auth/classroom.student-submissions.students.readonly",
      "https://www.googleapis.com/auth/classroom.student-submissions.me.readonly",
      "https://www.googleapis.com/auth/classroom.courseworkmaterials",
      "https://www.googleapis.com/auth/classroom.coursework.students",
    ],
  },
  importStudents: {
    displayName: "importing students",
    scopes: [
      "https://www.googleapis.com/auth/classroom.courses",
      "https://www.googleapis.com/auth/classroom.profile.emails",
      "https://www.googleapis.com/auth/classroom.profile.photos",
      "https://www.googleapis.com/auth/classroom.rosters",
    ],
  },
};

export interface IGoogleClassroomAPIContext {
  setGoogleAuthWarningCallbackAndFeature: (
    feature: UrsorGoogleClassroomFeature,
    callback: () => void
  ) => void;
  requestAccessToFeatureScopes: (
    feature: UrsorGoogleClassroomFeature,
    callback: () => void
  ) => void;
  requestAccessToAll: (callback: () => void) => void;
}

const GoogleClassroomAPIContext = createContext<IGoogleClassroomAPIContext>({
  setGoogleAuthWarningCallbackAndFeature: () => null,
  requestAccessToFeatureScopes: () => null,
  requestAccessToAll: () => null,
});

const useGoogleClassroomAPIContext = () => {
  const context = useContext(GoogleClassroomAPIContext);
  if (context === undefined) {
    throw new Error(
      "useGoogleClassroomAPIContext must be used within a GoogleClassroomAPIContextProvider"
    );
  }
  return context;
};

interface IGoogleClassroomAPIProviderProps {
  children: React.ReactNode;
}

/* much of the logic below is from https://docs.amplify.aws/lib/auth/advanced/q/platform/js/#google-sign-in-react */
const GoogleClassroomAPIProvider = (
  props: IGoogleClassroomAPIProviderProps
) => {
  const userCtx = useUserContext();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [googleAuthWarningFeature, setGoogleAuthWarningFeature] = useState<
    undefined | UrsorGoogleClassroomFeature
  >(undefined);
  const [googleAuthWarningCallback, setGoogleAuthWarningCallback] = useState<
    undefined | (() => void)
  >(undefined);

  useEffect(
    () =>
      setDialogOpen(!!googleAuthWarningCallback && !!googleAuthWarningFeature),
    [googleAuthWarningCallback, googleAuthWarningFeature]
  );

  useEffect(() => {
    !window?.google?.accounts && createScript();
  }, [userCtx.userDetails]);

  const createScript = () => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };

  const requestScopeAccess = (
    feature?: UrsorGoogleClassroomFeature,
    callback?: () => void
  ) => {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id:
        "111899680723-kevqjb6ri7d4ll4o1b62rsod6ghv4m5d.apps.googleusercontent.com",
      scope:
        FEATURE_DETAILS[googleAuthWarningFeature! || feature].scopes.join(" "),
      hint: userCtx.userDetails?.email,
      callback: (tokenResponse) => {
        if (!tokenResponse.code) {
          return; // this runs if the user has not approved any scopes
        }
        ApiController.loadRefreshToken(
          tokenResponse.code,
          userCtx.userDetails?.id
        )
          .then(() =>
            setTimeout(() => {
              userCtx.load(userCtx.userDetails?.email);
              ApiController.getGCScopes(userCtx.userDetails?.id).then(
                (scopes) => {
                  if (
                    getAreScopesFulfilled(
                      googleAuthWarningFeature! || feature,
                      scopes
                    )
                  ) {
                    (googleAuthWarningCallback || callback)?.(); // this will not run if the user has not approved all the necessary scopes
                  }
                }
              );
            }, 2000)
          )
          .finally(() => {
            setGoogleAuthWarningCallback(undefined);
            setGoogleAuthWarningFeature(undefined);
          });
      },
      error_callback: (error) => console.log(error),
    });
    client.requestCode();
  };

  const requestAccessToAllScopes = (callback: () => void) => {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id:
        "111899680723-kevqjb6ri7d4ll4o1b62rsod6ghv4m5d.apps.googleusercontent.com",
      scope: Object.values(FEATURE_DETAILS)
        .map((x) => x.scopes.join(" "))
        .join(" "),
      hint: userCtx.userDetails!.email,
      callback: (tokenResponse) => {
        if (!tokenResponse.code) {
          return; // this runs if the user has not approved any scopes
        }
        ApiController.loadRefreshToken(
          tokenResponse.code,
          userCtx.userDetails?.id
        )
          //.then(() => setTimeout(userCtx.load, 2000))
          .finally(callback);
        // .then(() =>
        //   setTimeout(() => {
        //     userCtx.load();
        //     ApiController.getGCScopes(userCtx.userDetails?.id).then(
        //       (scopes) => {
        //         if (
        //           getAreScopesFulfilled(googleAuthWarningFeature!, scopes)
        //         ) {
        //           googleAuthWarningCallback!(); // this will not run if the user has not approved all the necessary scopes
        //         }
        //       }
        //     );
        //   }, 2000)
        // )
        // .finally(() => {
        //   setGoogleAuthWarningCallback(undefined);
        //   setGoogleAuthWarningFeature(undefined);
        // });
      },
      error_callback: (error) => console.log(error),
    });
    client.requestCode();
  };

  const getAreScopesFulfilled = (
    feature: UrsorGoogleClassroomFeature,
    scopes: string[]
  ) => FEATURE_DETAILS[feature].scopes.every((s) => scopes.includes(s));

  return (
    <GoogleClassroomAPIContext.Provider
      value={{
        requestAccessToFeatureScopes: (feature, callback) =>
          ApiController.getGCScopes(userCtx.userDetails?.id).then((scopes) => {
            if (getAreScopesFulfilled(feature, scopes)) {
              callback();
            } else {
              //setGoogleAuthWarningCallback(() => callback);
              requestScopeAccess(feature, callback);
            }
          }),
        setGoogleAuthWarningCallbackAndFeature: (feature, callback) => {
          ApiController.getGCScopes(userCtx.userDetails?.id).then((scopes) => {
            if (getAreScopesFulfilled(feature, scopes)) {
              callback();
            } else {
              setGoogleAuthWarningCallback(() => callback);
              setGoogleAuthWarningFeature(feature);
            }
          });
          //setGoogleAuthWarningFeature(feature);
          //setGoogleAuthWarningCallback(() => callback);
        },
        requestAccessToAll: (callback) => requestAccessToAllScopes(callback),
      }}
    >
      {props.children}
      {dialogOpen ? (
        <UrsorDialog
          title="Access to Google Classroom"
          subtitle={[
            "To import a class from Google Classroom you need to sign in and provide",
            "permission for ASTRO to access this data.",
          ]}
          open={true}
          onCloseCallback={() => {
            setGoogleAuthWarningCallback(undefined);
            setGoogleAuthWarningFeature(undefined);
          }}
          googleButton={{
            text: "Continue",
            callback: async () => {
              requestScopeAccess();
              setDialogOpen(false);
            },
          }}
        />
      ) : null}
    </GoogleClassroomAPIContext.Provider>
  );
};

export { GoogleClassroomAPIProvider, useGoogleClassroomAPIContext };
