import React, { useCallback, useContext, useEffect, useState } from "react";
import PageLayout, { SIDEBAR_Y_MARGIN } from "../../components/PageLayout";
import { ReactComponent as TrashcanIcon } from "../../images/icons/TrashcanIcon.svg";
import { ReactComponent as LinkIcon } from "../../images/icons/LinkIcon.svg";
import { ReactComponent as PlusIcon } from "../../images/icons/PlusIcon.svg";
import { ReactComponent as FolderIcon } from "../../images/icons/FolderIcon.svg";
import { ReactComponent as PencilIcon } from "../../images/icons/PencilIcon.svg";
import { ReactComponent as ClippyIcon } from "../../images/icons/ClippyIcon.svg";
import { ReactComponent as PersonIcon } from "../../images/icons/PersonIcon.svg";
import GraphIllustration from "../../images/GraphIllustration.svg";
import { Stack, keyframes } from "@mui/system";
import { IStack, useUserDataContext } from "../../contexts/UserDataContext";
import { useOverallDialogContext } from "../../contexts/DialogContext";
import Typography from "../../components/Typography";
import { PALETTE, SecondaryColor } from "../../palette";
import UrsorFadeIn from "../../components/UrsorFadeIn";
import UrsorActionButton from "../../components/UrsorActionButton";
import { IActionPopupItem } from "../../components/ActionPopup";
import LinkDialog, { ILink } from "../BrowserPage/dialogs/LinkDialog";
import _ from "lodash";
import moment from "moment";
import StackDialog from "./StackDialog";
import StackViewDialog from "./StackViewDialog";
import LinkViewDialog from "./LinkViewDialog";
import { DEFAULT_CORNER_RADIUS } from "../../components/UrsorPopover";
import LinkCard from "./components/LinkCard";
import ApiController from "../../controllers/ApiController";
import { createPortal } from "react-dom";
import { EmptyStateIllustration } from "../DevicesPage/DevicesPage";
import UrsorButton from "../../components/buttons/UrsorButton";
import useColumnWidth from "./useColumnWidth";
import NotificationContext from "../../contexts/NotificationContext";
import MovingDialog from "./MovingDialog";
import { useUserContext } from "../../contexts/UserContext";
import StackCard from "./components/StackCard";
import AddDialog from "./AddDialog";

export const GRID_SPACING = "20px";

export const getAgoText: (datetime?: string) => {
  value?: number;
  text: string;
} = (datetime) => {
  const days = moment().diff(moment(datetime), "days");
  if (days > 0) {
    return days === 1
      ? { text: "Yesterday" }
      : { value: days, text: "days ago" };
  } else {
    const hours = moment().diff(moment(datetime), "hours");
    if (hours > 0) {
      return {
        value: hours,
        text: `hour${hours === 1 ? "" : "s"} ago`,
      };
    } else {
      const minutes = moment().diff(moment(datetime), "minutes");
      return minutes <= 1
        ? { text: "Now" }
        : { value: minutes, text: "minutes ago" };
    }
  }
};

export interface IChannel {
  id: string;
  creatorId?: string;
  schoolId: string;
  title: string;
  nLinks: number;
  nStacks: number;
  starter?: boolean;
  color: string;
}

const ChannelCard = (props: {
  channel: IChannel;
  nStacks: number;
  nLinks: number;
  selected: boolean;
  callback: () => void;
  editCallback: () => void;
  deleteCallback: () => void;
  hideCreator?: boolean;
}) => {
  const notificationCtx = useContext(NotificationContext);
  const dialogCtx = useOverallDialogContext();
  const dataCtx = useUserDataContext();
  const [hovering, setHovering] = useState<boolean>(false);
  const actions: IActionPopupItem[] = [
    {
      text: "Edit",
      icon: PencilIcon,
      kallback: props.editCallback,
    },
    {
      text: "Duplicate",
      icon: ClippyIcon,
      kallback: () =>
        ApiController.duplicateChannel(props.channel.id)
          .then(dataCtx.refreshStacks)
          .then(dataCtx.refreshChannels)
          .then(dataCtx.refreshLinks)
          .then(() => notificationCtx.success("Channel duplicated")),
    },
    {
      text: "Delete",
      icon: TrashcanIcon,
      kallback: () =>
        dialogCtx.setDeletionDialogProps({
          category: "Channel",
          title: props.channel.title,
          open: true,
          deletionCallback: () =>
            ApiController.deleteChannel(props.channel.id)
              .then(dataCtx.refreshChannels)
              .then(props.deleteCallback)
              .then(() => notificationCtx.negativeSuccess("Channel deleted")),
          closeCallback: () => null,
        }),
      color: PALETTE.system.red,
    },
  ];
  return (
    <Stack
      borderRadius="12px"
      px="12px"
      py="8px"
      bgcolor={
        props.selected ? PALETTE.secondary.purple[2] : "rgb(255,255,255)"
      }
      direction="row"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      height="63px"
      minHeight="63px"
    >
      <Stack
        sx={{
          cursor: "pointer",
          "&:hover": { opacity: 0.6 },
          transition: "0.2s",
        }}
        flex={1}
        onClick={props.callback}
        justifyContent="space-between"
        overflow="hidden"
      >
        <Stack direction="row" alignItems="center" spacing="8px">
          <Stack
            height="16px"
            width="16px"
            bgcolor={props.channel.color || PALETTE.secondary.green[2]}
            borderRadius="100%"
          />
          <Typography
            color={props.selected ? PALETTE.font.light : PALETTE.font.dark}
            bold
          >
            {props.channel.title}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing="12px"
          sx={{
            opacity: 0.6,
            svg: {
              path: {
                stroke: props.selected ? PALETTE.font.light : PALETTE.font.dark,
              },
            },
          }}
          minWidth="100%"
          maxWidth={0}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing="3px"
            // sx={{
            //   opacity: props.nStacks > 0 ? 1 : props.selected ? 0.7 : 0.55,
            // }}
          >
            <FolderIcon height="12px" width="12px" />
            <Typography
              variant="small"
              color={props.selected ? PALETTE.font.light : PALETTE.font.dark}
              bold
            >
              {props.channel.nStacks}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing="2px"
            sx={{
              //opacity: props.nLinks > 0 ? 1 : props.selected ? 0.7 : 0.55,
              svg: {
                path: {
                  fill: props.selected ? PALETTE.font.light : PALETTE.font.dark,
                },
              },
            }}
          >
            <LinkIcon height="12px" width="12px" />
            <Typography
              variant="small"
              color={props.selected ? PALETTE.font.light : PALETTE.font.dark}
              bold
            >
              {props.channel.nLinks}
            </Typography>
          </Stack>
          {!props.hideCreator ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing="2px"
              sx={{
                //opacity: props.nLinks > 0 ? 1 : props.selected ? 0.7 : 0.55,
                svg: {
                  path: {
                    fill: props.selected
                      ? PALETTE.font.light
                      : PALETTE.font.dark,
                  },
                },
              }}
              minWidth="100%"
              maxWidth={0}
            >
              <PersonIcon height="12px" width="12px" />
              <Typography
                variant="small"
                color={props.selected ? PALETTE.font.light : PALETTE.font.dark}
                bold
                noWrap
              >
                {
                  dataCtx.teachers.find((t) => t.id === props.channel.creatorId)
                    ?.teacherName
                }
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
      <Stack
        justifyContent="center"
        sx={{
          opacity: hovering ? 1 : 0,
          transition: "0.2s",
        }}
      >
        <UrsorActionButton
          size="16px"
          actions={actions}
          large
          buttonClickCallback={() => setHovering(false)}
          light={props.selected}
        />
      </Stack>
    </Stack>
  );
};

const ChannelsColumn = (props: {
  selected?: string;
  selectionCallback: (id: string) => void;
  deleteCallback: (id: string) => void;
  channels: IChannel[];
  my?: boolean;
  //editCallback: (id: string) => void;
}) => {
  const dataCtx = useUserDataContext();
  const dialogCtx = useOverallDialogContext();

  return (
    <Stack spacing="12px" width="285px" minWidth="285px" overflow="hidden">
      <Stack
        height="48px"
        minHeight="48px"
        width="100%"
        justifyContent="center"
        alignItems="center"
        borderRadius="12px"
        bgcolor={PALETTE.secondary.grey[2]}
        sx={{
          cursor: "pointer",
          "&:hover": { opacity: 0.6 },
          transition: "0.2s",
          svg: {
            path: {
              fill: PALETTE.secondary.grey[4],
            },
          },
        }}
        onClick={() =>
          dialogCtx.setChannelDialogProps({
            completionCallback: (id) => props.selectionCallback(id),
            open: true,
            closeCallback: () => null,
          })
        }
        spacing="8px"
        direction="row"
      >
        <Typography bold variant="small" color={PALETTE.secondary.grey[4]}>
          Add Lesson
        </Typography>
        <PlusIcon width="18px" height="18px" />
      </Stack>
      <Stack flex={1} spacing="12px" overflow="scroll" pb="96px">
        {_.reverse(props.channels.slice())?.map((c, i) => (
          <Stack key={c.id}>
            <UrsorFadeIn delay={i * 140} duration={800}>
              <ChannelCard
                key={c.id}
                channel={c}
                selected={c.id === props.selected}
                nStacks={
                  c.nStacks
                  // dataCtx.stacks?.filter((s) => s.channelId === c.id)
                  //   .length ?? 0
                }
                nLinks={
                  c.nLinks
                  // dataCtx.links?.filter((l) => l.channelId === c.id).length ??
                  // 0
                }
                callback={() => props.selectionCallback(c.id)}
                editCallback={() =>
                  dialogCtx.setChannelDialogProps({
                    channel: c,
                    open: true,
                    closeCallback: () => null,
                  })
                }
                deleteCallback={() => props.deleteCallback(c.id)}
                hideCreator={props.my}
              />
            </UrsorFadeIn>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export type AstroContent = "link" | "stack";

export default function LibraryPage() {
  const dataCtx = useUserDataContext();
  const userCtx = useUserContext();
  const dialogCtx = useOverallDialogContext();
  const notificationCtx = useContext(NotificationContext);

  const [channels, setChannels] = useState<IChannel[]>([]);

  const [my, setMy] = useState<boolean>(false);

  const [selectedChannelId, setSelectedChannelId] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    const filteredChannels =
      dataCtx.channels?.filter(
        (c) => !my || c.creatorId === userCtx.userDetails?.id
      ) || [];
    console.log(filteredChannels, selectedChannelId);
    if (filteredChannels.length > 0) {
      (!selectedChannelId ||
        !filteredChannels.map((c) => c.id).includes(selectedChannelId)) &&
        setSelectedChannelId(
          filteredChannels?.[filteredChannels.length - 1]?.id
        );
    } else {
      setSelectedChannelId(undefined);
    }
    setChannels(filteredChannels);
  }, [dataCtx.channels, userCtx.userDetails?.id, my]);

  const duplicateLink = (id: string) =>
    ApiController.duplicateLink(id, userCtx.userDetails?.id)
      .then(dataCtx.refreshLinks)
      .then(dataCtx.refreshStacks)
      .then(dataCtx.refreshChannels)
      .then(() => notificationCtx.success("Link duplicated"));

  const [cardColumns, setCardColumns] = useState<
    {
      type: AstroContent;
      details: ILink | IStack;
    }[][]
  >([]);

  // const [channelEditingDialogId, setChannelEditingDialogId] = useState<
  //   string | undefined
  // >(undefined);
  const [linkCreationDialogOpen, setLinkCreationDialogOpen] =
    useState<boolean>(false);
  const [stackCreationDialogOpen, setStackCreationDialogOpen] =
    useState<boolean>(false);
  const [linkEditingDialogId, setLinkEditingDialogId] = useState<
    string | undefined
  >(undefined);
  const [stackEditingDialogId, setStackEditingDialogId] = useState<
    string | undefined
  >(undefined);
  const [linkViewingDialogId, setLinkViewingDialogId] = useState<
    string | undefined
  >(undefined);
  const [stackViewingDialogId, setStackViewingDialogId] = useState<
    string | undefined
  >(undefined);
  const [newlyCreatedStackId, setNewlyCreatedStackId] = useState<
    string | undefined
  >(undefined);

  const { nColumns, setColumnsContainerRef } = useColumnWidth();

  useEffect(() => {
    const linkDetails = (
      dataCtx.links?.filter(
        (l) => !l.stackId && l.channelId === selectedChannelId
      ) || []
    ).map((l) => ({
      type: "link" as AstroContent,
      details: l,
    }));
    const stackDetails = (
      dataCtx.stacks?.filter((s) => s.channelId === selectedChannelId) || []
    ).map((s) => ({
      type: "stack" as AstroContent,
      details: s,
    }));
    const allContentDetails = _.reverse(
      _.sortBy(
        [...linkDetails, ...stackDetails],
        (c) => new Date(c.details.createdAt)
      ).slice()
    );
    const chunked = _.chunk(allContentDetails, nColumns);
    setCardColumns(
      [...Array(nColumns).keys()].map((i) =>
        _.compact(chunked.map((chunk) => chunk[i]))
      )
    );
  }, [dataCtx.links, dataCtx.stacks, selectedChannelId, nColumns]);

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  return (
    <>
      <PageLayout
        title="Lessons"
        description="This is where all of your created links & folders will appear."
        titleBackButton={true}
        bodyWidth="100%"
        selectedSidebarItemId="library"
        button={{
          text: "Add",
          callback: () => setAddDialogOpen(true), //setLinkCreationDialogOpen(true),
          icon: PlusIcon,
        }}
      >
        <Stack flex={1} direction="row" overflow="hidden">
          <Stack spacing="12px">
            <Stack direction="row" spacing="30px">
              <Stack
                sx={{
                  opacity: !my ? 1 : 0.6,
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                  transition: "0.2s",
                }}
                onClick={() => setMy(false)}
              >
                <Typography variant="large" bold>
                  All Lessons
                </Typography>
              </Stack>
              <Stack
                sx={{
                  opacity: my ? 1 : 0.6,
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                  transition: "0.2s",
                }}
                onClick={() => setMy(true)}
              >
                <Typography variant="large" bold>
                  My Lessons
                </Typography>
              </Stack>
            </Stack>
            <ChannelsColumn
              selected={selectedChannelId}
              selectionCallback={(id) => setSelectedChannelId(id)}
              channels={channels}
              my={my}
              deleteCallback={(id) => {
                id === selectedChannelId &&
                  channels &&
                  setSelectedChannelId(
                    channels.filter((c) => c.id !== id)[0].id
                  );
              }}
              //editCallback={(id) => setChannelEditingDialogId(id)}
            />
          </Stack>
          <Stack width="40px" height="100%" alignItems="center">
            <Stack
              height="100%"
              width="1px"
              bgcolor={PALETTE.secondary.grey[2]}
            />
          </Stack>
          <Stack
            ref={setColumnsContainerRef}
            overflow="hidden"
            flex={1}
            pb="64px"
            spacing="10px"
          >
            <Typography variant="large" bold>
              {channels.find((c) => c.id === selectedChannelId)?.title}
            </Typography>
            {cardColumns.flat().length === 0 ? (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <Stack>
                  <UrsorFadeIn delay={300} duration={1000}>
                    <Stack spacing="0px" position="relative">
                      <Stack sx={{ opacity: 0.3, filter: "grayscale(1)" }}>
                        <img
                          height="207px"
                          width="217px"
                          src={GraphIllustration}
                        />
                      </Stack>
                      <Stack
                        position="absolute"
                        bottom="-8px"
                        width="100%"
                        alignItems="center"
                      >
                        <UrsorButton
                          onClick={() => setLinkCreationDialogOpen(true)}
                          endIcon={<PlusIcon height="24px" width="24px" />}
                          mode="dark"
                          variant="tertiary"
                        >
                          Add Link
                        </UrsorButton>
                      </Stack>
                    </Stack>
                  </UrsorFadeIn>
                </Stack>
              </Stack>
            ) : (
              <Stack flex={1} overflow="scroll">
                <Stack
                  flex={1}
                  pb={SIDEBAR_Y_MARGIN}
                  direction="row"
                  spacing={GRID_SPACING}
                >
                  {cardColumns.map((column, i) => (
                    <Stack key={i} flex={1} spacing={GRID_SPACING}>
                      {column.map((item, j) => (
                        <Stack key={item.details.id}>
                          <UrsorFadeIn delay={j * 150 + i * 80} duration={800}>
                            {item.type === "link" ? (
                              <LinkCard
                                link={item.details as ILink}
                                clickCallback={() =>
                                  setLinkViewingDialogId(item.details.id)
                                }
                                editCallback={() =>
                                  setLinkEditingDialogId(item.details.id)
                                }
                                duplicateCallback={() =>
                                  duplicateLink(item.details.id)
                                }
                              />
                            ) : (
                              <StackCard
                                stack={item.details as IStack}
                                clickCallback={() =>
                                  setStackViewingDialogId(item.details.id)
                                }
                                editCallback={() =>
                                  setStackEditingDialogId(item.details.id)
                                }
                                duplicateCallback={() =>
                                  ApiController.duplicateStack(item.details.id)
                                    .then(dataCtx.refreshStacks)
                                    .then(dataCtx.refreshChannels)
                                    .then(dataCtx.refreshLinks)
                                    .then(() =>
                                      notificationCtx.success(
                                        "Stack duplicated"
                                      )
                                    )
                                }
                              />
                            )}
                          </UrsorFadeIn>
                        </Stack>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </PageLayout>
      {linkCreationDialogOpen || linkEditingDialogId ? (
        <LinkDialog
          link={
            linkEditingDialogId
              ? dataCtx.links?.find((l) => l.id === linkEditingDialogId)
              : undefined
          }
          channelId={selectedChannelId}
          stackId={stackViewingDialogId || newlyCreatedStackId}
          creationCallback={(link) => {
            setSelectedChannelId(link.channelId);
            setStackViewingDialogId(link.stackId);
          }}
          // newChannelCallback={() =>
          //   dialogCtx.setChannelDialogProps({
          //     completionCallback: (id) => {
          //       setSelectedChannelId(id);
          //     },
          //     open: true,
          //     closeCallback: () => null,
          //   })
          // }
          // newStackCallback={() => setStackCreationDialogOpen(true)}
          open={true}
          closeCallback={() => {
            setLinkCreationDialogOpen(false);
            setLinkEditingDialogId(undefined);
            setNewlyCreatedStackId(undefined);
          }}
          backCallback={() => setAddDialogOpen(true)}
        />
      ) : null}
      {stackCreationDialogOpen || stackEditingDialogId ? (
        <StackDialog
          stack={
            stackEditingDialogId
              ? dataCtx.stacks?.find((l) => l.id === stackEditingDialogId)
              : undefined
          }
          channelId={selectedChannelId}
          newChannelCallback={() =>
            dialogCtx.setChannelDialogProps({
              completionCallback: (id) => {
                setSelectedChannelId(id);
              },
              open: true,
              closeCallback: () => null,
            })
          }
          completionCallback={(id, channelId) => {
            setNewlyCreatedStackId(id);
            setStackViewingDialogId(id);
            setSelectedChannelId(channelId);
          }}
          open={true}
          closeCallback={() => {
            setStackCreationDialogOpen(false);
            setStackEditingDialogId(undefined);
          }}
          backCallback={() => setAddDialogOpen(true)}
        />
      ) : null}
      {linkViewingDialogId ? (
        <LinkViewDialog
          linkId={linkViewingDialogId}
          open={true}
          closeCallback={() => setLinkViewingDialogId(undefined)}
          openEditDialogCallback={() => {
            setLinkEditingDialogId(linkViewingDialogId);
            setLinkViewingDialogId(undefined);
          }}
        />
      ) : null}
      {stackViewingDialogId ? (
        <StackViewDialog
          stackId={stackViewingDialogId}
          open={true}
          closeCallback={() => setStackViewingDialogId(undefined)}
          newLinkCallback={() => {
            setSelectedChannelId(
              dataCtx.stacks?.find((s) => s.id === stackViewingDialogId)
                ?.channelId
            );
            setLinkCreationDialogOpen(true);
          }}
          editCallback={() => setStackEditingDialogId(stackViewingDialogId)}
          openLinkCallback={(id) => setLinkViewingDialogId(id)}
          editLinkCallback={(id) => setLinkEditingDialogId(id)}
        />
      ) : null}
      <AddDialog
        open={addDialogOpen}
        closeCallback={() => setAddDialogOpen(false)}
        channelCallback={() => {
          setAddDialogOpen(false);
          dialogCtx.setChannelDialogProps({
            completionCallback: (id) => setSelectedChannelId(id),
            open: true,
            closeCallback: () => null,
            backCallback: () => setAddDialogOpen(true),
          });
        }}
        stackCallback={() => {
          setAddDialogOpen(false);
          setStackCreationDialogOpen(true);
        }}
        linkCallback={() => {
          setAddDialogOpen(false);
          setLinkCreationDialogOpen(true);
        }}
      />
    </>
  );
}
